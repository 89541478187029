import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ToastyService } from "ng2-toasty";
import { CourseStructure } from '../../../../../api/models/course';
import { StorageService } from '../../../../../api/service/storage.service';

@Component({
  selector: 'app-item-course',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input() course: CourseStructure;

  @Output() clickCard: EventEmitter<string> = new EventEmitter();
  @Output() clickAlert: EventEmitter<string> = new EventEmitter();
  @Output() clickNotification: EventEmitter<string> = new EventEmitter();
  @Output() clickEdit: EventEmitter<CourseStructure> = new EventEmitter();
  @Output() clickRemove: EventEmitter<CourseStructure> = new EventEmitter();
  @Output() clickSelect: EventEmitter<CourseStructure> = new EventEmitter();
  @Output() addStudent: EventEmitter<CourseStructure> = new EventEmitter();
  @Output() addTteach: EventEmitter<CourseStructure> = new EventEmitter();
  @Output() mouseEnter: EventEmitter<CourseStructure> = new EventEmitter();


  grantEditCourse =
      !!(this.storageService.getCurrentSession().grants
          .find(g => g.name === 'CONTENT')
          .grants.find(gg => gg.name === 'EDITAR'));

  grantDeleteCourse =
      !!(this.storageService.getCurrentSession().grants
          .find(g => g.name === 'CONTENT')
          .grants.find(gg => gg.name === 'ELIMINAR'));

  grantAsocStaffCourse =
      !!(this.storageService.getCurrentSession().grants
          .find(g => g.name === 'CONTENT')
          .grants.find(gg => gg.name === 'ASOC_STAFF'));

  grantAsocEstuCourse =
      !!(this.storageService.getCurrentSession().grants
          .find(g => g.name === 'CONTENT')
          .grants.find(gg => gg.name === 'ASOCIAR_ESTUD'));

  grantAdd_ActivityCourse =
      !!(this.storageService.getCurrentSession().grants
          .find(g => g.name === 'CONTENT')
          .grants.find(gg => gg.name === 'AGREGAR_ACTIVIDADES'));

  constructor( private toastyService: ToastyService, public storageService: StorageService) { }

  ngOnInit(): void {  }

  clickEditCourse(): void {
    this.clickEdit.emit(this.course);
  }

  clickRemoveCourse(): void {
    this.clickRemove.emit(this.course);
  }

  clickSelectCourse(): void {
    this.clickSelect.emit(this.course);
  }

  addStudentCourse(): void {
    this.addStudent.emit(this.course);
  }

  addTeachCourse(): void {
    this.addTteach.emit(this.course);
  }

  mouseOut(): void {
    this.course.active = false;
  }

  mouseEnteFn(): void {
    this.mouseEnter.emit(this.course);
  }

}

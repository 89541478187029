import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Service} from '../../api/service/Service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {StorageService} from '../../api/service/storage.service';
import {Session} from '../../api/model/Session';
import {GrantsService} from '../services/grants.service';
import {MatDialog} from '@angular/material/dialog';
import {UiModalComponent} from '../theme/shared/components/modal/ui-modal/ui-modal.component';
import {Balance} from '../../api/client/data-contracts';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ContactTypeEnum} from 'src/api/utils/Constants';

@Component({
    selector: 'app-auth-signin',
    templateUrl: './auth-signin.component.html',
    styleUrls: ['./auth-signin.component.scss']
})
export class AuthSigninComponent implements OnInit {
    @ViewChild('modalPayment') modalPayment: UiModalComponent;

    position = 'top-center';
    username: string = 'usuario.staff@gmail.com';
    password: string = 'Test123!!';
    validToken: boolean = false;
    payment: boolean = false;
    subscriptionForm: FormGroup;
    balance: Balance;

    constructor(private storageService: StorageService,
                private fb: FormBuilder,
                private grantsService: GrantsService,
                public dialog: MatDialog,
                private router: Router, private toastyService: ToastyService) { }

    async setPolicy(){
        this.toastyService.clearAll();
        let toastOptions: ToastOptions = {
            title: "Espere",
            msg: "Iniciando Sesión",
            timeout: 5000,
            theme: "default"
        };
        this.toastyService.wait(toastOptions);
        const service = new Service(this.storageService);

        try {
            await service.updatePolicy(this.storageService.getCurrentSession().user.id).then(value => {
                this.router.navigate(['/menu/calendar']);
            }).catch((err) => {
                this.toastyService.clearAll();
                toastOptions = {
                    title: "Error al aceptar las politicas",
                    msg: err?.response?.data?.errors[0]?.description,
                    timeout: 5000,
                    theme: "default"
                };
                this.toastyService.error(toastOptions);
            });
        }catch (err) {
            this.toastyService.clearAll();
            toastOptions = {
                title: "Error al consumir API",
                msg: err,
                timeout: 5000,
                theme: "default"
            };
            this.toastyService.error(toastOptions);
        }
    }

    openPolicies(): void {
        const dialogRef = this.dialog.open(DialogPoliciesDialog);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.setPolicy();
            }
        });
    }

    ngOnInit() {
        const service = new Service(this.storageService);
        let currentToken = this.storageService.getCurrentToken();
        currentToken = currentToken ? currentToken.split(" ").pop() : currentToken;
        this.validToken = currentToken ? this.isValidToken(currentToken) : false;
        if (this.validToken) {
            this.router.navigate(['/menu/calendar']);
        } else if (currentToken) {
            setTimeout(() => {
                this.toastyService.clearAll();
                var toastOptions: ToastOptions = {
                    title: "Lo sentimos",
                    msg: "Su sesión ha expirado",
                    timeout: 5000,
                    theme: "de`fault"
                };
                this.toastyService.error(toastOptions);
                this.storageService.logout();
            }, 500);
        }


       /* if (this.storageService.getCurrentSession().customerResponse) {
            service.getCustomerBalanceById(this.storageService.getCurrentSession().customerResponse.id).then(value => {
                this.balance = value;
                debugger
            });
        } */


        this.subscriptionForm = this.fb.group({
        });
    }

    async login() {
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
            title: "Espere",
            msg: "Iniciando Sesión",
            timeout: 5000,
            theme: "default"
        };
        this.toastyService.wait(toastOptions);
        const service = new Service(this.storageService);
        try {
            await service.login("app", this.username, this.password).then(value => {
                const session = new Session();
                this.payment = true;

                // @ts-ignore
                if (value.user.role.match(ContactTypeEnum.STAFF)
                    || value.user.role.match(ContactTypeEnum.ADMIN)
                    || value.user.role.match(ContactTypeEnum.OWNER)) {
                    // @ts-ignore
                    value.grantsObject = this.grantsService.mapGrantToObject(value.grants, false);
                    if (!value?.user?.accepted_privacy_policy){
                        this.storageService.setCurrentSession(value);
                        this.openPolicies();
                    }else{
                        this.storageService.setCurrentSession(value);
                        this.router.navigate(['/menu/calendar']);
                    }
                } else {
                    this.toastyService.clearAll();
                    toastOptions = {
                        title: "Usuario invalido",
                        msg: "Su usuario no tiene acceso para utilizar este portal",
                        timeout: 5000,
                        theme: "default"
                    };
                    this.toastyService.warning(toastOptions);
                }


            }).catch((err) => {
                this.toastyService.clearAll();
                toastOptions = {
                    title: "Error al iniciar sesion",
                    msg: err?.response?.data?.errors[0]?.description,
                    timeout: 5000,
                    theme: "default"
                };
                this.toastyService.error(toastOptions);
            });
        } catch (err) {

        }
    }

    isValidToken(token: string): boolean {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) <= expiry;
    }

    showPayment(): void {
        this.modalPayment.show();
        console.log('sdfads');
    }
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'dialog-policies-dialog',
    templateUrl: 'dialog-policies-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogPoliciesDialog {}

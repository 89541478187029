<article class="wrapperItem" (click)="clickSelectCourse()">
    <article style=" background-color: #096fc6; margin-top: 10px; height: 87px;">
        <section>
            <article class="contentClick">
                <i class="feather icon-corner-left-down iconOpen"></i>
            </article>
        </section>
        <div class="createdAt">
            {{
            type === 'Actividades' ? moment(activity.created_at).utcOffset("-06:00").format("DD/MM/YYYY").toString() :
            type === 'Recursos' ? moment(activity.end_at).utcOffset("-06:00").format("DD/MM/YYYY").toString() :
            type === 'Calificaciones' ? moment(activity.end_at).utcOffset("-06:00").format("DD/MM/YYYY").toString() :
            type === 'Alumnos' && moment(activity.birth_date).utcOffset("-06:00").format("DD/MM/YYYY").toString()
        }}</div>
        <div>
            <mat-icon *ngIf="activity?.type === 'TASK'"
                style="color: #fff;font-size: 40px;margin: 6px 0 0 7px;">description</mat-icon>
            <mat-icon *ngIf="activity?.type === 'MEET'"
                style="color: #fff;font-size: 40px;margin: 6px 0 0 7px;">calendar_today</mat-icon>
            <mat-icon *ngIf="activity?.type === 'WATCH_EXTERNAL_VIDEO' || activity?.type === 'WATCH_VIDEO'"
                style="color: #fff;font-size: 40px;margin: 6px 0 0 7px;">video_library</mat-icon>
            <mat-icon *ngIf="activity?.type === 'READ_DOCUMENT'"
                style="color: #fff;font-size: 40px;margin: 6px 0 0 7px;">chrome_reader_mode</mat-icon>
            <mat-icon *ngIf="activity?.type === 'QUIZ'"
                style="color: #fff;font-size: 40px;margin: 6px 0 0 7px;">developer_board</mat-icon>

            <mat-icon *ngIf="type === 'Recursos'"
                style="color: #fff;font-size: 40px;margin: 6px 0 0 7px;">archive</mat-icon>

            <mat-icon *ngIf="type === 'Calificaciones'"
                style="color: #fff;font-size: 40px;margin: 6px 0 0 7px;">receipt</mat-icon>

            <img *ngIf="type === 'Alumnos'" class="user" src="{{activity?.avatar?.href}}" alt="estudiante" />

        </div>
        <div class="title" placement="bottom" >
            <div *ngIf="type==='Actividades'" class="score">
                Punteo: {{activity?.score}}
            </div>
            {{
                type === 'Actividades' ? activity.title :
                type === 'Recursos' ? activity.name :
                type === 'Calificaciones' ? 'Total Alumnos: ' + activity.score_quantity :
                type === 'Alumnos' ? activity.names + ' ' + activity.last_names : ''

            }}</div>
        <article *ngIf="grantsService.validateByNameAndType('ELIMINAR_ACTIVIDADES', 'ACTION')" class="deleteA" (click)="clickRemoveCourse($event)">
           {{ type === 'Calificaciones' ? 'Anular' : 'Eliminar'}}
        </article>
    </article>

    <div *ngIf="type==='Actividades'" class="description" >
        {{activity.description}}
    </div>
    <div *ngIf="type==='Alumnos'" class="description" >
        {{activity.email}}
    </div>

    <div *ngIf="type==='Recursos' || type==='Calificaciones'" class="download description" (click)="download($event)" >
        Descargar
    </div>

    <div *ngIf="type==='Calificaciones'" class="createdBy" style="left: 5px;">
        <label style="margin: 0; font-weight: 500;">Unidad</label>
        <div>{{activity.unit.unit}}</div>
    </div>
    <div *ngIf="type==='Calificaciones'" class="createdBy" >
        <label style="margin: 0; font-weight: 500;">Estado</label>
        <div>{{activity.status === 'COMPLETED' ? 'Procesada' : 'Fallida'}}</div>
    </div>

    <div *ngIf="type==='Actividades'" class="createdBy" style="left: 5px;">
        <label style="margin: 0; font-weight: 500;">Fecha entrega</label>
        <div>{{moment(activity.end_at).utcOffset("-06:00").format("DD/MM/YYYY").toString()}}</div>
    </div>
    <div *ngIf="type==='Actividades'" class="createdBy">
        <label style="margin: 0; font-weight: 500;">Hora entrega</label>
        <div>{{activity.end_time}}</div>
    </div>

    <div *ngIf="type==='Alumnos'" class="createdBy" style="left: 5px;">
        <label style="margin: 0; font-weight: 500;">Genero</label>
        <div>{{activity.gender}}</div>
    </div>
    <div *ngIf="type==='Alumnos'" class="createdBy" style="bottom: 4px;">
        <label style="margin: 0; font-weight: 500;">Ver Notas</label>
        <mat-icon
        style="color: #607D8B;font-size: 23px;margin: -5px 3px 0px 7px;display: block;">remove_red_eye</mat-icon>
    </div>
</article>

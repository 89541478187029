<div class="wrapperItemView">
    <div *ngIf="type === 'Actividades' && grantsService.validateByNameAndType('AGREGAR_ACTIVIDADES', 'ACTION')">
        <article
            class="wrapperItem"
            (click)="openNewActivity()">
            <i class="feather icon-plus-square iconOpen"></i>
            <div>Nueva Actividad</div>
        </article>
    </div>

    <app-item-activity
        *ngFor="let item of data; let i = index"
        class="table-row"
        [activity]="item"
        [type]="type"
        (clickRemove)="deleteAc($event)"
        (clickSelect)="openAc($event)"
        (clickDownload)="downloadDocument($event)"
        (clickSee)="seeDocument($event)"
    ></app-item-activity>
</div>

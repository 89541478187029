import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MediaAsset} from 'src/api/client/data-contracts';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';


@Component({
  selector: 'app-media-preview-modal',
  templateUrl: './media-preview-modal.component.html',
  styleUrls: ['./media-preview-modal.component.scss']
})
export class MediaPreviewModalComponent implements OnInit {
  @ViewChild('video1') video1: ElementRef;
  @ViewChild('pdfViewer') pdfViewer: ElementRef;

  pdfUrl: SafeResourceUrl;
  title = 'Vista previa';
  landscapeView: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public attachment: MediaAsset,
    private dialogRef: MatDialogRef<MediaPreviewModalComponent>,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.attachment.href);
    if (this.attachment.type === 'VIDEO') {
      setTimeout(() => {
        const videoWidth = this.video1.nativeElement.offsetWidth;
        const videoHeight = this.video1.nativeElement.offsetHeight;
        this.landscapeView = videoWidth > videoHeight ? true : false;
      }, 1000);
    }
  }


  closeModal(): void {
    this.dialogRef.close();
  }

}

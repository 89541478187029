<app-header-eduk [breadcrumbs]="breadcrumbs"></app-header-eduk>

<div class="container" style="margin: 0; padding: 0; max-width: 100%;">
  <div class="row">
    <article
      [ngClass]="{'col-lg-2 ': statusOption, 'menuMobileActive': statusOption && (this.windowWidth <= 1110)  }"
      class="WrapperNav"
      style="margin: 0; padding: 0;">
        <div
                [ngClass]="{'menuMobileOpen': statusOption && (this.windowWidth <= 1110), 'menuMobileClose' : !statusOption && (this.windowWidth <= 1110) }"
                class="menuMobile">
            <app-sidebar-eduk #sideBar
                              (clickOption)="setOptionNav($event)"
                              [showClose]="(this.windowWidth <= 1110)"
                              (closeSideBar)="closeSideBar()"
                              [title]="'Opciones'"
                              [optionsMenu]="iconsNav2"></app-sidebar-eduk>
        </div>

    </article>
    <article class="wrapper-couser col" style="margin: 0; padding: 0;">
        <section class="header">
            <mat-icon
              (click)="toggleMenuOptions()"
              style="color: #0ea7f5; font-size: 35px; margin: 7px 10px 0px 5px; cursor: pointer;">menu</mat-icon>
            <span class="title">
                {{ optionActive.name }} /
                {{this.courseName}} </span>
        </section>
        <section>
            <app-header-activity
                    #header
                    (optionContextMenu)="clickContextMenu($event)"
                    (clearFilter)="clearFilter()"
                    (rangeDates)="searchByDates($event)"
                    (name)="searchByName($event)"
                    [filterNave]="filterNave"
                    (filters)="setFilterNav($event)">
            </app-header-activity>
        </section>
        <section>
            <div class="row" style="margin: 0">
                <div class="clearRow col-md-12 order-2">
                  <app-itemView-activity
                        (download)="download($event)"
                        (delete)="deleteAc($event)"
                        (open)="openFilePreview($event)"
                        (newActivity)="modalCreateActivitie.show()"
                        [type]="optionActive.name"
                        [data]="optionActive.name === 'Actividades' ? activities :
                            optionActive.name === 'Recursos' ? resources  :
                            optionActive.name === 'Calificaciones' ? scores :
                            optionActive.name === 'Alumnos' && students
                        ">
                  </app-itemView-activity>
                </div>
            </div>
        </section>
    </article>
  </div>

</div>

<app-ui-modal #modalCreateResource [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
      <h5 class="modal-title">Cargar un recurso</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalCreateResource.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <div class="col-sm-12 ">
        <resources-component ></resources-component>
      </div>
    </div>
</app-ui-modal>

<app-ui-modal #modalCreateActivitie [dialogClass]="'modal-lg'">
    <div class="app-modal-header" style="top: -170px">
      <h5 class="modal-title">Crear Actividad</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalCreateActivitie.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <div class="col-sm-12">
        <app-activities ></app-activities>
      </div>
    </div>
</app-ui-modal>

<app-ui-modal #modalDeleteResource [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
      <h5 class="modal-title">Eliminar Recurso del Curso</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalDeleteResource.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <p>¿Está seguro de eliminar el Recurso?</p>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="modalDeleteResource.hide()">Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="deleteResource()">Aceptar</button>
    </div>
  </app-ui-modal>

<app-ui-modal #modalDeleteActivity [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
      <h5 class="modal-title">Eliminar Actividad del Curso</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalDeleteActivity.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <p>¿Está seguro de eliminar la Actividad?</p>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="modalDeleteActivity.hide()">Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="deleteActivity()">Aceptar</button>
    </div>
  </app-ui-modal>

<app-ui-modal #modalDelete [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h5 class="modal-title">Eliminar Estudiante del Curso</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalDelete.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <p>¿Está seguro de eliminar el estudiante '<b> {{selectedStudent.names}} {{selectedStudent.last_names}}</b> ',
        del curso?</p>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="modalDelete.hide()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="deleteStudent()">Aceptar</button>
    </div>
    </app-ui-modal>
<app-screen-loading #screenLoading [message]="message"></app-screen-loading>

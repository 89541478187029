<div class="wrapperItemView">
    <article *ngIf="grantNewCourse" class="wrapperItem" (click)="openNewCourse()">
        <i class="feather icon-plus-square iconOpen"></i>
        <div>Nuevo Curso</div>
    </article>
    <app-item-course
            *ngFor="let item of data; let i = index"
            class="table-row"
            (click)="this.setActiveItem(i);"
            [course]="item"
            (clickEdit)="selectCourse($event)"
            (clickRemove)="openRemoveModal($event)"
            (clickSelect)="openCourse($event)"
            (mouseEnter)="mouseEnteFn($event)"
            (addStudent)="assign($event, 'STUDENT')"
            (addTteach)="assign($event, 'STAFF,OWNER')"
    ></app-item-course>
</div>

<app-ui-modal #addCourse [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h5 class="modal-title">{{isNewSpace ? 'Nueva sucursal' : isEditSpace ? 'Editar Sucursal' : 'Curso'}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                (click)=" this.addCourse.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="app-modal-body">
        <div class="col-sm-12 ">
            <div class="errorMSJ" *ngIf="messageError">
                {{messageError}}
            </div>
            <form #validationForm="ngForm" class="editForm" novalidate>
                <div class="row">
                    <div class="col-md-12" *ngIf="!isNewSpace && !isEditSpace">
                        <app-tags-eduk
                                title="Etiqueta"
                                placeHolder="Escribe o Elije una Etiqueta"
                                [tags]="tagList" [listTag]="courseSelected?.tags ? courseSelected.tags : []"
                                (tagsEnter)="tagEnter($event)"></app-tags-eduk>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-7">
                        <div class="form-group">
                            <label class="form-label">Nombre*</label>
                            <input type="text" class="form-control" id="course" name="course"
                                   placeholder="Ingresar nombre"
                                   #course="ngModel"
                                   [(ngModel)]="formInput.name"
                                   [ngClass]="{'is-invalid': !course.valid && (course.dirty || course.touched )}"
                                   required>
                            <label *ngIf="!course.valid && (course.dirty || course.touched )"
                                   id="validation-names-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese un
                                Nombre de curso.</label>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Descripcion</label>
                            <input type="text" class="form-control" id="description" name="description"
                                   placeholder="Ingresar descripcion"
                                   #description="ngModel"
                                   [(ngModel)]="formInput.description"
                                   [ngClass]="{'is-invalid': !description.valid && (description.dirty || description.touched )}"
                                   required>
                            <label *ngIf="!description.valid && (description.dirty || description.touched )"
                                   id="validation-names-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese una
                                Descripcion.</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7 col-lg-5">
                        <section style=" margin: 0 0 0 65px; width: 100%; ">
                            <article class="logoWraper">
                                <section>
                                    <img *ngIf="image" src="{{ image }}" alt="curso">
                                </section>
                                <section>
                                    <input hidden type="file" #uploader (change)="setImageCourse($event)"
                                           [multiple]="false"/>
                                    <button type="button" class="btn btn-sm btn-glow-light btn-light btnAdd"
                                            (click)="uploader.click()"><i
                                            class="feather icon-paperclip"></i>Cambiar logo
                                    </button>
                                </section>
                            </article>
                        </section>
                    </div>
                    <div class="col-md-12" *ngIf="isNewSpace || isEditSpace">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">Direccion*</label>
                                <input type="text" class="form-control" id="address" name="address"
                                       placeholder="Ingresar Direccion"
                                       #address="ngModel"
                                       [(ngModel)]="formInput.address"
                                       [ngClass]="{'is-invalid': !address.valid && (address.dirty || address.touched )}"
                                       required>
                                <label *ngIf="!address.valid && (address.dirty || address.touched )"
                                       id="validation-names-error"
                                       class="error jquery-validation-error small form-text invalid-feedback">Ingrese un
                                    Direccion.</label>
                            </div>
                            <div class="col-md-6">
                                <label class="form-control-label" for="type">Administrador</label>
                                <select class="form-control" id="branchAdmins" name="branchAdmins" required
                                        #branchAdmins="ngModel"
                                        [(ngModel)]="admins">
                                    <option *ngFor="let staff of staffMembers"
                                            [value]="staff.id">{{staff.names}} {{staff.last_names}}</option>

                                </select>
                            </div>
                            <div class="col-md-12">
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <label class="form-control-label" for="phoneType">Tipo Tel&eacute;fono</label>
                                        <select class="form-control" name="phoneType" [(ngModel)]="phoneType">
                                            <option value="CELLPHONE">Celular</option>
                                            <option value="HOME">Casa</option>
                                            <option value="WORK">Trabajo</option>
                                            <option value="OTHER">Otro</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-control-label" for="phoneNumber">Tel&eacute;fono</label>
                                        <div class="d-flex">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-12 col-lg-5"
                                                     style="margin: 0 0 10px 0; padding: 0">
                                                    <select
                                                            style="width: 130px;"
                                                            class="form-control mr-1"
                                                            id="phoneCode"
                                                            name="phoneCode"
                                                            [(ngModel)]="phoneCode">
                                                        <option *ngFor="let country of countries"
                                                                [value]="country.code">
                                                            {{country.countryCode}} {{country.code}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-7"
                                                     style="margin: 0; padding: 0 0 0 0px">
                                                    <input
                                                            style="width: 200px;"
                                                            type="text" class="form-control" placeholder="00000000"
                                                            id="phoneNumber"
                                                            name="phoneNumber" [(ngModel)]="phoneNumber"
                                                            maxlength="8">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
                        <div class="spinner-border text-info" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
    <div class="app-modal-footer">
        <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                Continuar
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="save(false)">Guardar</button>
                <button *ngIf="!isNewSpace && !isEditSpace" ngbDropdownItem (click)="save(true)">Guardar y crear de
                    nuevo
                </button>
            </div>
        </div>
    </div>

</app-ui-modal>

<app-ui-modal #modalDelete [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h5 class="modal-title">Eliminar Curso</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                (click)="modalDelete.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <p>¿Está seguro que desea eliminar el curso?</p>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="modalDelete.hide();">Cancelar
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteCourse()">Aceptar</button>
    </div>
</app-ui-modal>

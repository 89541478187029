import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {NotFoundComponent} from './not-found/not-found.component';

const publicRoutes: Routes = [
    {
        path: 'not-found',
        component: NotFoundComponent,
    },
];
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth-signin/auth-signin.module').then(m => m.AuthSigninModule),
  },
  {
    path: 'menu',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'course/activity',
        loadChildren: () => import('./course/activity/activity-routing.module').then(m => m.ActivityRoutingModule),
      },
      {
        path: 'course/activity/detail',
        loadChildren: () => import('./course/detail/detail-routing.module').then(m => m.DetailActivityRoutingModule),
      },
      {
        path: 'course',
        loadChildren: () => import('./course/course-routing.module').then(m => m.CourseRoutingModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('./event-calendar/event-calendar.module').then(m => m.EventCalendarModule),
      },
      {
        path: 'contacts',
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
      },
      {
        path: 'content',
        loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
      },
      {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
      },
      {
        path: 'content-alerts',
        loadChildren: () => import('./content-alerts/content-alerts.module').then(m => m.ContentAlertsModule),
      },
      {
        path: 'security-roles',
        loadChildren: () => import('./security-roles/security-roles.module').then(m => m.SecurityRolesModule),
      },
      {
        path: 'school-cycles',
        loadChildren: () => import('./school-cycles/school-cycles-routing.module').then(m => m.SchoolCyclesRoutingModule),
      },
      {
        path: 'settings-school',
        loadChildren: () => import('./settings-school/settings-school-routing.module').then(m => m.SettingsSchoolRoutingModule),
      },
      {
        path: 'settings-school#pay',
        loadChildren: () => import('./settings-school/settings-school-routing.module').then(m => m.SettingsSchoolRoutingModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(publicRoutes),
      RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToastyService} from 'ng2-toasty';
import {StorageService} from '../../../../api/service/storage.service';

@Component({
  selector: 'app-search-course',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Output() enterValue: EventEmitter<string> = new EventEmitter();

  loading = false;
  search: string;
  space: string;

  constructor( private toastyService: ToastyService, private storageService: StorageService) { }

  ngOnInit(): void {}

  changeValue(): void{
    this.enterValue.emit(this.search);
  }

}

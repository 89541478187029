import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { UploadsNotifierService } from 'src/app/theme/shared/services/uploads-notifier.service';
import readXlsxFile from 'read-excel-file';

const permittedFiles = [
  'txt',
  'csv',
  'xls',
  'xml',
  'text',
  'xlsm',
  'xlsx',
  'xltx',
  'xltm',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

@Component({
  selector: 'app-courses-upload',
  templateUrl: './courses-upload.component.html',
  styleUrls: ['./courses-upload.component.scss']
})
export class CoursesUploadComponent implements OnInit {

  @ViewChild('csvReader') csvReader: any;
  form: FormGroup;
  title = 'Sube tu archivo con Cursos';
  coursesFiles: File[];
  coursesFileSelected = false;
  coursesTotal = 0;
  uploadErrors: any[] = [];
  filesHolding: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public spaceId: string,
    private fb: FormBuilder,
    private toastyService: ToastyService,
    public storageService: StorageService,
    private dialogRef: MatDialogRef<CoursesUploadComponent>,
    private uploadsNotifierService: UploadsNotifierService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      course_type: [null, [Validators.required]]
    });
  }

  validateCourseList($event) {
    this.toastyService.clearAll();
    const toastOptions: ToastOptions = {
      title: 'Espere',
      msg: 'Validando...',
      timeout: 5000,
      theme: 'default'
    };
    this.toastyService.wait(toastOptions);
    const files: File[] = [];
    const fileList: FileList = $event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }
    const notPermittedFiles = files.filter(file => !permittedFiles.includes(file.type));
    if (notPermittedFiles.length < 1) {
      const reader = new FileReader();
      reader.readAsText(files[0]);
      readXlsxFile(files[0]).then( (rows) => {
        this.coursesTotal = rows.length - 1;
      });
      this.coursesFileSelected = true;
      this.coursesFiles = files;
      setTimeout(() => {
        this.toastyService.clearAll();
      }, 4000);
    } else {
      let msg: string = 'Tipos de archivo "';
      const retrictedFileTypesArr = notPermittedFiles.map(file => file.type);
      const retrictedFileTypes = retrictedFileTypesArr.join(', ');
      msg += `${retrictedFileTypes}" no permitidos.`
      this.coursesFileSelected = false;
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg,
        timeout: 5000,
        theme: 'default'
      };
      this.toastyService.error(toastOptions);
    }
  }

  uploadCourseList() {
    const service = new Service(this.storageService);
    const loadType = this.form.get('course_type').value;
    this.coursesFiles.forEach(file => {
      service.sendFileForUploadAuthFull(file, loadType, this.spaceId, false).then((fileHolding: any) => {
        this.filesHolding.push(fileHolding);
      }).catch(reason => {
        this.toastyService.clearAll();
        const toastOptions: ToastOptions = {
          title: 'Error',
          msg: reason.message,
          timeout: 5000,
          theme: 'default'
        };
        this.toastyService.error(toastOptions);
      });
    });
  }

  managePendingFile(decision: string) {
    const service = new Service(this.storageService);
    switch (decision) {
      case 'start':
        this.filesHolding.forEach(file => {
          service.decideOnPendingFileUpload('start', file.id)
            .then(() => { })
            .catch(reason => {
              this.toastyService.clearAll();
              const toastOptions: ToastOptions = {
                title: 'Error',
                msg: reason.message,
                timeout: 5000,
                theme: 'default'
              };
              this.toastyService.error(toastOptions);
            });
        });
        this.uploadsNotifierService.checkPendingUploads();
        break;
      case 'cancel':
        this.filesHolding.forEach(async file => {
          service.decideOnPendingFileUpload('cancel', file.id).then(() => { })
            .catch(reason => {
              this.toastyService.clearAll();
              const toastOptions: ToastOptions = {
                title: 'Error',
                msg: reason.message,
                timeout: 5000,
                theme: 'default'
              };
              this.toastyService.error(toastOptions);
            });
          this.uploadsNotifierService.checkPendingUploads();
        });
        this.coursesFiles = [];
        this.coursesFileSelected = false;
        this.coursesTotal = 0;
        this.uploadErrors = [];
        this.filesHolding = [];
        break;
      default:
        break;
    }
  }

  downloadAssetExample() {
    window.open('/assets/files/Ejemplo Cursos Masivos.xlsx', '_blank');
  }

  continueChecingkPendingUploads() {
    this.managePendingFile('start');
    this.uploadsNotifierService.toggleNotifierVisibility();
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {StorageService} from 'src/api/service/storage.service';
import {Service} from '../../api/service/Service';
import {SpaceNodeFlat} from 'src/util/space';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {TagStructure} from '../../api/models/chipAutoComplete';
import {ActivatedRoute} from '@angular/router';
import {CourseStructure} from 'src/api/models/course';
import {ScreenLoadingComponent} from '../shared/screenLoading/screenLoading.component';

@Component({
  selector: "app-course",
  templateUrl: "./course.component.html",
  styleUrls: ["./course.component.scss"],
})
export class CourseComponent implements OnInit {
  @ViewChild("screenLoading") screenLoading: ScreenLoadingComponent;

  tagList: TagStructure[] = [];
  listTagByFilter = "";
  loading = false;
  space: string;
  listSpace: SpaceNodeFlat[];
  spaceEdit: SpaceNodeFlat;
  isNewSpace = false;
  isEditSpace = false;
  searchTerm: string;
  message = "Cargando Cursos";
  dataMouseEnter: CourseStructure;
  isStaff: boolean;
  dataCourse: {
    title: string;
    description: string;
    createdAt: string;
    createdBy: string;
    logo: string;
    active: boolean;
    tags: [];
    staff: [];
  }[];

  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    private toastyService: ToastyService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getS();
  }

  closeModalOption(): void {
    this.isEditSpace = false;
    this.isNewSpace = false;
  }

  spaceActive(space: string): void {
    this.space = space;
    this.getSettings();
  }

  createNewSpace(value: boolean): void {
    this.isNewSpace = value;
  }

  editSpace(value: boolean): void {
    this.isEditSpace = value;
  }

  editSpaceObject(value: SpaceNodeFlat): void {
    this.spaceEdit = value;
  }


  setTag(tags: TagStructure[]): void {
    this.tagList = tags;
  }

  setMouseEnterData(data): void {
    this.dataMouseEnter = data;
  }

  setFilterTag(tags): void {
    this.listTagByFilter = "";
    tags
      .filter((tt) => tt.id)
      .map((t) => {
        this.listTagByFilter = `${t.id},${this.listTagByFilter}`;
      });
    this.getSettings(this.listTagByFilter);
  }

  setFilterNav(
    filters: { icon: string; active: number; option: string }[]
  ): void {
    this.getSettings("", filters);
  }

  getS(): void {
    const service = new Service(this.storageService);
    const schoolId = this.storageService.getCurrentSession().school.id;
    this.screenLoading.on();
    service
      .getAllUsingGet3("", {
        Authorization: this.storageService.getCurrentToken(),
        schoolId,
      })
      .then((value: any) => {
        this.loading = false;
        this.listSpace = value.data.data;
        this.space = value?.data?.data[0].id;
        this.spaceEdit = value?.data?.data[0];
        this.screenLoading.off();
        this.getSettings();
      })
      .catch((error) => {
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Error",
          msg: error,
          timeout: 5000,
          theme: "default",
        };
        this.toastyService.error(toastOptions);
        this.screenLoading.off();
        this.loading = false;
      });
  }

  search(term: string): void{
    this.searchTerm = term;
    this.getSettings();
  }

  getSettings(tags?, filters?): void {
    this.isStaff =
      this.storageService.getCurrentSession().user.role === "STAFF"
        ? true
        : false;
    let assigneeUserId = "";

    this.route.queryParams.subscribe((params) => {
      if (this.isStaff) {
        assigneeUserId =
          this.storageService.getCurrentSession().user.contact_id;
      }
    });

    const service = new Service(this.storageService);
    service
      .getCourse(
        this.isStaff ? '' : this.space,
        tags,
        filters,
        assigneeUserId,
        this.searchTerm
      )
      .then((value) => {
        this.dataCourse = value.map((cr) => ({
          id: cr.id,
          createdAt: "",
          logo: cr?.avatar?.href,
          title: cr.title,
          description: cr.description,
          createdBy: "",
          active: false,
          tags: cr.tags,
          staff: cr.staff,
        }));
      });



  }
  updateSucursal(id: string): void {
    const schoolId = this.storageService.getCurrentSession().school.id;
    const service = new Service(this.storageService);
    this.screenLoading.on();
    service
        .getAllUsingGet3("", {
          Authorization: this.storageService.getCurrentToken(),
          schoolId,
        })
        .then((value: any) => {
          this.loading = false;
          this.listSpace = value.data.data;
          this.space = id;
          this.spaceEdit = value?.data?.data[0];
          this.screenLoading.off();
        })
        .catch((error) => {
          this.toastyService.clearAll();
          var toastOptions: ToastOptions = {
            title: "Error",
            msg: error,
            timeout: 5000,
            theme: "default",
          };
          this.toastyService.error(toastOptions);
          this.screenLoading.off();
          this.loading = false;
        });
  }
}


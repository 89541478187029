<aside
  [ngClass]="{'asideOut': close }"
  class="asideWrapper">
    <h4>{{title}}</h4>
     <article class="node" *ngFor="let nav of optionsMenu; let i = index">
      <h5>{{nav.title}}</h5>
      <p>{{nav.description}}</p>
         <mat-icon *ngIf="showClose" (click)="closeSide()"
                 style="color: #0ea7f5; font-size: 35px; margin: 7px 10px 0px 5px; cursor: pointer; position: absolute; top: 4px; right: 19px;">close</mat-icon>
      <nav>
        <ul>
          <li
            *ngFor="let menu of nav.menu"
            (click)="selectItem(i, menu.icon); (showClose && closeSide());"
            [ngClass]="{'active': menu.active }"
            >
            <mat-icon style="font-size: 25px; margin: 7px 10px 0px 12px;">{{menu.icon}}</mat-icon>
            <span style="display: inline-block; margin-top: 9px; position: absolute; ">{{menu.name}}</span>
          </li>
        </ul>
      </nav>
    </article>
</aside>

<div class="app-modal-header">
    <h5 class="modal-title">Realizar Pago
        <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
</div>
<div class="app-modal-body d-flex flex-column pl-5 pb-2 pr-5 pt-1" style="max-height: 95%;">
    <div class="">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-4">
                <div class="form-group">
                    <label class="form-label">Total a Pagar:</label>
                    <input type="text"
                           style="text-align: end"
                           [value]="balance.exchange.formatted"
                           class="form-control"
                           formControlName="school_name"
                           placeholder="0.0" readonly disabled>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ngb-tabset [activeId]="activeTab" (tabChange)="onTabChange($event)">
                    <ngb-tab id="tabDeposit">
                        <ng-template ngbTabTitle>
                            <div>
                                <mat-icon style="vertical-align:middle;">account_balance</mat-icon>
                                <span class="tab-title">Deposito</span>
                            </div>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <form #validateDepositForm="ngForm" class="editForm" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-control-label" for="depBo">No. Boleta</label>
                                            <input type="text" class="form-control"
                                                   placeholder="Ingrese una direcci&oacute;n" name="depBo" id="depBo" required
                                                   #depBoleta="ngModel"
                                                   [(ngModel)]="depositFormRequest.reference.deposit.number"
                                                   [ngClass]="{'is-invalid': !depBoleta.valid && (depBoleta.dirty || depBoleta.touched || isSubmit)}">
                                            <label
                                                    *ngIf="!depBoleta.valid && (depBoleta.dirty || depBoleta.touched || isSubmit)"
                                                    id="validation-depBoleta-error"
                                                    class="error jquery-validation-error small form-text invalid-feedback">
                                                Seleccione el banco del deposito
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-control-label" for="depBank">Banco</label>
                                            <select class="form-control" name="depBank" id="depBank" required
                                                    #depBank="ngModel"
                                                    [(ngModel)]="depositFormRequest.reference.deposit.bank"
                                                    [ngClass]="{'is-invalid': !depBank.valid && (depBank.dirty || depBank.touched || isSubmit)}">
                                            <option *ngFor="let bank of getBanks()" [value]="bank.name">
                                                    {{bank.name}}</option>
                                            </select>
                                            <label
                                                    *ngIf="!depBank.valid && (depBank.dirty || depBank.touched || isSubmit)"
                                                    id="validation-depBank-error"
                                                    class="error jquery-validation-error small form-text invalid-feedback">
                                                Seleccione el banco del deposito
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-control-label" for="depType">Tipo de deposito</label>
                                            <select class="form-control" name="depType" id="depType"
                                                    required #depType="ngModel"
                                                    [(ngModel)]="depositFormRequest.reference.deposit.type"
                                                    [ngClass]="{'is-invalid': !depType.valid && (depType.dirty || depType.touched || isSubmit)}">
                                            <option value="LOCAL">Local</option>
                                                <option value="ACH">ACH</option>
                                            </select>
                                            <label
                                                    *ngIf="!depType.valid && (depType.dirty || depType.touched || isSubmit)"
                                                    id="validation-depType-error"
                                                    class="error jquery-validation-error small form-text invalid-feedback">
                                                Seleccione tipo de deposito
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label" for="depDate">Fecha de deposito</label>
                                            <div class="input-group">
                                                <input class="form-control"  name="depDate" id="depDate" ngbDatepicker #d="ngbDatepicker" dateFormat="dd/MM/yyyy"
                                                       [maxDate]="maxDate" required #depDate="ngModel"   onkeydown="return false"
                                                       [(ngModel)]="datePay"  placeholder="dd / mm / aaaa"
                                                       [ngClass]="{'is-invalid': !depDate.valid && (depDate.dirty || depDate.touched || isSubmit)}">
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary"
                                                                    (click)="d.toggle()" type="button">
                                                                <i class="fas fa-calendar f-14"></i>
                                                            </button>
                                                        </div>
                                                <label
                                                        *ngIf="!depDate.valid && (depDate.dirty || depDate.touched || isSubmit)"
                                                        id="validation-depDate-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Ingrese al fecha del deposito
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row justify-content-center">
                                    <input hidden type="file" #uploader (change)="loadFile($event)" [multiple]="false" />
                                    <button type="button" class="btn btn-sm btn-glow-light btn-light" (click)="uploader.click()"><i
                                            class="feather icon-paperclip"></i>Cargar imagen de pago</button>
                                </div>
                                <div class="row">
                                    <button
                                            id="btnDep"
                                            type="button"
                                            class="btn btn-md btn-success btn-save"
                                            (click)="makePayment(validateDepositForm, 'DEPOSIT')"
                                            [disabled]="!depBoleta.valid || !depBank.valid || !depBank.valid || !depDate.valid"
                                    >
                                        Realizar Deposito
                                    </button>
                                </div>
                            </form>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tabTransference">
                        <ng-template ngbTabTitle>
                            <mat-icon style="  vertical-align:middle; margin-left: 10px">compare_arrows</mat-icon>
                            <span class="tab-title">Transferencia</span>
                        </ng-template>
                        <ng-template ngbTabContent>
                            <form #validateTransForm="ngForm" class="editForm" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-control-label" for="transNo">N&uacute;mero</label>
                                            <input type="text" class="form-control"
                                                   placeholder="Ingrese N&uacute;mero" name="transNo" id="transNo" required
                                                   #transNo="ngModel"
                                                   [(ngModel)]="depositFormRequest.reference.swift.number"
                                                   [ngClass]="{'is-invalid': !transNo.valid && (transNo.dirty || transNo.touched || isSubmit)}">
                                            <label
                                                    *ngIf="!transNo.valid && (transNo.dirty || transNo.touched || isSubmit)"
                                                    id="validation-transNo-error"
                                                    class="error jquery-validation-error small form-text invalid-feedback">
                                                Ingrese N&uacute;mero
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-control-label" for="transBank">Banco Origen</label>
                                            <input type="text" class="form-control"
                                                   placeholder="Ingrese el Banco de origen de la transacci&oacute;n" name="transBank" id="transBank" required
                                                   #transBank="ngModel"
                                                   [(ngModel)]="depositFormRequest.reference.swift.bank"
                                                   [ngClass]="{'is-invalid': !transBank.valid && (transBank.dirty || transBank.touched || isSubmit)}">
                                            <label
                                                    *ngIf="!transBank.valid && (transBank.dirty || transBank.touched || isSubmit)"
                                                    id="validation-transBank-error"
                                                    class="error jquery-validation-error small form-text invalid-feedback">
                                                Ingrese banco de origen
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-control-label" for="transType">Tipo de transferencia</label>
                                            <select class="form-control" name="transType" id="transType"
                                                    required #transType="ngModel"
                                                    [(ngModel)]="depositFormRequest.reference.swift.type"
                                                    [ngClass]="{'is-invalid': !transType.valid && (transType.dirty || transType.touched || isSubmit)}">
                                                <option value="LOCAL">Local</option>
                                                <option value="ACH">ACH</option>
                                            </select>
                                            <label
                                                    *ngIf="!transType.valid && (transType.dirty || transType.touched || isSubmit)"
                                                    id="validation-transType-error"
                                                    class="error jquery-validation-error small form-text invalid-feedback">
                                                Seleccione tipo de transferencia
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-control-label" for="transType">Pa&iacute;s</label>
                                            <select class="form-control" name="transCountry" id="transCountry"
                                                    required #transCountry="ngModel"
                                                    [(ngModel)]="depositFormRequest.reference.swift.countryCode"
                                                    [ngClass]="{'is-invalid': !transCountry.valid && (transCountry.dirty || transCountry.touched || isSubmit)}">
                                                <option *ngFor="let obj of getCountries()" [value]="obj.country">
                                                    {{obj.country}}</option>
                                            </select>
                                            <label
                                                    *ngIf="!transCountry.valid && (transCountry.dirty || transCountry.touched || isSubmit)"
                                                    id="validation-transCountry-error"
                                                    class="error jquery-validation-error small form-text invalid-feedback">
                                                Seleccione pa&iacute;s
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label" for="depDate">Fecha de deposito</label>
                                            <div class="input-group">
                                                <input class="form-control"  name="transDate" id="transDate" ngbDatepicker #d="ngbDatepicker"
                                                       [maxDate]="maxDate" required #transDate="ngModel"   onkeydown="return false"
                                                       [(ngModel)]="datePay"  placeholder="dd / mm / aaaa"
                                                       [ngClass]="{'is-invalid': !transDate.valid && (transDate.dirty || transDate.touched || isSubmit)}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary"
                                                            (click)="d.toggle()" type="button">
                                                        <i class="fas fa-calendar f-14"></i>
                                                    </button>
                                                </div>
                                                <label
                                                        *ngIf="!transDate.valid && (transDate.dirty || transDate.touched || isSubmit)"
                                                        id="validation-transDate-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Ingrese al fecha del deposito
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-control-label" for="transCurrency">Moneda</label>
                                            <select class="form-control" name="transCurrency" id="transCurrency"
                                                    required #transCurrency="ngModel"
                                                    [(ngModel)]="depositFormRequest.reference.swift.currencyCode"
                                                    [ngClass]="{'is-invalid': !transCurrency.valid && (transCurrency.dirty || transCurrency.touched || isSubmit)}">
                                                <option *ngFor="let obj of getCountries()" [value]="obj.currency">
                                                    {{obj.currency}}</option>
                                            </select>
                                            <label
                                                    *ngIf="!transCurrency.valid && (transCurrency.dirty || transCurrency.touched || isSubmit)"
                                                    id="validation-transCurrency-error"
                                                    class="error jquery-validation-error small form-text invalid-feedback">
                                                Seleccione tipo de moneda
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row justify-content-center">
                                    <input hidden type="file" #uploader (change)="loadFile($event)" [multiple]="false" />
                                    <button type="button" class="btn btn-sm btn-glow-light btn-light" (click)="uploader.click()"><i
                                            class="feather icon-paperclip"></i>Cargar imagen de pago</button>
                                </div>
                                <div class="row">
                                    <button
                                            id="btnTrans"
                                            type="button"
                                            class="btn btn-md btn-success btn-save"
                                            (click)="makePayment(validateTransForm, 'SWIFT')"
                                            [disabled]="!transNo.valid || !transBank.valid || !transType.valid || !transCountry.valid || !transDate.valid || !transCurrency.valid">
                                        Realizar Transferencia
                                    </button>
                                </div>
                            </form>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>
</div>

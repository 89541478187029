import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionsMenuStructure} from '../../../api/models/sidebar';

@Component({
  selector: "app-sidebar-eduk",
  templateUrl: "./sidebarMenu.component.html",
  styleUrls: ["./sidebarMenu.component.scss"],
})
export class SidebarMenuComponent implements OnInit {
  @Input() title: string;
  @Input() optionsMenu: OptionsMenuStructure[];
  @Input() showClose: boolean;
  @Output() clickOption: EventEmitter<OptionsMenuStructure> =
    new EventEmitter();
  @Output() closeSideBar: EventEmitter<boolean> = new EventEmitter();

  close: boolean;
  optionSelected: any;

  constructor() {}

  ngOnInit(): void {
    this.selectItem(0, this.optionsMenu[0].menu[0].icon);
  }

  closeSide(): void {
    this.close = !this.close;
    this.closeSideBar.emit(this.close);
  }

  selectItem(nav, item): void {
    this.optionsMenu[nav].menu.forEach((op) => {
      op.active = op.icon === item;
    });
    this.clickOption.emit(this.optionsMenu[nav]);
  }
}

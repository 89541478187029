import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipAutoCompleteComponent } from './chipAutoComplete.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [ChipAutoCompleteComponent],
    exports: [
        ChipAutoCompleteComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        MatIconModule,
    ]
})
export class ChipAutoCompleteModule { }

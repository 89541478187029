<article (mouseleave)="mouseOut()" (mouseenter)="mouseEnteFn()" class="wrapperItem">
    <!-- <i class="feather icon-more-vertical iconOpen"></i> -->
    <section>
        <article class="contentClick">
            <i class="feather icon-corner-left-down iconOpen"></i>
        </article>
    </section>
    <section
    [ngClass]="course.active ? 'contentActive' : 'contentInactive'">
        <div>
            <i
            (click)="grantEditCourse && this.clickEditCourse();"
            [ngStyle]="{'color': !grantEditCourse && '#989898'}"
            style="float: left; margin: 9px 0px 0px 8px; " class="feather icon-edit iconOpen"></i>
            <i
            (click)="grantDeleteCourse && this.clickRemoveCourse();"
            [ngStyle]="{'color': !grantDeleteCourse && '#989898'}"
            style="float: right; margin: 9px 8px 0px 0px;" class="feather icon-trash iconOpen"></i>
        </div>
        <article class="optionMenu">
            <div (click)="grantAdd_ActivityCourse && this.clickSelectCourse();">
                <i
                    [ngStyle]="{'color': !grantAdd_ActivityCourse && '#989898'}"
                    class="iconMenuO feather icon-file-text iconOpen"></i>
                <span>Ingresar</span>
            </div>
            <div (click)="grantAsocEstuCourse && this.addStudentCourse();">
                <i
                    [ngStyle]="{'color': !grantAsocEstuCourse && '#989898'}"
                    class="iconMenuO feather icon-user iconOpen"></i>
                <span [ngStyle]="{'color': !grantAsocEstuCourse && '#989898'}">(+) Estudiante</span>
            </div>
            <div (click)="grantAsocStaffCourse && this.addTeachCourse();">
                <i
                    [ngStyle]="{'color': !grantAsocStaffCourse && '#989898'}"
                    class="iconMenuO feather icon-award iconOpen"></i>
                <span [ngStyle]="{'color': !grantAsocStaffCourse && '#989898'}">(+) Maestro</span>
            </div>
           <!-- <div>
                <i class="iconMenuO feather icon-layers iconOpen"></i>
                <span>Duplicar Curso</span>
            </div> -->
        </article>
    </section>
    <div class="createdAt">{{course.createdAt}}</div>
    <div *ngIf="course.logo" class="logo"><img src={{course.logo}} alt="curso"></div>
    <div class="title" placement="bottom" >{{course.title}}</div>
    <div class="description" >
        <div class="staff-container">
            <div class="staff" *ngFor="let st of course?.staff">
                <img ngbTooltip="{{st.names}} {{st.last_names}}" placement="bottom" src="{{st?.avatar?.href}}" alt="{{st?.names}}">
            </div>
        </div>
    </div>
    <div class="createdBy">{{course.createdBy}}</div>
</article>

<div class="wrapperNavigation row">
    <div class="wpIcon">
        <i class="icon feather icon-filter iconGrid"></i>
        <span class="filter">Filtrar por:</span>
        <div class="line"></div>
    </div>
    <div *ngIf="!isStaff" class="">
        <select class="sucursal select form-control"  id="type" name="type"
                placeholder="sdfas dfads"
                required
                #contactType="ngModel"
                (change)="takeSpace(space)"
                [(ngModel)]="space">
            <option
                    selected
                    *ngFor="let space of listSpace; trackBy: trackById"
                    value="{{space.id}}"
            >{{space.title}}</option>
            <option
                    value="new"
            >Nueva Sucursal(+)</option>
        </select>
    </div>

    <div class="col-md">
        <div class="navigation">
            <ul>
                <li *ngFor="let opt of iconsNav; let i = index" class="space" (click)="filter(i)">
                    <i class="
                    {{opt.active === 0 ? 'iconNeutro' : opt.active === 1 ? 'iconActive' : 'iconFalse'  }} icon feather {{opt.icon}} iconGrid"></i>
                    <div class="textDes">
                        {{opt.active === 0 ? '' : opt.active === 1 ?
                            (opt.option === 'hasStudents' ? 'con estudiantes' :
                            opt.option === 'hasStaff' ? 'con maestros' :
                            opt.option === 'hasContentAlerts' ? 'con alertas' :
                            opt.option === 'hasActivity' && 'con notificaciones')
                        :   (opt.option === 'hasStudents' ? 'sin estudiantes' :
                            opt.option === 'hasStaff' ? 'sin maestros' :
                            opt.option === 'hasContentAlerts' ? 'sin alertas' :
                            opt.option === 'hasActivity' && 'sin notificaciones')  }}
                    </div>
                </li>
                <li style="float: right;">
                    <mat-icon
                            style="color: #057ab2; font-size: 32px; margin: 10px 0 0 17px; cursor: pointer;">
                        refresh
                    </mat-icon>

                    <mat-icon
                            [matMenuTriggerFor]="beforeMenu"
                            style="color: #057ab2; font-size: 32px; margin-left: 26px; cursor: pointer;">
                        more_vert
                    </mat-icon>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button
                                (click)="openContentUploadModal()"
                                mat-menu-item disabled="{{!grantNewCourse}}">
                            <i class="icon feather icon-upload iconGrid"></i>

                             Carga Masiva
                        </button>
                        <hr />
                        <button
                                (click)="takeSpace('new')"
                                mat-menu-item disabled="{{!grantNewCourse}}">
                            <i class="icon feather icon-aperture iconGrid"></i>

                            Nueva sucursal!
                        </button>
                        <button
                                (click)="callEditSpace()"
                                mat-menu-item disabled="{{!grantNewCourse}}">
                            <i class="icon feather icon-edit iconGrid"></i>

                            Editiar sucursal!
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </div>
</div>

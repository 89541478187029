<div *ngIf="!validToken" class="rectangle">
  <div class="vector-3"></div>
  <div class="vector-2"></div>
  <div class="vector-1"></div>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="card">
        <div class="card-body text-center">
          <div class="mb-4">
            <img style="padding-bottom:25px;padding-top: 25px " src="assets/images/logo.svg">
          </div>
          <h3 class="mb-4 login-important login-title">Iniciar sesión</h3>
          <div class="input-group mb-3">
            <input (keydown.enter)="login()" type="email" class="form-control" placeholder="Usuario" [(ngModel)]="username"
              value="eliu@gmail.com">
          </div>
          <div class="input-group mb-4">
            <input (keydown.enter)="login()" type="password" class="form-control" placeholder="Constraseña" [(ngModel)]="password"
              value="Test123!!">
          </div>
          <div class="form-group text-left">
            <div class="checkbox checkbox-fill d-inline">
              <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" checked="">
              <label for="checkbox-fill-a1" class="cr login-muted"> Recordar usuario</label>
            </div>
          </div>
          <button class="btn btn-primary mb-4" (click)="login()">Iniciar sesión</button>
          <p class="mb-2 login-muted"><a class="login-important"
            [routerLink]="['/auth/forgot-password']">¿Olvid&oacute; su contraseña?</a></p>
          <p class="mb-0 login-muted">¿A&uacute;n no tiene cuenta? <a class="login-important" [routerLink]="['/auth/signup']">Registrarse</a></p>
        </div>
      </div>
    </div>
  </div>
  <ng2-toasty [position]="position"></ng2-toasty>
</div>


<app-ui-modal #modalPayment [dialogClass]="'modal-lg'">
  <div class="app-modal-header" style="top: -170px">
    <h5 class="modal-title">Actualiza tu pago</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="modalPayment.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="col-sm-12">
      <section>
        <form [formGroup]="subscriptionForm">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="form-label">Saldo Pendiente:</label>
                <input type="text"
                       [value]="balance?.exchange?.formatted"
                       class="form-control"
                       placeholder="0.0" readonly disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ngb-tabset>
                <ngb-tab id="pendingPays">
                  <ng-template ngbTabTitle>
                    <div>
                      <mat-icon style="  vertical-align:middle;">attach_money</mat-icon>
                      <span class="tab-title">Pagos Pendientes</span>
                    </div>
                  </ng-template>
                  <ng-template ngbTabContent>
                    <table class="table">
                      <thead>
                      <tr>
                        <th align="center" class="table-title">No. Factura</th>
                        <th align="center" class="table-title">Fecha Vencimiento</th>
                        <th align="center" class="table-title">Fecha Pago</th>
                        <th align="center" class="table-title">Total $</th>
                        <th align="center" class="table-title">Total Local</th>
                      </tr>
                      <tbody>
                      <tr *ngFor="let invoice of balance?.invoicesDue">
                        <td align="center">
                          {{invoice.letter}}-{{invoice.sequence}}
                        </td>
                        <td align="center">
                          {{invoice.dueDate | date:'dd/MM/yyyy'}}
                        </td>
                        <td align="center">
                          {{invoice.paidAt | date:'dd/MM/yyyy'}}
                        </td>
                        <td align="right">
                          {{invoice.total | currency: '$'}}
                        </td>
                        <td align="right">
                          {{invoice.exchange.formatted}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </ngb-tab>
                <ngb-tab id="historicPays">
                  <ng-template ngbTabTitle>
                    <mat-icon style="  vertical-align:middle; margin-left: 10px">assignment
                    </mat-icon>
                    <span class="tab-title">Historial de Pagos</span>
                  </ng-template>
                  <ng-template ngbTabContent>
                    <table class="table">
                      <thead>
                      <tr>
                        <th align="center" class="table-title">No. Factura</th>
                        <th align="center" class="table-title">Fecha Vencimiento</th>
                        <th align="center" class="table-title">Fecha Pago</th>
                        <th align="center" class="table-title">Total $</th>
                        <th align="center" class="table-title">Total Local</th>
                      </tr>
                      <tbody>
                      <tr *ngFor="let invoice of balance?.invoicesHistory">
                        <td align="center">
                          {{invoice.letter}}-{{invoice.sequence}}
                        </td>
                        <td align="center">
                          {{invoice.dueDate | date:'dd/MM/yyyy'}}
                        </td>
                        <td align="center">
                          {{invoice.paidAt | date:'dd/MM/yyyy'}}
                        </td>
                        <td align="right">
                          {{invoice.total | currency: '$'}}
                        </td>
                        <td align="right">
                          {{invoice.exchange.formatted}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</app-ui-modal>

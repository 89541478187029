<div class="wrapperNavigation row">
    <div class="wpIcon">
        <i class="icon feather icon-filter iconGrid"></i>
        <span class="filter">Filtrar por:</span>
        <div class="line"></div>
    </div>
    <div class="col-md" style="padding: 0;">
        <div class="navigation">
            <ul>
                <li>
                    <div class="btn-group" role="group" aria-label="button groups">
                        <button
                            *ngFor="let filter of filterNave; let i = index"
                            type="button"
                            (click)="filterActivity(filter)"
                            class="optionsBtn btn btn-sm btn-secondary">{{filter.text}}</button>
                    </div>
                </li>
                <li style="float: right; margin-top: -12px;" class="mobileRefresh">
                    <mat-icon
                        (click)="clear()"
                        style="color: #6c757d; font-size: 32px; margin: 10px 0 0 17px; cursor: pointer;">
                            refresh
                    </mat-icon>

                    <mat-icon
                            [matMenuTriggerFor]="beforeMenu"
                            style="color: #6c757d; font-size: 32px; margin-left: 26px; cursor: pointer;">
                        more_vert
                    </mat-icon>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button
                            *ngIf="grantsService.validateByNameAndType('AGREGAR_RECURSOS', 'ACTION')"
                            (click)="openCreateResource('newResource')"
                                mat-menu-item >
                            <i style="margin-right: 5px;" class="icon feather icon-upload iconGrid"></i>
                             Cargar un recurso
                        </button>
                        <button
                                (click)="openCreateResource('updateScores')"
                                mat-menu-item >
                            <i style="margin-right: 5px;" class="icon feather icon-upload-cloud iconGrid"></i>
                             Subir Notas
                        </button>
                        <button
                            *ngIf="grantsService.validateByNameAndType('AGREGAR_ACTIVIDADES', 'ACTION')"
                            (click)="openCreateResource('listStudents')"
                                mat-menu-item >
                            <i style="margin-right: 5px;" class="icon feather icon-file-text iconGrid"></i>
                             Exportar Lista Alumnos
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </div>
</div>

<div *ngIf="optionActive?.active" class="wrapperNavigation" style="width: 100%; display: inline-block;">
    <div class="line" style="height: 56px"></div>
    <div class="row" style="padding: 0; margin: -8px 0 0 0">
        <div *ngIf="optionActive?.option === 'NAME'" class="col-sm-12 col-md-12 col-lg-12 col-xl filterOption">
            <label style="margin-bottom: 0; margin-top: 18px;">Buscar por nombre:</label>
            <input
                    type="text" class="form-control" id="nameActivity" name="nameActivity"
                    placeholder="nombre"
                    #names="ngModel"
                    [(ngModel)]="nameActivity"
                    (keyup)="searchByName()"
            >
        </div>
        <div *ngIf="optionActive?.option === 'RANGE'" class="col-sm-12 col-md-12 col-lg-12 col-xl" style="padding: 0; margin: 0">
            <label class="form-control-label" for="dateFrom" style="margin-bottom: 0; margin-top: 10px;">Desde</label>
            <div class="input-group">
                <input
                        class="form-control" placeholder="dd / mm / aaaa" #from="ngModel"
                        name="dateFromActivity"
                        id="dateFromActivity"
                       [(ngModel)]="dateFromActivity" ngbDatepicker #d="ngbDatepicker"
                       [ngClass]="{'is-invalid': !from.valid && (from.dirty || from.touched)}" required>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                        <i class="fas fa-calendar f-14"></i>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="optionActive?.option === 'RANGE'" class="col-sm-12 col-md-12 col-lg-12 col-xl" >
            <label class="form-control-label" for="dateTo" style="margin-bottom: 0; margin-top: 10px;">Hasta</label>
            <div class="input-group">
                <input class="form-control"
                       placeholder="dd / mm / aaaa"
                       [minDate]="dateFromActivity"
                       #to="ngModel"
                       name="dateToActivity"
                       id="dateToActivity"
                       [(ngModel)]="dateToActivity"
                       ngbDatepicker #d2="ngbDatepicker"
                       [ngClass]="{'is-invalid': !to.valid && (to.dirty || to.touched)}" required>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                        <i class="fas fa-calendar f-14"></i>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="optionActive?.option === 'RANGE'" class="col-sm-12 col-md-12 col-lg-12 col-xl">
            <button (click)="range()" style="margin-top: 30px;" type="button" class="btn btn-primary mb-0">
                Buscar
            </button>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl" style="text-align: right">
            <button (click)="clear()" style="width: 125px; margin-top: 22px;" class="btn drp-icon btn-outline-danger" type="button">
                Borrar Filtro
                <i class="feather icon-delete"></i>
            </button>
        </div>
    </div>
</div>


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivitiesComponent} from './activities.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {ChipAutoCompleteModule} from '../../../../shared/chipAutoComplete/chipAutoComplete.module';
import {MatMenuModule} from '@angular/material/menu';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {ScreenLoadingModule} from '../../../../shared/screenLoading/screenLoading.module';
import {QuestionFormModule} from '../../../../shared/questionForm/questionForm.module';

import {ArchwizardModule} from 'angular-archwizard';

@NgModule({
    declarations: [ActivitiesComponent],
    exports: [ActivitiesComponent],
    imports: [
        CommonModule,
        NgbModule,
        QuestionFormModule,
        ChipAutoCompleteModule,
        NgxMaterialTimepickerModule,
        ScreenLoadingModule,
        ArchwizardModule,
        FormsModule,
        MatIconModule,
        MatMenuModule,
    ]
})
export class ActivitiesModule { }

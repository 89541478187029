<header>
    <h3 class="titlePage">
        Contenido del curso.
    </h3>
    <ul style="margin: 0px 3px 12px 0px; padding: 0;" class="breadCrumbsContainer">
      <nav>
        <ul>
          <li class="breadCrumbsIcon" (click)="navigateUrl({url:'/'})"> 
            <mat-icon style="color: #00213d; font-size: 25px; margin-right: 7px;"> home </mat-icon>  
            <span style="margin-top: 2px; font-weight: 100;"> Home </span> 
          </li>
          <li *ngFor="let bc of breadcrumbs" class="breadCrumbsIcon" (click)="navigateUrl(bc)">
            <mat-icon style="color: #00213d; font-size: 25px; margin-right: 7px;"> keyboard_arrow_right </mat-icon> 
            <mat-icon *ngIf="bc.icon" style="color: #fff; font-size: 25px; margin-right: 20px;">{{bc.icon}}</mat-icon>  
            <span style="margin-top: 2px; font-weight: 100;">  {{ bc.name }} </span> 
          </li>
        </ul>
      </nav>    
    </ul>
</header>
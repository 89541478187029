<article class="wrapperTag">
    <h4 class="title">Buscar por etiqueta</h4>
    <app-tags-eduk
        [tags]="listTagsUpdated"
        title="Escriba o haga click para elegir Etiqueta"
        placeHolder="Haz click aqui!"
        [listTag]="[]"
        showAll="{{true}}"
        (tagsEnter) = "tagEnter($event)"></app-tags-eduk>

    <article *ngIf="courseTags" class="wrapperTagCourse">
       <!-- <h4 class="title">Etiquetas</h4> -->
        <p>A continuacion se listan todas las etiquetas que han sido agregadas al curso <b>"{{courseTags?.title}}"</b></p>
        <ul>
            <li *ngFor="let tag of courseTags?.tags;" >{{tag.name}}</li>
        </ul>
    </article>
</article>



import {
  MessageResponse,
  MediaAsset,
  Delivery,
  ContactResponse,
  BaseRequestActivityScoreRequest,
  BaseRequestOfActivityRequest,
  Activity,
  Comment,
  ActivityRequest
} from '../../../../api/client/data-contracts';
import { StorageService } from '../../../../api/service/storage.service';
import { Service } from '../../../../api/service/Service';
import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ChangeDetectorRef} from '@angular/core';
import { ToastOptions, ToastyService } from "ng2-toasty";
import { ActivatedRoute } from '@angular/router';
import {NgbDateParserFormatter, NgbDateStruct, NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap'
import { CustomDateParserFormatter } from 'src/app/util/dateFormatterService'
import { GrantsService } from 'src/app/services/grants.service';
import { MatDialog } from '@angular/material/dialog';
import { MediaPreviewModalComponent } from 'src/app/theme/shared/components/media-preview-modal/media-preview-modal.component';
import {ActivityType, FILES_NOT_PREVEW} from '../../../../api/utils/Constants';
import * as moment from 'moment';
import { ScreenLoadingComponent } from '../../../shared/screenLoading/screenLoading.component';
import { QuestionFormComponent } from '../../../shared/questionForm/questionForm.component';
import { NotesFormComponent } from '../../../shared/notesForm/notesForm.component';
import {FormQuestion, Quiz} from '../../../../api/models/question';
import { v4 as uuidv4 } from 'uuid';


const permittedFiles = [
  'video/mp4',
  'video/quicktime',
  'application/pdf'
];

interface Deliverables {
  delivery: Delivery;
  contact: ContactResponse;
  has_delivery: boolean;
  progress: any;
}

@Component({
  selector: 'app-activity-task',
  templateUrl: './activity-task.component.html',
  styleUrls: ['./activity-task.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})


export class ActivityTaskComponent implements OnInit {
  //@ViewChild(QuestionFormComponent) questionFormm: QuestionFormComponent;
  @ViewChildren('questions') questionF: QueryList<QuestionFormComponent>;
  @ViewChildren('notes') notesF: QueryList<NotesFormComponent>;

  @ViewChild(ScreenLoadingComponent) screenLoading: ScreenLoadingComponent;
  public moment: any = moment;

  title = '';
  id = '';
  
  questionsList: FormQuestion [] = [];
  
  questionActiveF: boolean;
  startDate: NgbDateStruct;
  
  endDate: NgbDateStruct;

  formInput: ActivityRequest;
  formInputQuestions: Quiz;
  linkMeet: string;
  plataformMeet: string;
  activityEndDate: NgbDateStruct;
  activityDate;
  resourcesFiles: MediaAsset[] = [];
  activityTitle: string;
  activityTime: string;
  activityDescription: string;
  activityScore = 0;
  activityComments: Comment[] = [];


  messageScreen = "El video se esta subiendo, no cerrar esta pantalla!";
  
  
  isTask: boolean;
  isVideo: boolean;
  isDocument: boolean;
  isQuiz: boolean;
  
 
  position = 'top-center';
  defaultPanelId = "toggle1";
  
  deliverablesList: Array<Deliverables> = [];
  documentAttached: any;
  commentsQuiz: string;
  selectedDeliverable: Deliverables;
  score: number;
  comment: string;
  lastPanelId: string = null;
  files: any[] = [];
 
  isMeet: boolean;
  messages: Array<MessageResponse>;
  allMessages: Array<MessageResponse>;
  isNewMessage: boolean = false;
  isReplyMessage: boolean = false;
  loading: boolean;
  isForwardMessage: boolean = false;
  attachments = Array<File>();
  subject: string = "";
  message: string = "";
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  showComments: boolean = false;
  
  selectedComment = null;
  attachment: MediaAsset;
  taskType = ActivityType;
  showQuiz = false;
  showAttempts = false;

  @ViewChild('modalConfirmationDownload', { static: true }) modalConfirmationDownload: ElementRef;

  constructor(
    private storageService: StorageService,
    private toastyService: ToastyService,
    private activatedRoute: ActivatedRoute,
    public grantsService: GrantsService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.id = this.activatedRoute.snapshot.queryParams['id'];
    this.loadActivities();
  }

  ngOnInit(): void {

    this.formInputQuestions = {
      minScore: null  ,
      score: null,
      retries: 0,
      questions: null
    };


  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  loadActivities(isUpdate: boolean = false): void {
    const service = new Service(this.storageService);
    service.getDeliveriesByActivity(this.id).then((list) => {
      list.forEach((deliverable) => {
        this.setStatus(deliverable.delivery);
      });
      if (list && list.length > 0) {
        this.deliverablesList = list;
        if (isUpdate) {
          // tslint:disable-next-line:max-line-length
          this.selectedDeliverable = this.deliverablesList.find(deliverable => deliverable.delivery.id === this.selectedDeliverable?.delivery?.id);
        }
      }
      this.setActivityFields();

      list.map(val => {
          this.showQuiz = !!val?.progress?.answers?.find(question => question.question.multipleChoice === false);
      });

    });
  }

  setMessage(isQuiz?: boolean): void{
    if (isQuiz) {
      this.messageScreen = 'El Cuestionario se esta creando, no cerrar esta pantalla!';
    } else {
      switch (this.formInput.type) {
        case 'MEET':
          this.messageScreen = 'La Reunion se esta actualizando, no cerrar esta pantalla!';
          break;
        case 'TASK':
          this.messageScreen = 'La Tarea se esta actualizando, no cerrar esta pantalla!';
          break;
        case 'WATCH_VIDEO':
          this.messageScreen = 'El Videoo se esta subiendo, no cerrar esta pantalla!';
          break;
        case 'READ_DOCUMENT':
          this.messageScreen = 'El Documento se esta subiendo, no cerrar esta pantalla!';
          break;
        default:
          this.messageScreen = 'Procesando, no cerrar esta pantalla!';
          break;
      }
    }
  }

  changeType() {
    switch (this.formInput.type) {
      case 'MEET':
        this.isQuiz = false;
        this.isMeet = true;
        this.isTask = false;
        this.isDocument = false;
        this.isVideo = false;
        break;
      case 'TASK':
        this.isQuiz = false;
        this.isMeet = false;
        this.isTask = true;
        this.isDocument = false;
        this.isVideo = false;
        break;
      case 'WATCH_VIDEO':
        this.isQuiz = false;
        this.isMeet = false;
        this.isTask = false;
        this.isDocument = false;
        this.isVideo = true;
        break;
      case 'READ_DOCUMENT':
        this.isQuiz = false;
        this.isMeet = false;
        this.isTask = false;
        this.isDocument = true;
        this.isVideo = false;
        break;
      case 'QUIZ':
              this.isQuiz = true;
              this.isMeet = false;
              this.isTask = false;
              this.isDocument = false;
              this.isVideo = false;
              break;
      default:
        this.isMeet = false;
        this.isTask = false;
        this.isDocument = false;
        this.isVideo = false;
        break;
    }

  }

  setActivityFields() {
    const service = new Service(this.storageService);
    const studentId = this.selectedDeliverable ? this.selectedDeliverable?.contact?.id : '';
    service.getActivity(this.id, studentId).then(
      (activity: Activity) => {
        const endDate = activity.end_at.split('-');
        this.linkMeet = activity?.body?.link?.href ?? activity?.link?.href;
        this.plataformMeet = activity?.body?.link?.vendor;
        this.activityEndDate = { year: parseInt(endDate[0]), month: parseInt(endDate[1]), day: parseInt(endDate[2]) };
        this.activityDate = [this.activityEndDate.day, this.activityEndDate.month, this.activityEndDate.year].join('-');
        this.resourcesFiles = activity.resources;
        this.activityTitle = activity.title;
        this.activityTime = activity.end_time ? activity.end_time.substring(0, 5) : null;
        this.activityDescription = activity.description;
        this.activityScore = activity.score;
        this.activityComments = activity.comments;
        this.formInput = activity.body;
        this.formInput.videType = this.formInput.type === 'WATCH_EXTERNAL_VIDEO' ? 'URL' : 'LOAD_VIDEO';
        this.formInput.type = this.formInput.type === 'WATCH_EXTERNAL_VIDEO' ? 'WATCH_VIDEO' : this.formInput.type;
        this.formInput.meet_link = this.linkMeet;
        this.formInput.meet_vendor = this.plataformMeet;
        this.formInput.minScore = activity?.quiz?.minScore;
        this.formInput.retries = activity?.quiz?.retries;
        this.formInput.end_time = activity.body.end_time.substring(0, 5);
        this.endDate = { year: parseInt(endDate[0]), month: parseInt(endDate[1]), day: parseInt(endDate[2]) };
        this.changeType();
        this.formInputQuestions = activity.quiz ? activity.quiz : {score: this.formInput.score ,minScore: null, retries: null, questions: []};
        this.questionActiveF = activity?.required_quiz ? activity?.required_quiz : true;

        this.questionsList = [];
        activity.quiz?.questions?.forEach((q, i: number) => {
          this.questionsList.push({
            id: q.id,
            title: q.title,
            score: q.score,
            multipleChoice: q.multipleChoice,
            choices: q.choices
          });
        });
        this.changeDetectorRef.detectChanges();
      }
    )}

  setStatus(delivery: Delivery) {
    switch (delivery.status) {
      case "TODO":
        delivery.statusDesc = 'No Entregada';
        delivery.statusColor = '#ff635f';
        break;
      case "DOING":
        delivery.statusDesc = 'En Proceso';
        delivery.statusColor = '#1e3db2';
        break;
      case "COMPLETED":
        delivery.statusDesc = 'Entregada';
        delivery.statusColor = '#4fc67a';
        break;
      case "REVIEWED":
        delivery.statusDesc = 'Revisada';
        delivery.statusColor = '#ffa600';
        break;
      default:

    }
    this.score = delivery.score;
    if(delivery.comments!=null && delivery.comments.length>0){
      this.comment = delivery.comments[0]['comment'];
    }
  }

  upload() {
    const service = new Service(this.storageService);
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Cargando",
      msg: "Cargando Recurso(s).",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    service.attachFilesActivity(this.id, this.files).then(
      () => {
        this.files = [];
        this.loadActivities();
      }
    )
  }

  selectDocument($event): void {
    this.toastyService.clearAll();
    let toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando...",
      timeout: 5000,
      theme: "default"
    };

    const file = $event.target.files[0];
    this.documentAttached = $event;

    if (!permittedFiles.includes(file.type)) {
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: "Error",
        msg: 'Tipo de archivo no permitido.',
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    }
  }

  onSelectedDeliverable(delivery: Deliverables) {
    this.selectedComment = null;
    this.selectedDeliverable = delivery;
    this.score = this.selectedDeliverable?.delivery?.score;
    // tslint:disable-next-line:max-line-length
    this.comment = this.selectedDeliverable?.delivery?.comments?.length > 0
                      ? this.selectedDeliverable?.delivery?.comments[this.selectedDeliverable?.delivery?.comments?.length - 1]['comment']
                      : '';
  }

  onSelectedComment(comment: Comment) {
    this.selectedDeliverable = null;
    const service = new Service(this.storageService);
    //@ts-ignore
    service.getOneContact(comment.publisher.id)
      .then((contact: ContactResponse) => {
        this.selectedComment = {
          id: comment.id,
          //@ts-ignore
          description: comment.comment,
          createdAt: comment.created_at,
          contact: {
            names: `${contact.names} ${contact.last_names}`,
            avatar: contact.avatar.href,
            email: contact.email,
          }
        }
      });
  }

  saveQualification(deliverable) {
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando nota",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    const data: BaseRequestActivityScoreRequest = {
      app_id: '',
      data: {
        comment: this.comment,
        score: this.score
      }
    };
    service.updateQualification(deliverable.activity.id, deliverable.id, data).then(
      () => {
        this.loadActivities(true);
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Exito",
          msg: "Calificación guardada exitosamente.",
          timeout: 3000,
          theme: "default"
        };
        this.toastyService.info(toastOptions);
      }
    ).catch(reason => {
      this.screenLoading.off();
      this.toastyService.clearAll();

      const errosList = reason?.response?.data?.errors;

      console.log('mis errores:::', reason);
      const toastOptions: ToastOptions = {
        title: "Error",
        msg: 'errosList',
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    });
  }

  panelShadow($event: NgbPanelChangeEvent, shadow): void {
    const { nextState } = $event;
    const activePanelId = $event.panelId;
    const activePanelElem = document.getElementById(activePanelId);

    if (!shadow?.isExpanded(activePanelId)) {
      activePanelElem?.parentElement?.classList.add("open");
    }

    if (!this.lastPanelId) this.lastPanelId = this.defaultPanelId;

    if (this.lastPanelId) {
      const lastPanelElem = document.getElementById(this.lastPanelId);

      if (this.lastPanelId === activePanelId && nextState === false)
        activePanelElem?.parentElement?.classList.remove("open");
      else if (this.lastPanelId !== activePanelId && nextState === true) {
        lastPanelElem?.parentElement?.classList.remove("open");
      }

    }

    this.lastPanelId = $event.panelId;
  }

  setQuestion(): void{
    this.questionsList.push({
      id: '',
      title: '',
      score: 0,
      multipleChoice: false,
      choices: []
    });
    this.formInputQuestions.questions = this.questionsList;
    this.changeDetectorRef.detectChanges();
  }

  fillQuestion(): void{
    this.showAttempts = false;
      this.questionF.forEach((q, i: number) => {
        const inputQuestion = q.getForm();
        this.questionsList[i].id = uuidv4();
        this.questionsList[i].title = inputQuestion.title;
        this.questionsList[i].score = inputQuestion.score;
        this.questionsList[i].multipleChoice = inputQuestion.multipleChoice;
        this.questionsList[i].choices = inputQuestion.choices;
        this.showAttempts = inputQuestion.multipleChoice && true;
      });

      this.formInputQuestions.questions = this.questionsList;

      console.log('SHOW ::: ', this.showAttempts);
  }

  saveNotes(): void{
    const notesObject = {
      app_id: 'app',
      data: {
        comment: this.commentsQuiz,
        questionScores:[]
      }
    };
    this.screenLoading.on();

    this.notesF.forEach((n, i: number) => {
      notesObject.data.questionScores.push(n.resultQuestionAry);
    });

    const service = new Service(this.storageService);
    service.updateQuizNote(this.selectedDeliverable?.delivery?.activity?.id, this.selectedDeliverable?.delivery?.id, notesObject).then((list) => {

      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Calificación guardada exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.screenLoading.off();
      this.loadActivities();
      this.toastyService.success(toastOptions);
    })
    .catch(reason => {
      this.screenLoading.off();
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: "Error",
        msg: reason.message,
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    });

  }

  newQuestion(): void {
    let add = false;
    if (this.questionsList.length > 0){
      if (this.questionF) {
        this.questionF.forEach((q, i: number) => {
          if (q.formInputQuestion.title && q.formInputQuestion.score){
            if (q.formInputQuestion.multipleChoice) {
              if (q.formInputQuestion.choices.length > 0){
                add = true;
              }else{
                this.toastyService.clearAll();
                add = false;
                var toastOptions: ToastOptions = {
                  title: "Pregunta sin respuestas.",
                  msg: 'Se debe ingresar almenos una respuesta valida)',
                  timeout: 5000,
                  theme: "default"
                };
                this.toastyService.error(toastOptions);
              }
            }else{
              add = true;
            }
          } else {
            this.toastyService.clearAll();
            add = false;
            var toastOptions: ToastOptions = {
              title: "Completar campos obligatorios.",
              msg: 'Se debe ingresar un valor en los campos (Pregunta,Nota)',
              timeout: 5000,
              theme: "default"
            };
            this.toastyService.error(toastOptions);
          }
        });
        if (add){
          this.setQuestion();
        }
      }
      /**/
    }else{
      this.setQuestion();
    }
  }

  saveError(error) {
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Error",
      msg: error,
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.error(toastOptions);
  }

  uploadDocument(idActivity): void {
    const service = new Service(this.storageService);
    const file = this.documentAttached.target.files[0];

    service.addDocumentActivity(file, this.formInput.title, this.formInput.description, idActivity)
        .then(async () => {
          this.screenLoading.off();
          this.toastyService.clearAll();
          this.documentAttached = '';
          this.isMeet = false;
          this.isTask = false;
          this.isDocument = false;
          this.isVideo = false;
          // tslint:disable-next-line:no-shadowed-variable
          const toastOptions: ToastOptions = {
            title: "Exito",
            msg: "Video subido exitosamente!",
            timeout: 3000,
            theme: "default"
          };
          this.toastyService.info(toastOptions);
        })
        .catch(reason => {
          this.screenLoading.off();
          this.toastyService.clearAll();
          const toastOptions: ToastOptions = {
            title: "Error",
            msg: reason.message,
            timeout: 5000,
            theme: "default"
          };
          this.toastyService.error(toastOptions);
        });
  }

  updateActivityTask(form: any, isQuiz?: boolean): void {
    this.messageScreen = 'Cargando!';
    this.loading = true;

      if (this.questionsList.length > 0) {
        this.changeDetectorRef.detectChanges();
        this.fillQuestion();
      }

    if (!this.formInputQuestions.retries){
      this.formInputQuestions.retries = 0;
    }

   if (form.valid) {
      this.setMessage(isQuiz);

     if(!this.showAttempts){
       this.formInputQuestions.retries = 0;
     }

      this.screenLoading.on();

      const service = new Service(this.storageService);

     if (this.formInput.type === 'WATCH_VIDEO'){
       this.changeVideoType();
     }


      this.formInput.end_at = [this.endDate.day, this.endDate.month, this.endDate.year].join('-');

      let data: BaseRequestOfActivityRequest;

      // @ts-ignore
      // tslint:disable-next-line:triple-equals
      if ((this.questionsList.length > 0)) {
        data = {
          app_id: '',
          data: {
            title: this.formInput.title,
            description: this.formInput.description,
            type: this.formInput.type,
            end_at: this.formInput.end_at,
            end_time: this.formInput.end_time.substr(0, 5),
            score: this.formInput.score,
            meet_vendor: this.formInput.meet_vendor,
            meet_link: this.formInput.meet_link,
            require_quiz: (this.questionsList.length > 0),
            quiz:  (this.questionsList.length > 0) && this.formInputQuestions
          }
        };
      } else {
        data = {
          app_id: '',
          data: {
            title: this.formInput.title,
            description: this.formInput.description,
            type: this.formInput.type,
            end_at: this.formInput.end_at,
            end_time: this.formInput.end_time.substr(0, 5),
            score: this.formInput.score,
            meet_vendor: this.formInput.meet_vendor,
            meet_link: this.formInput.meet_link,
            require_quiz: (this.questionsList.length > 0),
          }
        };
      }


      service.updateActivity(this.id, data).then(
          () => {
            // @ts-ignore
            if (this.formInput?.type === 'WATCH_VIDEO' || this.formInput?.type === 'READ_DOCUMENT') {
              if (this.documentAttached){
                this.uploadDocument(this.id);
              } else {
                this.screenLoading.off();
              }
            } else {
              this.screenLoading.off();
            }

            // @ts-ignore
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Exito",
              msg: '¡Actividad actualizada exitosamente!',
              timeout: 3000,
              theme: "default"
            };
            this.toastyService.success(toastOptions);
            this.loadActivities();
            this.loading = false;
          }
      ).catch((error) => {
        // @ts-ignore
        this.screenLoading.off();
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Error",
          msg: error,
          timeout: 3000,
          theme: "default"
        };
        this.toastyService.error(toastOptions);
        this.loading = false;
      });
    }else {
      this.loading = false;
      this.toastyService.clearAll();
      this.toastyService.error({
        title: 'Error',
        msg: 'Completar todos los campos obligatorios!',
        timeout: 5000,
        theme: 'default'
      });
    }


  }

  downloadAttachment(attachment: MediaAsset, close: boolean) {
    if ( close ){
      // @ts-ignore
      this.modalConfirmationDownload.hide();
    }
    const filename = attachment.file_name ? attachment.file_name : 'resource_file_' + Math.random();
    const a = document.createElement('a');
    a.download = filename;
    a.href = attachment.href;
    a.textContent = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => document.body.removeChild(a), 100);
  }

  openFilePreview(attachment: MediaAsset) {
    if( FILES_NOT_PREVEW[attachment.type]){
      this.attachment = attachment;
      // @ts-ignore
      this.modalConfirmationDownload.show();
    }else {
      this.dialog.open(MediaPreviewModalComponent, {
        data: attachment,
        height: '85%',
        width: '75%',
        autoFocus: false,
      }).afterClosed().subscribe(() => {
        //TODO If action needed after closed
      });
    }
  }

  removeAttachment(file) {
    const service = new Service(this.storageService);

    service.removeResourceFromActivity(this.id, file.id).then(
      () => {
        this.removeFile(file);
      }
    );
  }

  removeQuestion(id: number): void {
    this.questionsList.splice(id, 1);
  }

  uploadFile($event) {
    this.attachments.push($event.target.files[0])
  }

  removeFile(attachment) {
    const index = this.resourcesFiles.indexOf(attachment);

    if (index >= 0) {
      this.resourcesFiles.splice(attachment, 1);
    }
  }

  changeVideoType(): void {
    switch (this.formInput.videType) {
      case 'URL':
        this.formInput.type = 'WATCH_EXTERNAL_VIDEO';
        break
      default:
        this.formInput.type = 'WATCH_VIDEO';
        break
    }
  }


  validUrl() {
    if(this.formInput.meet_link.length > 0){
      this.documentAttached = true;
    }
  }


  // search(keyword) {
  //   if (keyword !== '') {
  //     this.messages = this.allMessages.filter(
  //       message =>
  //         message.sender.name.toLowerCase().includes(keyword.toLowerCase())
  //         || message.subject.toLowerCase().includes(keyword.toLowerCase()));
  //   } else {
  //     this.messages = this.allMessages;
  //   }
  // }
}

<article class="wrapper-couser">
    <section class="header">
        <span class="line"></span>
        <app-search-course (enterValue)="search($event)"></app-search-course>
    </section>
    <section>
        <app-header-course
                (isNewSpace)="createNewSpace($event)"
                (isEditSpace)="editSpace($event)"
                (spaceObject)="editSpaceObject($event)"
                [listSpace]="this.listSpace"
                (spaceActive)="spaceActive($event)"
                [isStaff]="isStaff"
                [space]="this.space"
                (filters)="setFilterNav($event)"></app-header-course>
    </section>
    <section>
        <div class="row" style="margin: 0">
            <div class="clearRow col-md-9 order-2">
                <app-itemView-course
                        [space]="this.space"
                        [data]="this.dataCourse"
                        [isNewSpace]="this.isNewSpace"
                        [isEditSpace]="this.isEditSpace"
                        [spaceObject]="this.spaceEdit"
                        (closeModal)="this.closeModalOption()"
                        (udpateSpace)="this.updateSucursal($event)"
                        (refresh)="getSettings();"
                        (refreshSucursal)="getS();"
                        (mouseEnter)="setMouseEnterData($event)"
                        (tagOut)="setTag($event)">
                </app-itemView-course>
            </div>
            <div class="clearRow col-md-3 order-2">
                <app-asideBar-course
                        [courseTags]="this.dataMouseEnter"
                        (filterTags)="setFilterTag($event)"
                        [tagList]="this.tagList">
                </app-asideBar-course>
            </div>
        </div>
    </section>
</article>

<!--
<div class="row">
    <div class="clearRow col-md-9">
        <app-search-course
                [isStaff]="isStaff"
                (isNewSpace)="createNewSpace($event)"
                 [listSpace]="this.listSpace"
                (spaceActive)="spaceActive($event)"></app-search-course>
        <app-header-course [space]="this.space" (filters)="setFilterNav($event)"></app-header-course>

    </div>

</div> -->

<app-screen-loading #screenLoading [message]="message"></app-screen-loading>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseComponent } from './course.component';
import { CourseRoutingModule } from './course-routing.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbButtonsModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastyModule } from "ng2-toasty";
import { SearchModule } from './components/search/search.module';
import { AsideBarModule } from './components/asideBar/asideBar.module';
import { HeaderModule } from './components/header/header.module';
import { ItemViewModule } from './components/itemView/itemView.module';
import { ChipAutoCompleteModule } from '../shared/chipAutoComplete/chipAutoComplete.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ScreenLoadingModule } from '../shared/screenLoading/screenLoading.module';

@NgModule({
  imports: [
    CommonModule,
    ChipAutoCompleteModule,
    CourseRoutingModule,
    ScreenLoadingModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    ToastyModule,
    SearchModule,
    ReactiveFormsModule,
    AsideBarModule,
    HeaderModule,
    ItemViewModule,
    NgbModule,
    NgbButtonsModule,
  ],
  declarations: [
    CourseComponent
  ]
})
export class CourseModule { }

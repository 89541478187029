import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Course, Phone } from "src/api/client/data-contracts";
import { ToastOptions, ToastyService } from "ng2-toasty";
import { UiModalComponent } from "../../../theme/shared/components/modal/ui-modal/ui-modal.component";
import { CourseStructure } from "../../../../api/models/course";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { Router } from "@angular/router";

import { StorageService } from "../../../../api/service/storage.service";
import { Service } from "../../../../api/service/Service";
import {
  ContactResponse,
  SpaceRequest,
} from "../../../../api/client/data-contracts";
import { ChipAutoCompleteComponent } from "../../../shared/chipAutoComplete/chipAutoComplete.component";

import { ContactAssignManagerComponent } from "../../../content/contact-assign-manager/contact-assign-manager.component";
import { TagStructure } from "../../../../api/models/chipAutoComplete";
import countriesArr from "../../../../assets/data/countries-phone-codes";
import { ContactTypeEnum } from "../../../../api/utils/Constants";
import { SpaceNodeFlat } from "../../../../util/space";

@Component({
  selector: "app-itemView-course",
  templateUrl: "./itemView.component.html",
  styleUrls: ["./itemView.component.scss"],
})
export class ItemViewComponent implements OnInit, OnChanges {
  @Input() data: CourseStructure[];
  @Input() space: string;
  @Input() isNewSpace: boolean;
  @Input() isEditSpace: boolean;
  @Input() spaceObject: SpaceNodeFlat;
  @Output() value: EventEmitter<string> = new EventEmitter();
  @Output() tagOut: EventEmitter<TagStructure[]> = new EventEmitter();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshSucursal: EventEmitter<boolean> = new EventEmitter();
  @Output() mouseEnter: EventEmitter<CourseStructure> = new EventEmitter();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() udpateSpace: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(UiModalComponent) addCourse: UiModalComponent;
  @ViewChild("modalDelete") modalDelete: UiModalComponent;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("fruitInput") fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild(ChipAutoCompleteComponent) chipEduk: ChipAutoCompleteComponent;

  formInput: Course;
  contactDefault: string;

  messageError: string;
  phoneType = "CELLPHONE";
  phoneCode = "+(502)";
  phoneNumber: string;
  grantNewCourse = !!this.storageService
    .getCurrentSession()
    .grants.find((g) => g.name === "CONTENT")
    .grants.find((gg) => gg.name === "CREAR");

  admins: any;
  address: string;

  tagList: TagStructure[] = [];
  countries = countriesArr;
  tagEnterList: TagStructure[] = [];
  userPhones: Phone[];
  visible = true;
  selectable = true;
  isEdit = false;
  associateTitle = "";
  allUsers: Array<ContactResponse> = new Array<ContactResponse>();
  loading = false;
  image: string;
  selectedFile: File = null;
  courseSelected: CourseStructure;
  options: string[] = ["Option 1", "Option 2", "Option 3", "Option 4"];
  staffMembers: ContactResponse[] = [];

  constructor(
    private toastyService: ToastyService,
    public storageService: StorageService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setForm();
    const service = new Service(this.storageService);
    this.getTags();

    service
      .getAllUsersByRole(
        "",
        null,
        `${ContactTypeEnum.STAFF},${ContactTypeEnum.OWNER}`
      )
      .then((value: any) => {
        value.data?.forEach((value1) => {
          this.staffMembers.push(value1);
        });
      });
  }

  ngAfterViewInit() {
    this.addCourse.onHide.subscribe(() => {
      this.closeModal.emit();
    });
  }

  mouseEnteFn(course: CourseStructure): void {
    this.mouseEnter.emit(course);
  }

  getTags(): void {
    const service = new Service(this.storageService);
    service
      .getTags()
      .then((value) => {
        this.tagList = value;
        this.tagOut.emit(value);
      })
      .catch((error) => {
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Error",
          msg: error,
          timeout: 5000,
          theme: "default",
        };
        this.toastyService.error(toastOptions);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isNewSpace) {
      this.messageError = null;
      this.isNewSpaceChange();
    }
    if (changes.isEditSpace) {
      this.messageError = null;
      this.isNewSpaceChange();
      if (this.isEditSpace) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
    }
  }

  private isNewSpaceChange(): void {
    if (this.isNewSpace) {
      this.formInput.name = "";
      this.formInput.description = "";
      this.address = "";
      this.admins = "";
      this.phoneNumber = null;
      this.addCourse.show();
    } else if (this.isEditSpace) {
      this.formInput.name = this.spaceObject.title;
      this.formInput.description = this.spaceObject.description;
      this.formInput.address = this.spaceObject.branch.address;
      this.image = this.spaceObject?.avatar?.href;
      this.phoneNumber = null;
      this.addCourse.show();
      if (this.spaceObject?.branch?.admins?.length > 0) {
        this.admins = this.spaceObject?.branch?.admins[0].contact_id;
      }
      if (this.spaceObject?.branch?.telephones?.length > 0) {
        this.phoneNumber = this.spaceObject?.branch?.telephones[0];
      }
    } else {
      this.addCourse?.hide();
    }
  }

  tagEnter(t: TagStructure[]): void {
    this.tagEnterList = t;
  }

  selectCourse(course: CourseStructure): void {
    this.isEdit = true;
    this.courseSelected = course;
    this.formInput.name = course.title;
    this.image = course.logo;
    this.formInput.description = course.description;
    this.addCourse.show();
  }

  openRemoveModal(course: CourseStructure): void {
    this.isEdit = true;
    this.courseSelected = course;
    this.formInput.name = course.title;
    this.image = course.logo;
    this.formInput.description = course.description;
    this.modalDelete.show();
  }

  setForm(): void {
    this.formInput = {
      description: "",
      logo: "",
      name: "",
      tag: [],
    };
  }

  setActiveItem(i: number): void {
    this.data.forEach((row) => (row.active = false));
    this.data[i].active = true;
  }

  openNewCourse(): void {
    this.isEdit = false;
    this.addCourse.show();
    this.isNewSpace = false;
    this.formInput.name = "";
    this.formInput.description = "";
    this.image = "";
    this.chipEduk.clear();
    this.courseSelected = null;
  }

  openCourse(course: CourseStructure): void {
    this.courseSelected = course;
    this.router.navigate([`menu/course/activity/${course.id}/${course.title}`]);
  }

  openAssignModal(data: {
    allContacts: any[];
    assignedContacts: string[];
    selectedSpace: CourseStructure;
    title: string;
  }): void {
    this.dialog.open(ContactAssignManagerComponent, {
      data,
      height: "75%",
      width: "55%",
      autoFocus: false,
    });
  }

  assign(course: CourseStructure, type: string): void {
    const service = new Service(this.storageService);
    this.allUsers = [];
    course.type = "CLASSROOM";
    // tslint:disable-next-line:max-line-length
    this.associateTitle =
      type === "STUDENT"
        ? this.storageService.getContactTypes()["STUDENT"]["name"]
        : this.storageService.getContactTypes()["STAFF"]["name"];
    service
      .getAllUsersByRole("", 1, type)
      .then((value: any) => {
        this.allUsers = value.data;
        if (type === "STUDENT") {
          service
            .getStudentsBySpace(course.id)
            .then((students) => {
              const assignedStudentsIds = students.map((user) => user.id);
              this.openAssignModal({
                allContacts: this.allUsers,
                assignedContacts: assignedStudentsIds,
                selectedSpace: course,
                title: this.associateTitle,
              });
            })
            .catch((error) => {
              this.toastyService.clearAll();
              let toastOptions: ToastOptions = {
                title: "Error",
                msg: error,
                timeout: 5000,
                theme: "default",
              };
              this.toastyService.error(toastOptions);
              this.loading = false;
            });
        } else {
          service
            .getStaffsBySpace(course.id)
            .then((staffs) => {
              const assignedStaffIds = staffs.map((staff) => staff.id);
              this.openAssignModal({
                allContacts: this.allUsers,
                assignedContacts: assignedStaffIds,
                selectedSpace: course,
                title: this.associateTitle,
              });
            })
            .catch((error) => {
              this.toastyService.clearAll();
              var toastOptions: ToastOptions = {
                title: "Error",
                msg: error,
                timeout: 5000,
                theme: "default",
              };
              this.toastyService.error(toastOptions);
              this.loading = false;
            });
        }
      })
      .catch((error) => {
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Error",
          msg: error,
          timeout: 5000,
          theme: "default",
        };
        this.toastyService.error(toastOptions);
        this.loading = false;
      });
  }

  deleteCourse(): void {
    const service = new Service(this.storageService);
    service.removeSpace(this.courseSelected.id).then(() => {
      // @ts-ignore
      this.modalDelete.hide();
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Curso eliminado exitosamente.",
        timeout: 3000,
        theme: "default",
      };
      this.toastyService.error(toastOptions);
      this.formInput.description = "";
      this.formInput.name = "";
      this.formInput.logo = "";
      this.formInput.tag = [];

      this.refresh.emit(true);
    });
  }

  setImageCourse(fileInput: any): void {
    const file: File = fileInput.target.files[0];
    this.selectedFile = fileInput.target.files[0] as File;

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result as string;
      };
    }
    this.formInput.logo = fileInput.target.files[0];
  }

  save(create: boolean): void {
    if (
      this.formInput.name &&
      (this.isNewSpace
        ? this.formInput.name &&
          this.formInput.address &&
          this.admins &&
          this.phoneNumber
        : true)
    ) {
      const service = new Service(this.storageService);
      const type = this.isNewSpace ? "INSTITUTION" : "CLASSROOM";
      const newTags = [];
      const tagE = [];
      this.messageError = null;

      this.tagEnterList.map((tag) => {
        if (!tag.id) {
          newTags.push(tag.name);
        } else {
          tagE.push(tag.id);
        }
      });

      let objCourse: SpaceRequest;

      if (this.isNewSpace || this.isEditSpace) {
        objCourse = {
          branch: {
            name: this.formInput.name,
            address: this.formInput.address,
            admins: [this.admins],
            telephones: [`${this.phoneCode} ${this.phoneNumber}`],
            id: "",
          },
          title: this.formInput.name,
          description: this.formInput.description,
          ...(!this.isEditSpace ? { type: type } : {}),
        };
      } else {
        objCourse = {
          title: this.formInput.name,
          description: this.formInput.description,
          parent_space_id: this.space,
          new_tags: newTags,
          tags: tagE,
          ...(!this.courseSelected?.id ? { type: type } : {}),
        };
      }

      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: "Espere",
        msg: "Guardando registro",
        timeout: 300000000000,
        theme: "default",
      };
      this.toastyService.wait(toastOptions);

      this.loading = true;
      if (this.isEdit) {
        service
          .updateSpace(
            this.isEditSpace ? this.spaceObject.id : this.courseSelected?.id,
            objCourse
          )
          .then(() => {
            if (this.selectedFile) {
              service
                .uploadAvatarSpace(
                  this.isEditSpace
                    ? this.spaceObject.id
                    : this.courseSelected?.id,
                  {
                    file: this.selectedFile,
                  }
                )
                .then((value) => {
                  this.image = value.avatar.href;
                  this.toastyService.clearAll();
                  const toastOptions: ToastOptions = {
                    title: "Exito",
                    msg: this.isNewSpace
                      ? "Sucursal editada exitosamente."
                      : "Curso editado exitosamente.",
                    timeout: 3000,
                    theme: "default",
                  };
                  this.toastyService.info(toastOptions);
                  this.refresh.emit(true);
                  this.udpateSpace.emit(this.space);
                  this.getTags();
                  this.loading = false;
                  this.isEdit = false;
                  this.messageError = null;
                });
              if (!create) {
                this.addCourse.hide();
              } else {
                this.formInput.description = "";
                this.formInput.name = "";
                this.formInput.logo = "";
                this.formInput.address = "";
                this.formInput.tag = [];
              }
            } else {
              this.toastyService.clearAll();
              const toastOptions: ToastOptions = {
                title: "Exito",
                msg: this.isNewSpace
                  ? "Sucursal editada exitosamente."
                  : "Curso editado exitosamente.",
                timeout: 3000,
                theme: "default",
              };
              this.toastyService.info(toastOptions);
              this.refresh.emit(true);
              this.udpateSpace.emit(this.space);
              this.getTags();
              this.loading = false;
              this.isEdit = false;
              this.messageError = null;
              if (!create) {
                this.addCourse.hide();
              } else {
                this.formInput.description = "";
                this.formInput.name = "";
                this.formInput.logo = "";
                this.formInput.address = "";
                this.formInput.tag = [];
              }
            }
          })
          .catch((error) => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Error",
              msg: error,
              timeout: 50000,
              theme: "default",
            };
            this.messageError = error;
            this.toastyService.error(toastOptions);
            this.loading = false;
          });
      } else {
        service
          .createSpace(objCourse)
          .then(() => {
            this.toastyService.clearAll();
            const toastOptions: ToastOptions = {
              title: "Exito",
              msg: this.isNewSpace
                ? "Sucursal creada exitosamente."
                : "Curso creado exitosamente.",
              timeout: 3000,
              theme: "default",
            };
            this.toastyService.info(toastOptions);

            if (this.selectedFile) {
              service
                .uploadAvatarSpace(this.space, { file: this.selectedFile })
                .then((value) => {
                  this.image = value.avatar.href;
                });
            }

            if (!create) {
              this.addCourse.hide();
            } else {
              this.formInput.description = "";
              this.formInput.name = "";
              this.formInput.logo = "";
              this.formInput.address = "";
              this.formInput.tag = [];
            }

            if (this.isNewSpace) {
              this.getTags();
              this.refreshSucursal.emit(true);
            } else {
              this.getTags();
              this.refresh.emit(true);
            }
            this.messageError = null;
            this.loading = false;
          })
          .catch((error) => {
            this.toastyService.clearAll();
            let toastOptions: ToastOptions = {
              title: "Error",
              msg: error,
              timeout: 5000,
              theme: "default",
            };
            this.messageError = error;
            this.toastyService.error(toastOptions);
            this.loading = false;
          });
      }
    } else {
      this.toastyService.clearAll();
      let toastOptions: ToastOptions = {
        title: "Error",
        msg: "Todos los campos marcados con '*' son Requeridos.",
        timeout: 5000,
        theme: "default",
      };
      this.messageError = "Todos los campos marcados con '*' son Requeridos.";
      this.toastyService.error(toastOptions);
      this.loading = false;
    }
  }

  protected readonly countriesArr = countriesArr;
}

<div class="container">
    <div class="boo-wrapper">
        <div class="boo">
            <div class="face"></div>
        </div>
        <div class="shadow"></div>

        <h1>¡Vaya!</h1>
        <p>
            No pudimos encontrar la página que
            <br />
            estabas buscando.
        </p>
    </div>
</div>

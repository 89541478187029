import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import {ChipAutoCompleteModule} from '../../../../shared/chipAutoComplete/chipAutoComplete.module';
import {MatMenuModule} from '@angular/material/menu';
@NgModule({
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
    imports: [
        CommonModule,
        NgbModule,
        ChipAutoCompleteModule,
        FormsModule,
        MatIconModule,
        MatMenuModule,
    ]
})
export class HeaderModule { }

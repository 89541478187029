<aw-wizard #wizard class="arc-wizard" navBarLayout="large-empty-symbols">
    <aw-wizard-step stepTitle="General" [navigationSymbol]="{ symbol: '&#xe8e9;', fontFamily: 'feather' }"
                    [canExit]="validateContact(validationForm)">
        <div class="row">
            <form #validationForm="ngForm" class="editForm" novalidate>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label" for="type">Tipo Actividad*</label>
                            <select class="form-control" id="type" name="type" (change)="changeType()" required
                                    #contactType="ngModel"
                                    [ngClass]="{'is-invalid': !contactType.valid && (contactType.dirty || contactType.touched || isSubmit)}"
                                    [(ngModel)]="formInput.type">
                                <option value="">Ingrese un Tipo Actividad</option>
                                <!--<option [value]="item.key" *ngFor="let item of taskType | enumToArray">{{item.value}}</option> -->
                                <option value="TASK">Tarea</option>
                                <option value="MEET">Reunion</option>
                                <option value="WATCH_VIDEO">Ver Video</option>
                                <option value="READ_DOCUMENT">Leer Documento</option>
                                <option value="QUIZ">Cuestionario</option>
                            </select>
                            <label *ngIf="!contactType.valid && (contactType.dirty || contactType.touched || isSubmit)"
                                   id="validation-contactType-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Debe
                                seleccionar el tipo de espacio.</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Titulo*</label>
                            <input type="text" class="form-control" id="names" name="names"
                                   placeholder="Ingresar Título" #names="ngModel" [(ngModel)]="formInput.title"
                                   [ngClass]="{'is-invalid': !names.valid && (names.dirty || names.touched || isSubmit)}"
                                   required>
                            <label *ngIf="!names.valid && (names.dirty || names.touched || isSubmit)"
                                   id="validation-names-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese un
                                t&iacute;tulo valido.</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label">Descripcion*</label>
                            <textarea
                                    class="form-control"
                                    name="lastNames"
                                    id="lastNames"
                                    #lastNames="ngModel"
                                    [(ngModel)]="formInput.description"
                                    [ngClass]="{'is-invalid': !lastNames.valid && (lastNames.dirty || lastNames.touched || isSubmit)}"
                                    placeholder="Ingresar Descripción"
                                    required
                                    rows="3">
                            </textarea>
                            <label *ngIf="!lastNames.valid && (lastNames.dirty || lastNames.touched || isSubmit)"
                                   id="validation-apellidos-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese una
                                descripci&oacute;n valida.</label>
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]="!isMeet">
                        <div class="form-group">
                            <label class="form-label">Direccion de reunion*</label>
                            <input type="text" class="form-control" name="urlMeet" id="urlMeet" #urlMeet="ngModel"
                                   [(ngModel)]="formInput.meet_link"
                                   [ngClass]="{'is-invalid': !urlMeet.valid && (urlMeet.dirty || urlMeet.touched || isSubmit)}"
                                   placeholder="Ingresar apellidos" [required]="isMeet">
                            <label *ngIf="!urlMeet.valid && (urlMeet.dirty || urlMeet.touched || isSubmit)"
                                   id="validation-urlmeet-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese una
                                descripci&oacute;n valida.</label>
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]="!isMeet">
                        <div class="form-group">
                            <label class="form-control-label" for="type">Plataforma de reunion*</label>
                            <select class="form-control" id="platform" name="platform" [required]="isMeet"
                                    #platform="ngModel"
                                    [ngClass]="{'is-invalid': !platform.valid && (platform.dirty || platform.touched || isSubmit)}"
                                    [(ngModel)]="formInput.meet_vendor">
                                <option value="ZOOM">ZOOM</option>
                                <option value="GOOGLE">Google Meet</option>
                                <!--                               &lt;!&ndash; <option value="SKYPE">Skype</option>-->
                                <option value="TEAMS">Teams</option>
                                <!--                               <option value="WEBEX">Webex</option> &ndash;&gt;-->
                            </select>
                            <label *ngIf="!platform.valid && (platform.dirty || platform.touched || isSubmit)"
                                   id="validation-platform-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Debe
                                seleccionar el tipo de plataforma.</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Fecha de Entrega*</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="dd / mm / aaaa"
                                       [minDate]="startDate ? startDate : minStartDate" #end_at="ngModel"
                                       name="dateEnd" id="dateEnd" [(ngModel)]="endDate" ngbDatepicker
                                       #d2="ngbDatepicker"
                                       [ngClass]="{'is-invalid': !dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)}"
                                       required>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                        <i class="fas fa-calendar f-14"></i>
                                    </button>
                                </div>
                            </div>
                            <label *ngIf="!dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)"
                                   id="validation-dateEnd-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">
                                Ingrese una fecha de entrega valida.
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Hora de Entrega*</label>
                            <input
                                #dateEnd="ngModel"
                                [(ngModel)]="formInput.end_time"
                                [ngClass]="{'is-invalid': !dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)}"
                                [value]="''"
                                [required]="isMeet && isTask"
                                placeholder="Date"
                                name="timeEnd"
                                id="timeEnd"
                                [format]="24"
                                class="timePicker"
                                [ngxTimepicker]="picker">
                            <ngx-material-timepicker #picker></ngx-material-timepicker>
                            <label *ngIf="!dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)"
                                   id="validation-timeEnd-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese una
                                hora de entrega valida.</label>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="!isMeet">
                        <div class="form-group">
                            <label class="form-label">Peso de nota*</label>
                            <input type="number" class="form-control" name="score" id="score" #score="ngModel"
                                   [(ngModel)]="formInput.score" [min]="0" [max]="100"
                                   [ngClass]="{'is-invalid': !score.valid && (score.dirty || score.touched || isSubmit)}"
                                   placeholder="Ingresar Descripción" required>
                            <label *ngIf="!score.valid && (score.dirty || score.touched || isSubmit)"
                                   id="validation-apellidos-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">La nota debe
                                ser entre 0-100</label>
                        </div>
                    </div>

                    <div class="col-md-6" *ngIf="isQuiz">
                        <div class="form-group">
                            <label class="form-label">Nota Minima*</label>
                            <input type="number" class="form-control" name="minScore" id="minScore" #minScore="ngModel"
                                   [(ngModel)]="formInput.minScore" [min]="0" [max]="100"
                                   [ngClass]="{'is-invalid': !minScore.valid && (minScore.dirty || minScore.touched || isSubmit)}"
                                   placeholder="Ingresar Descripción" required>
                            <label *ngIf="!minScore.valid && (minScore.dirty || minScore.touched || isSubmit)"
                                   id="validation-apellidos-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">La nota debe
                                ser entre 0-100</label>
                        </div>
                    </div>

                    <div class="col-md-6" *ngIf="isQuiz">
                        <div class="form-group">
                            <label class="form-label">Oportunidades para responder*</label>
                            <input type="number" class="form-control" name="retries" id="retries" #retries="ngModel"
                                   [(ngModel)]="formInput.retries" [min]="0" [max]="100"
                                   [ngClass]="{'is-invalid': !retries.valid && (retries.dirty || retries.touched || isSubmit)}"
                                   placeholder="Ingresar Descripción" required>
                            <label *ngIf="!retries.valid && (retries.dirty || retries.touched || isSubmit)"
                                   id="validation-apellidos-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">La nota debe
                                ser entre 0-100</label>
                        </div>
                    </div>

                    <article class="col-md-12 wpQ" *ngIf="isQuiz">
                        <div class="wrapper-quiz"> Cuestionario:</div>
                        <section class="row">
                            <div class="col-md-6 pt-4 pl-4">
                                Total de preguntas agregadas: <b> 0 </b>
                            </div>
                            <div class="col-md-6">
                                <button
                                        type="button"
                                        class="btn btn-primary active-button float-right mt-3"
                                        (click)="newQuestion()">
                                    Agregar Pregunta
                                </button>
                            </div>
                        </section>
                        <section class="mt-3">
                            <ngb-accordion
                                    [destroyOnHide]='false'
                                    [closeOthers]="true"
                                    [activeIds]="'toggle' + (questionsList.length - 1)">
                                <ngb-panel class="tabQuestion" cardClass="open"
                                           *ngFor="let q of questionsList; let i = index"
                                           [title]="'Pregunta' + ' ' + (i + 1) " [id]="'toggle' + i">
                                    <ng-template ngbPanelContent>
                                        <span class="deleteQuestion"
                                              (click)="removeQuestion(i)">Eliminar pregunta!</span>

                                        <app-question-form
                                            #questions
                                            (changeData)="this.fillQuestion()"
                                            [defaultData]="q"></app-question-form>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </section>
                    </article>


                    <div class="col-md-12" *ngIf="isVideo || isDocument">
                        <article>
                            <div *ngIf="isVideo" class="form-group">
                                <label class="form-label">Tipo video*</label>
                                <select class="form-control" id="typeVideo" name="typeVideo" required
                                        #typeVideo="ngModel"
                                        [ngClass]="{'is-invalid': !typeVideo.valid && (typeVideo.dirty || typeVideo.touched || isSubmit)}"
                                        [(ngModel)]="formInput.videType">
                                    <option value="LOAD_VIDEO">Cargar un video</option>
                                    <option value="URL">URL</option>
                                </select>
                                <label *ngIf="!typeVideo.valid && (typeVideo.dirty || typeVideo.touched || isSubmit)"
                                       id="validation-contactType-error"
                                       class="error jquery-validation-error small form-text invalid-feedback">Debe
                                    seleccionar un tipo de video.</label>
                            </div>
                        </article>
                        <article>
                            <section [class]="formInput.videType === 'URL' ? '' : 'wrapper-upload'">
                                <div *ngIf="documentAttached && documentAttached.target && (isVideo || isDocument) && formInput.videType != 'URL'"
                                     class="documentAdd">
                                    <i *ngIf="isVideo" class="feather icon-film" style="font-size: 40px"></i>
                                    <i *ngIf="isDocument" class="feather icon-file-text" style="font-size: 40px"></i>
                                    <br/>
                                    {{ documentAttached.target.files[0].name }}
                                </div>
                                <ng-template [ngIf]="formInput.videType === 'URL'" [ngIfElse]="LOAD_VIDEO"
                                             class="form-group">
                                    <div class="form-group">
                                        <label class="form-label">URL del video*</label>
                                        <input type="text" class="form-control" name="urlVideo" id="urlVideo"
                                               #urlVideo="ngModel"
                                               [(ngModel)]="formInput.meet_link" (change)="validUrl()"
                                               [pattern]="urlPattern2"
                                               [ngClass]="{'is-invalid': !urlVideo.valid && (urlVideo.dirty || urlVideo.touched || isSubmit)}"
                                               placeholder="Ingrese una URL" [required]="isMeet">
                                        <label *ngIf="!urlVideo.valid && (urlVideo.dirty || urlVideo.touched || isSubmit)"
                                               id="validation-urlmeet-error"
                                               class="error jquery-validation-error small form-text invalid-feedback">Ingrese
                                            una URL valida.</label>
                                    </div>
                                </ng-template>

                                <ng-template #LOAD_VIDEO>
                                    <input hidden type="file"
                                           accept="{{isVideo ? 'video/mp4,video/x-m4v,video/*' : 'application/pdf' }}  "
                                           #uploader (change)="selectDocument($event)" [multiple]="false"/>
                                    <button type="button" class="btn btn-sm btn-glow-light btn-light btnAdd btnUpload"
                                            (click)="uploader.click()">
                                        <i *ngIf="isVideo" class="feather icon-film"></i>
                                        <i *ngIf="isDocument" class="feather icon-file-text"></i>
                                        {{isVideo ? 'Buscar video' : isDocument && 'Buscar documento'}}
                                    </button>
                                </ng-template>

                            </section>
                        </article>
                    </div>


                </div>
            </form>

            <div class="col-sm-12 centered-content" *ngIf="!loading">
                <div class="btn-group mt-10">
                    <button type="button" class="btn btn-primary btn-sm btn-eduk" (click)="save(validationForm)"
                            [disabled]="isVideo || isDocument ? this.documentAttached ? false : true : false">Guardar
                    </button>
                </div>
            </div>
            <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
                <div class="spinner-border text-info" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <span class="infoD">** para crear la actividad es obligatorio completar todos los campos.</span>
        </div>
    </aw-wizard-step>
</aw-wizard>
<app-screen-loading [message]="message"></app-screen-loading>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthSigninRoutingModule} from './auth-signin-routing.module';
import {AuthSigninComponent, DialogPoliciesDialog} from './auth-signin.component';
import {ToastyModule} from 'ng2-toasty';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ActivitiesModule} from '../course/activity/components/activities/activities.module';
import {ModalModule} from '../theme/shared/components';
import {MatIconModule} from '@angular/material/icon';
import {NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        MatDialogModule,
        CommonModule,
        AuthSigninRoutingModule,
        ToastyModule,
        FormsModule,
        ReactiveFormsModule,
        ActivitiesModule,
        ModalModule,
        MatIconModule,
        NgbTabsetModule,
    ],
  declarations: [
    AuthSigninComponent,
    DialogPoliciesDialog,
    ResetPasswordComponent,
    ChangePasswordComponent,
  ],
  bootstrap: [DialogPoliciesDialog],
})

export class AuthSigninModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseActivityComponent } from './activity.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbButtonsModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastyModule } from "ng2-toasty";
import { SearchModule } from './components/search/search.module';
import { AsideBarModule } from './components/asideBar/asideBar.module';
import { ResourcesModule } from './components/resources/resources.module';
import { ActivitiesModule } from './components/activities/activities.module';
import { HeaderModule } from './components/header/header.module';
import { ItemViewModule } from './components/itemView/itemView.module';
import { ChipAutoCompleteModule } from '../../shared/chipAutoComplete/chipAutoComplete.module';
import {MatIconModule} from '@angular/material/icon';
import {ActivityRoutingModule} from './activity-routing.module';
import { AlertModule, ModalModule } from '../../theme/shared/components';
import { ScreenLoadingModule } from '../../shared/screenLoading/screenLoading.module';
import { HeaderPageModule } from '../../shared/headerPage/headerPage.module';
import { SidebarMenuModule } from '../../shared/sidebarMenu/sidebarMenu.module';
@NgModule({
    imports: [
        CommonModule,
        ChipAutoCompleteModule,
        HeaderPageModule,
        ActivityRoutingModule,
        FormsModule,
        ToastyModule,
        SidebarMenuModule,
        SearchModule,ScreenLoadingModule,
        ActivitiesModule,
        ReactiveFormsModule,
        AsideBarModule,
        HeaderModule,
        ResourcesModule,
        ItemViewModule,
        NgbModule,
        NgbButtonsModule,
        MatIconModule,
        AlertModule,
        ModalModule
    ],
  declarations: [
    CourseActivityComponent,
  ]
})
export class ActivityModule { }

<div class="app-modal-header">
    <h5 class="modal-title">{{title}}
        <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
</div>
<div class="app-modal-body">
    <div class="col-sm-12 ">
        <aw-wizard #wizard class="arc-wizard" navBarLayout="large-empty-symbols">
            <aw-wizard-step stepId="1" stepTitle="Selecciona"
                            [navigationSymbol]="{ symbol: '&#xe904;', fontFamily: 'Feather' }">
                <div class="column">
                    <div class="d-flex flex-row justify-content-around align-items-center">
                        <button type="button" class="btn btn-sm btn-glow-info btn-info"
                                (click)="downloadAssetExample()">
                            <i class="mr-0 far fa-question-circle" [placement]="'top'" ngbTooltip="Eliminar"></i>
                            Descargar Archivo de ejemplo
                        </button>
                    </div>
                    <div class="d-flex flex-row justify-content-around align-items-center">
                        <div class="d-flex flex-column align-items-center">
                            <i *ngIf="coursesFileSelected" class="far fa-check-circle fa-4x"
                               style="color: forestgreen;"></i>
                            <p *ngIf="coursesFileSelected" class="mt-2" style="color: forestgreen; font-weight: 500;">
                                Archivo válido
                            </p>
                            <i *ngIf="!coursesFileSelected" class="far fa-times-circle fa-4x"
                               style="color: darkred;"></i>
                            <p *ngIf="!coursesFileSelected" class="mt-2" style="color: darkred; font-weight: 500;">
                                Sin archivo seleccionado
                            </p>
                        </div>
                        <div>
                            <form [formGroup]="form">
                                <div class="form-group">
                                    <label class="form-control-label" for="course_type">Tipo Curso</label>
                                    <select class="form-control" name="course_type" formControlName="course_type"
                                            required
                                            [ngClass]="{'is-invalid': form.get('course_type').touched && form.get('course_type').hasError('required')}">
                                       <!-- <option *ngFor="let institution of storageService.getInstitutionType()"
                                                [value]="institution.key">{{ institution.name }}</option> -->
                                        <option value="CLASSROOM">Curso</option>

                                    </select>
                                    <label
                                            *ngIf="form.get('course_type').touched && form.get('course_type').hasError('required')"
                                            id="validation-phone-type-error"
                                            class="error jquery-validation-error small form-text invalid-feedback">
                                        Seleccione un tipo de contacto.
                                    </label>
                                </div>
                            </form>
                            <input hidden type="file" #uploader (change)="validateCourseList($event)" [multiple]="true" accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                            <button type="button" class="btn btn-sm btn-glow-light btn-light" (click)="uploader.click()"
                                    style="max-height: 40px;" [disabled]="!form.get('course_type').value">
                                <i class="feather icon-paperclip"></i>
                                Seleccionar archivo(s)
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 centered-content mt-5">
                        <div class="btn-group mt-10">
                            <button type="button" class="btn btn-sm btn-glow-warning btn-warning"
                                    [disabled]="!coursesFileSelected"
                                    (click)="uploadCourseList()" awNextStep>
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>
            <aw-wizard-step stepTitle="Confirma" [navigationSymbol]="{ symbol: '&#xe8e1;', fontFamily: 'Feather' }">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row justify-content-around">
                        <app-card [customHeader]="true" [options]="false">
                            <div class="app-card-header">
                                <h5>Total de Contactos</h5>
                            </div>
                            <div style="overflow: overlay; max-height: 40%;">
                                    <span class="font-weight-bold">
                                        {{coursesTotal}}
                                    </span>
                            </div>
                        </app-card>
                    </div>
                    <div class="col-sm-12 centered-content">
                        <div class="btn-group mt-10">
                            <button type="button" class="btn btn-sm btn-glow-danger btn-danger mr-5" awPreviousStep>
                                Cancelar
                            </button>
                            <button type="button" class="btn btn-sm btn-glow-warning btn-warning ml-5" awNextStep
                                    (click)="continueChecingkPendingUploads()">
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>
        </aw-wizard>
    </div>
</div>

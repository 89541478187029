import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ToastyService} from 'ng2-toasty';
import {TagStructure} from '../../../../api/models/chipAutoComplete';
import {StorageService} from '../../../../api/service/storage.service';
import {CoursesUploadComponent} from '../../../content/courses-upload/courses-upload.component';
import {MatDialog} from '@angular/material/dialog';
import {SpaceNodeFlat} from '../../../../util/space';

@Component({
  selector: 'app-header-course',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() placeHolder: string;
  @Input() listSpace: SpaceNodeFlat[];
  @Input() space: string;
  @Input() isStaff: boolean;
  @Input() tagList: TagStructure[];
  @Output() filters: EventEmitter<{icon: string, active: number, option: string}[]> = new EventEmitter();
  @Output() spaceActive: EventEmitter<string> = new EventEmitter();
  @Output() spaceObject: EventEmitter<SpaceNodeFlat> = new EventEmitter();
  @Output() isNewSpace: EventEmitter<boolean> = new EventEmitter();
  @Output() isEditSpace: EventEmitter<any> = new EventEmitter();


  grantNewCourse =
    !!(this.storageService.getCurrentSession().grants
        .find(g => g.name === 'CONTENT')
        .grants.find(gg => gg.name === 'CREAR'));

  search: string;
  iconsNav = [
    {icon: 'icon-user', active: 0, option: 'hasStudents'},
    {icon: 'icon-award', active: 0, option: 'hasStaff'},
    {icon: 'icon-alert-triangle', active: 0, option: 'hasContentAlerts'},
    {icon: 'icon-bell', active: 0, option: 'hasActivity'},
  ];
  constructor( private toastyService: ToastyService, public storageService: StorageService,  private dialog: MatDialog) { }

  ngOnInit(): void { }

  callEditSpace(): void{
    this.spaceObject.emit(this.listSpace.find((s) => s.id === this.space ));
    this.isEditSpace.emit(true);
  }

  trackById(index: number, space: any): number {
    return space.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listSpace) {
      if (this.listSpace && this.listSpace.length > 0){
        if (!this.space){
            this.space = this.listSpace[0].id;
        }

      }
    }
  }

  openContentUploadModal(): void {
    this.dialog.open(CoursesUploadComponent, {
      data: this.space,
      height: '65%',
      width: '75%',
    });
  }

  takeSpace(spaceId: string): void{
    if (spaceId === 'new'){
      this.isNewSpace.emit(true);
      this.space = this.listSpace[0]?.id;
    } else {
      this.isNewSpace.emit(false);
      this.spaceObject.emit(this.listSpace.find((s) => s.id === spaceId ));
      this.space = spaceId;
      this.spaceActive.emit(spaceId);
    }
  }

  filter(i: number): void{
    switch (this.iconsNav[i].active) {
      case 0:
          this.iconsNav[i].active = 1;
          break;
      case 1:
        this.iconsNav[i].active = 2;
        break;
      case 2:
        this.iconsNav[i].active = 0;
        break;
    }
    this.filters.emit(this.iconsNav);
  }
}

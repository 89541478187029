import {ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren,} from '@angular/core';
import {StorageService} from 'src/api/service/storage.service';
import {GrantsService} from 'src/app/services/grants.service';
import {Service} from '../../../api/service/Service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {FilterActivity} from '../../../api/models/activity';
import {
  Activity,
  ActivityRequest,
  ActivityShortResponse,
  BaseRequestActivityScoreRequest,
  BaseRequestOfActivityRequest,
  CommentResponse,
  ContactResponse,
  Delivery,
  MediaAsset,
  ResourceResponse,
} from 'src/api/client/data-contracts';
import {NgbDateStruct, NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {HeaderComponent} from './components/header/header.component';
import {FILES_NOT_PREVEW} from 'src/api/utils/Constants';
import {MediaPreviewModalComponent} from 'src/app/theme/shared/components/media-preview-modal/media-preview-modal.component';
import {ScreenLoadingComponent} from '../../shared/screenLoading/screenLoading.component';
import {HeaderStructure} from 'src/api/models/headerPage';
import {MenuStructure, OptionsMenuStructure} from 'src/api/models/sidebar';
import {SidebarMenuComponent} from '../../shared/sidebarMenu/sidebarMenu.component';
import {FormQuestion, Quiz} from 'src/api/models/question';
import {QuestionFormComponent} from '../../shared/questionForm/questionForm.component';
import {v4 as uuidv4} from 'uuid';
import {NotesFormComponent} from '../../shared/notesForm/notesForm.component';

interface Deliverables {
  delivery: Delivery;
  contact: ContactResponse;
  has_delivery: boolean;
  progress: any;
}

const permittedFiles = [
  'video/mp4',
  'video/quicktime',
  'application/pdf'
];

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"],
})
export class DetailActivityComponent implements OnInit {
  @ViewChild("screenLoading") screenLoading: ScreenLoadingComponent;
  @ViewChild("sideBar") sideBar: SidebarMenuComponent;
  @ViewChildren("questions") questionF: QueryList<QuestionFormComponent>;
  @ViewChild("header") header: HeaderComponent;
  @ViewChildren('notes') notesF: QueryList<NotesFormComponent>;

  public moment: any = moment;

  isTask: boolean;
  isVideo: boolean;
  isDocument: boolean;
  isQuiz: boolean;
  isMeet: boolean;

  deliverySelected: Deliverables;

  endDate: NgbDateStruct;
  formInput: ActivityRequest;
  formInputQuestions: Quiz;
  commentsQuiz: string;
  showAttempts = false;
  lastPanelId: string = null;
  linkMeet: string;
  messageScreen = "El video se esta subiendo, no cerrar esta pantalla!";
  plataformMeet: string;
  showQuiz = false;
  questionsList: FormQuestion[] = [];
  activityEndDate: NgbDateStruct;
  activityDate;
  resourcesFiles: MediaAsset[] = [];
  activityTitle: string;
  activityTime: string;
  activityDescription: string;
  questionActiveF: boolean;
  activityScore = 0;
  defaultPanelId = "toggle1";
  activityComments: CommentResponse[] = [];
  activityId: string;
  message = "Cargando Entregables";
  deliverablesList: Array<Deliverables> = [];
  selectedDeliverable: Deliverables;
  activityName: string;
  sideBarStatus: boolean;
  optionActive: MenuStructure;
  documentAttached: any;
  filterActive: FilterActivity;
  files: any[] = [];
  activities = new Array<ActivityShortResponse>();
  resources = new Array<ResourceResponse>();
  students = [];
  statusOption = true;
  windowWidth: number;
  scores = [];
  breadcrumbs: HeaderStructure[] = [];
  resourceToDelete = "";
  existingUnitsIds: number[] = [];
  activitiesByName = [];
  attachment: MediaAsset;
  loading = false;
  comment: string;
  score: number;

  private localStorageService;

  iconsNav2: OptionsMenuStructure[] = [
    {
      title: "Detalle Curso",
      description:
          "navega y selecciona acciones entre la lista que se muestra en la parte de abajo, ahi se muestran las diferentes opciones que se pueden ejecutar dentro de una actividad.",
      menu: [
        {
          id: "assignment_turned_in",
          icon: "assignment_turned_in",
          name: "Entregas",
        },
        { id: "attach_file", icon: "attach_file", name: "Recursos" },
        { id: "messages", icon: "messages", name: "Mensajes" },
        { id: "edit", icon: "edit", name: "Editar" },
      ],
    },
  ];



  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    public grantsService: GrantsService,
    private toastyService: ToastyService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.localStorageService = localStorage;
    this.activityId = this.route.snapshot.params.id;
    this.activityName = this.route.snapshot.params.course;

    const session = JSON.parse(
      this.localStorageService.getItem("edukcare-course")
    );

    this.breadcrumbs = [
      { id: null, name: "Cursos", url: "menu/course" },
      {
        id: null,
        name: "Actividades",
        url: `menu/course/activity/${session.id}/${session.name}`,
      },
      { id: null, name: "Detalle", url: null },
    ];
    this.windowWidth = window.innerWidth;
  }

  ngAfterViewInit() {
    this.loadActivities();
    this.sideBarStatus = this.sideBar.close;
    if (this.windowWidth <= 1110) {
      this.toggleMenuOptions();
    }
  }

  prepareFilesList(files: any): void {
    for (const item of files.target.files) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  panelShadow($event: NgbPanelChangeEvent, shadow): void {
    const { nextState } = $event;
    const activePanelId = $event.panelId;
    const activePanelElem = document.getElementById(activePanelId);

    if (!shadow?.isExpanded(activePanelId)) {
      activePanelElem?.parentElement?.classList.add('open');
    }

    if (!this.lastPanelId) this.lastPanelId = this.defaultPanelId;

    if (this.lastPanelId) {
      const lastPanelElem = document.getElementById(this.lastPanelId);

      if (this.lastPanelId === activePanelId && nextState === false)
          activePanelElem?.parentElement?.classList.remove('open');
      else if (this.lastPanelId !== activePanelId && nextState === true) {
          lastPanelElem?.parentElement?.classList.remove('open');
      }
    }
    this.lastPanelId = $event.panelId;
  }

  clickDelivery(item: Deliverables): void {
    this.deliverySelected = item;
    switch (this.optionActive.name) {
      case "Entregas":
        this.score = item.delivery.score;
        this.comment = "";
        if (item?.delivery?.comments) {
          this.comment = item?.delivery?.comments
              .map((value) => value.comment)
              .join(" , ");
        }
        break;
      default:
    }
  }

  backList(): void {
    this.deliverySelected = null;
  }

  saveNotes(): void{
    const notesObject = {
      app_id: 'app',
      data: {
        comment: this.commentsQuiz,
        questionScores:[]
      }
    };
    this.screenLoading.on();

    this.notesF.forEach((n, i: number) => {
      notesObject.data.questionScores.push(n.resultQuestionAry);
    });

    const service = new Service(this.storageService);
    service.updateQuizNote(this.deliverySelected?.delivery?.activity?.id, this.deliverySelected?.delivery?.id, notesObject).then((list) => {

      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Calificación guardada exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.screenLoading.off();
      this.loadActivities();
      this.toastyService.success(toastOptions);
    })
        .catch(reason => {
          this.screenLoading.off();
          this.toastyService.clearAll();
          const toastOptions: ToastOptions = {
            title: "Error",
            msg: reason.message,
            timeout: 5000,
            theme: "default"
          };
          this.toastyService.error(toastOptions);
        });

  }

  openFilePreview(attachment: MediaAsset): void {
    if (FILES_NOT_PREVEW[attachment.type]) {
      this.attachment = attachment;
      // @ts-ignore
      this.modalConfirmationDownload.show();
    } else {
      this.dialog
        .open(MediaPreviewModalComponent, {
          data: attachment,
          height: "85%",
          width: "75%",
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          //TODO If action needed after closed
        });
    }
  }

  upload(): void {
    const service = new Service(this.storageService);
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Cargando",
      msg: "Cargando Recurso(s).",
      timeout: 5000,
      theme: "default",
    };
    this.toastyService.wait(toastOptions);
    service.attachFilesActivity(this.activityId, this.files).then(() => {
      this.files = [];
      this.loadActivities();
    });
  }

  removeAttachment(file): void {
    const service = new Service(this.storageService);

    service.removeResourceFromActivity(this.activityId, file.id).then(() => {
      this.removeFile(file);
    });
  }

  removeFile(attachment): void {
    const index = this.resourcesFiles.indexOf(attachment);

    if (index >= 0) {
      this.resourcesFiles.splice(attachment, 1);
    }
  }

  downloadAttachment(attachment: MediaAsset, close: boolean): void {
    if (close) {
      // @ts-ignore
      this.modalConfirmationDownload.hide();
    }
    const filename = attachment.file_name
      ? attachment.file_name
      : "resource_file_" + Math.random();
    const a = document.createElement("a");
    a.download = filename;
    a.href = attachment.href;
    a.textContent = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => document.body.removeChild(a), 100);
  }

  loadActivities(isUpdate: boolean = false): void {
    const service = new Service(this.storageService);
    this.screenLoading.on();
    service.getDeliveriesByActivity(this.activityId).then((list) => {
      this.screenLoading.off();
      list.forEach((deliverable) => {
        this.setStatus(deliverable.delivery);
      });
      if (list && list.length > 0) {
        this.deliverablesList = list;
        if (isUpdate) {
          // tslint:disable-next-line:max-line-length
          this.selectedDeliverable = this.deliverablesList.find(
            (deliverable) =>
              deliverable.delivery.id === this.selectedDeliverable?.delivery?.id
          );
        }
      }
      this.setActivityFields();

      list.map((val) => {
        this.showQuiz = !!val?.progress?.answers?.find(
          (question) => question.question.multipleChoice === false
        );
      });
    });
  }

  saveQualification(deliverable) {
    this.screenLoading.on();
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando nota",
      timeout: 5000,
      theme: "default",
    };
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    const data: BaseRequestActivityScoreRequest = {
      app_id: "",
      data: {
        comment: this.comment,
        score: this.score,
      },
    };
    service
      .updateQualification(deliverable.activity.id, deliverable.id, data)
      .then(() => {
        this.loadActivities(true);
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Exito",
          msg: "Calificación guardada exitosamente.",
          timeout: 3000,
          theme: "default",
        };
        this.toastyService.info(toastOptions);
        this.screenLoading.off();
      })
      .catch((reason) => {
        this.screenLoading.off();
        this.toastyService.clearAll();

        this.screenLoading.off();

        const toastOptions: ToastOptions = {
          title: "Error",
          msg: reason.response?.data?.errors
            .map((value) => value.description)
            .join(",\n"),
          timeout: 5000,
          theme: "default",
        };
        this.toastyService.error(toastOptions);
      });
  }

  setStatus(delivery: Delivery) {
    switch (delivery.status) {
      case "TODO":
        delivery.statusDesc = "No Entregada";
        delivery.statusColor = "#ff635f";
        break;
      case "DOING":
        delivery.statusDesc = "En Proceso";
        delivery.statusColor = "#1e3db2";
        break;
      case "COMPLETED":
        delivery.statusDesc = "Entregada";
        delivery.statusColor = "#4fc67a";
        break;
      case "REVIEWED":
        delivery.statusDesc = "Revisada";
        delivery.statusColor = "#ffa600";
        break;
      default:
    }
    this.score = delivery.score;
    if (delivery.comments != null && delivery.comments.length > 0) {
      this.comment = delivery.comments[0]["comment"];
    }
  }

  setActivityFields() {
    const service = new Service(this.storageService);
    this.screenLoading.on();
    const studentId = this.selectedDeliverable
      ? this.selectedDeliverable?.contact?.id
      : "";
    service
      .getActivity(this.activityId, studentId)
      .then((activity: Activity) => {
        const endDate = activity.end_at.split("-");
        this.linkMeet = activity?.body?.link?.href ?? activity?.link?.href;
        this.plataformMeet = activity?.body?.link?.vendor;
        this.activityEndDate = {
          year: parseInt(endDate[0]),
          month: parseInt(endDate[1]),
          day: parseInt(endDate[2]),
        };
        this.activityDate = [
          this.activityEndDate.day,
          this.activityEndDate.month,
          this.activityEndDate.year,
        ].join("-");
        this.resourcesFiles = activity.resources;
        this.activityTitle = activity.title;
        this.activityTime = activity.end_time
          ? activity.end_time.substring(0, 5)
          : null;
        this.activityDescription = activity.description;
        this.activityScore = activity.score;
        this.activityComments = activity.comments;
        this.formInput = activity.body;
        this.formInput.videType =
          this.formInput.type === "WATCH_EXTERNAL_VIDEO" ? "URL" : "LOAD_VIDEO";
        this.formInput.type =
          this.formInput.type === "WATCH_EXTERNAL_VIDEO"
            ? "WATCH_VIDEO"
            : this.formInput.type;
        this.formInput.meet_link = this.linkMeet;
        this.formInput.meet_vendor = this.plataformMeet;
        this.formInput.minScore = activity?.quiz?.minScore;
        this.formInput.retries = activity?.quiz?.retries;
        this.formInput.end_time = activity.body.end_time.substring(0, 5);
        this.endDate = {
          year: parseInt(endDate[0]),
          month: parseInt(endDate[1]),
          day: parseInt(endDate[2]),
        };
        this.changeType();
        this.formInputQuestions = activity.quiz
          ? activity.quiz
          : {
              score: this.formInput.score,
              minScore: null,
              retries: null,
              questions: [],
            };
        this.questionActiveF = activity?.required_quiz
          ? activity?.required_quiz
          : true;

        this.questionsList = [];
        activity.quiz?.questions?.forEach((q, i: number) => {
          this.questionsList.push({
            id: q.id,
            title: q.title,
            score: q.score,
            multipleChoice: q.multipleChoice,
            choices: q.choices,
          });
        });
        this.changeDetectorRef.detectChanges();

        this.screenLoading.off();

        if ((this.formInput.type === "WATCH_VIDEO" || this.formInput.type === 'READ_DOCUMENT') && (!this.iconsNav2[0].menu.find((item) => item.id === "cuestionario"))) {
          this.iconsNav2[0].menu.push({ id: "cuestionario", icon: "list_alt", name: "Cuestionario" });
        }
      });
  }

  setOptionNav(item): void {
    this.optionActive = item.menu.find((icon) => icon.active);
    this.deliverySelected = null;
  }

  newQuestion(): void {
    let add = false;
    if (this.questionsList.length > 0){
      if (this.questionF) {
        this.questionF.forEach((q, i: number) => {
          if (q.formInputQuestion.title && q.formInputQuestion.score){
            if (q.formInputQuestion.multipleChoice) {
              if (q.formInputQuestion.choices.length > 0){
                add = true;
              }else{
                this.toastyService.clearAll();
                add = false;
                let toastOptions: ToastOptions = {
                  title: "Pregunta sin respuestas.",
                  msg: 'Se debe ingresar almenos una respuesta valida)',
                  timeout: 5000,
                  theme: "default"
                };
                this.toastyService.error(toastOptions);
              }
            }else{
              add = true;
            }
          } else {
            this.toastyService.clearAll();
            add = false;
            let toastOptions: ToastOptions = {
              title: "Completar campos obligatorios.",
              msg: 'Se debe ingresar un valor en los campos (Pregunta,Nota)',
              timeout: 5000,
              theme: "default"
            };
            this.toastyService.error(toastOptions);
          }
        });
        if (add){
          this.setQuestion();
        }
      }
      /**/
    }else{
      this.setQuestion();
    }
  }

  setQuestion(): void{
    this.questionsList.push({
      id: '',
      title: '',
      score: 0,
      multipleChoice: false,
      choices: []
    });
    this.formInputQuestions.questions = this.questionsList;
    this.changeDetectorRef.detectChanges();
  }

  toggleMenuOptions(): void {
    this.sideBar.closeSide();
    this.statusOption = !this.sideBar.close;
    this.sideBarStatus = this.sideBar.close;
  }



  closeSideBar(): void {
    this.statusOption = !this.statusOption;
    this.sideBarStatus = this.sideBar.close;
  }

  searchByName(name: string): void {
    if (this.optionActive.name === "Actividades") {
      this.activities = this.activitiesByName.filter((act) =>
        act.title.toLowerCase().includes(name.toLowerCase())
      );
    } else if (this.optionActive.name === "Recursos") {
      this.resources = this.activitiesByName.filter((act) =>
        act.name.toLowerCase().includes(name.toLowerCase())
      );
    } else if (this.optionActive.name === "Calificaciones") {
      this.scores = this.activitiesByName.filter((act) =>
        act.title.toLowerCase().includes(name.toLowerCase())
      );
    } else if (this.optionActive.name === "Alumnos") {
      this.students = this.activitiesByName.filter((act) =>
        act.names.toLowerCase().includes(name.toLowerCase())
      );
    }
  }

  changeType(): void {
    switch (this.formInput.type) {
      case "MEET":
        this.isQuiz = false;
        this.isMeet = true;
        this.isTask = false;
        this.isDocument = false;
        this.isVideo = false;
        break;
      case "TASK":
        this.isQuiz = false;
        this.isMeet = false;
        this.isTask = true;
        this.isDocument = false;
        this.isVideo = false;
        break;
      case "WATCH_VIDEO":
        this.isQuiz = false;
        this.isMeet = false;
        this.isTask = false;
        this.isDocument = false;
        this.isVideo = true;
        break;
      case "READ_DOCUMENT":
        this.isQuiz = false;
        this.isMeet = false;
        this.isTask = false;
        this.isDocument = true;
        this.isVideo = false;
        break;
      case "QUIZ":
        this.isQuiz = true;
        this.isMeet = false;
        this.isTask = false;
        this.isDocument = false;
        this.isVideo = false;
        break;
      default:
        this.isMeet = false;
        this.isTask = false;
        this.isDocument = false;
        this.isVideo = false;
        break;
    }
  }

  clickContextMenu(opt: string): void {
    if (opt === "newResource") {
    } else if (opt === "updateScores") {
    } else if (opt === "listStudents") {
    }
  }

  fillQuestion(): void {
    this.showAttempts = false;
    this.questionF.forEach((q, i: number) => {
      const inputQuestion = q.getForm();
      this.questionsList[i].id = uuidv4();
      this.questionsList[i].title = inputQuestion.title;
      this.questionsList[i].score = inputQuestion.score;
      this.questionsList[i].multipleChoice = inputQuestion.multipleChoice;
      this.questionsList[i].choices = inputQuestion.choices;
      this.showAttempts = inputQuestion.multipleChoice && true;
    });

    this.formInputQuestions.questions = this.questionsList;
  }

  setMessage(isQuiz?: boolean): void {
    if (isQuiz) {
      this.messageScreen =
        "El Cuestionario se esta creando, no cerrar esta pantalla!";
    } else {
      switch (this.formInput.type) {
        case "MEET":
          this.messageScreen =
            "La Reunion se esta actualizando, no cerrar esta pantalla!";
          break;
        case "TASK":
          this.messageScreen =
            "La Tarea se esta actualizando, no cerrar esta pantalla!";
          break;
        case "WATCH_VIDEO":
          this.messageScreen =
            "El Videoo se esta subiendo, no cerrar esta pantalla!";
          break;
        case "READ_DOCUMENT":
          this.messageScreen =
            "El Documento se esta subiendo, no cerrar esta pantalla!";
          break;
        default:
          this.messageScreen = "Procesando, no cerrar esta pantalla!";
          break;
      }
    }
  }

  changeVideoType(): void {
    switch (this.formInput.videType) {
      case "URL":
        this.formInput.type = "WATCH_EXTERNAL_VIDEO";
        break;
      default:
        this.formInput.type = "WATCH_VIDEO";
        break;
    }
  }

  selectDocument($event): void {
    this.toastyService.clearAll();
    let toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando...",
      timeout: 5000,
      theme: "default"
    };

    const file = $event.target.files[0];

    this.documentAttached = $event;

    if (!permittedFiles.includes(file.type)) {
      this.toastyService.clearAll();
      this.documentAttached = '';
      const toastOptions: ToastOptions = {
        title: "Error",
        msg: 'Tipo de archivo no permitido.',
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    }
  }

  updateActivityTask(form: any, isQuiz?: boolean): void {
    this.messageScreen = "Cargando!";
    this.loading = true;

    if (this.questionsList.length > 0) {
      this.changeDetectorRef.detectChanges();
      this.fillQuestion();
    }

    if (!this.formInputQuestions.retries) {
      this.formInputQuestions.retries = 0;
    }

    if (form.valid) {
      this.setMessage(isQuiz);

      if (!this.showAttempts) {
        this.formInputQuestions.retries = 0;
      }

      this.screenLoading.on();

      const service = new Service(this.storageService);

      if (this.formInput.type === "WATCH_VIDEO") {
        this.changeVideoType();
      }

      this.formInput.end_at = [
        this.endDate.day,
        this.endDate.month,
        this.endDate.year,
      ].join("-");

      let data: BaseRequestOfActivityRequest;

      // @ts-ignore
      // tslint:disable-next-line:triple-equals
      if (this.questionsList.length > 0) {
        data = {
          app_id: "",
          data: {
            title: this.formInput.title,
            description: this.formInput.description,
            type: this.formInput.type,
            end_at: this.formInput.end_at,
            end_time: this.formInput.end_time.substr(0, 5),
            score: this.formInput.score,
            meet_vendor: this.formInput.meet_vendor,
            meet_link: this.formInput.meet_link ? this.formInput.meet_link : "",
            require_quiz: this.questionsList.length > 0,
            quiz: this.questionsList.length > 0 && this.formInputQuestions,
          },
        };
      } else {
        data = {
          app_id: "",
          data: {
            title: this.formInput.title,
            description: this.formInput.description,
            type: this.formInput.type,
            end_at: this.formInput.end_at,
            end_time: this.formInput.end_time.substr(0, 5),
            score: this.formInput.score,
            meet_vendor: this.formInput.meet_vendor,
            meet_link: this.formInput.meet_link ? this.formInput.meet_link : "",
            require_quiz: this.questionsList.length > 0,
          },
        };
      }

      service
        .updateActivity(this.activityId, data)
        .then(() => {
          // @ts-ignore
          if (
            this.formInput?.type === "WATCH_VIDEO" ||
            this.formInput?.type === "READ_DOCUMENT"
          ) {
            if (this.documentAttached) {
              this.uploadDocument(this.activityId);
            } else {
              this.screenLoading.off();
            }
          } else {
            this.screenLoading.off();
          }

          // @ts-ignore
          this.toastyService.clearAll();
          var toastOptions: ToastOptions = {
            title: "Exito",
            msg: "¡Actividad actualizada exitosamente!",
            timeout: 3000,
            theme: "default",
          };
          this.toastyService.success(toastOptions);
          this.loadActivities();
          this.loading = false;
        })
        .catch((error) => {
          // @ts-ignore
          this.screenLoading.off();
          this.toastyService.clearAll();
          var toastOptions: ToastOptions = {
            title: "Error",
            msg: error,
            timeout: 3000,
            theme: "default",
          };
          this.toastyService.error(toastOptions);
          this.loading = false;
        });
    } else {
      this.loading = false;
      this.toastyService.clearAll();
      this.toastyService.error({
        title: "Error",
        msg: "Completar todos los campos obligatorios!",
        timeout: 5000,
        theme: "default",
      });
    }
  }

  uploadDocument(idActivity): void {
    const service = new Service(this.storageService);
    const file = this.documentAttached.target.files[0];

    service
      .addDocumentActivity(
        file,
        this.formInput.title,
        this.formInput.description,
        idActivity
      )
      .then(async () => {
        this.screenLoading.off();
        this.toastyService.clearAll();
        this.documentAttached = "";
        this.isMeet = false;
        this.isTask = false;
        this.isDocument = false;
        this.isVideo = false;
        // tslint:disable-next-line:no-shadowed-variable
        const toastOptions: ToastOptions = {
          title: "Exito",
          msg: "Video subido exitosamente!",
          timeout: 3000,
          theme: "default",
        };
        this.toastyService.info(toastOptions);
      })
      .catch((reason) => {
        this.screenLoading.off();
        this.toastyService.clearAll();
        const toastOptions: ToastOptions = {
          title: "Error",
          msg: reason?.message,
          timeout: 5000,
          theme: "default",
        };
        this.toastyService.error(toastOptions);
      });
  }
}

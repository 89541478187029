import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { OneSignal  } from 'onesignal-ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Edukare';

  constructor(private router: Router, private oneSignal: OneSignal ) {
    this.oneSignal.init({
      appId: "ffa117f6-03d8-4bd7-bd5b-db72580cdeca",
      safari_web_id: "web.onesignal.auto.0818a4e7-118f-4fc1-b0e2-07892e811a2a",
      notifyButton: {

        enable: true,
      },
      subdomainName: "edukcare",
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

}

<app-header-eduk [breadcrumbs]="breadcrumbs"></app-header-eduk>
<div class="container" style="margin: 0; padding: 0; max-width: 100%;">
  <div class="row">
    <article
      [ngClass]="{'col-lg-2': statusOption, 'menuMobileActive': statusOption && (this.windowWidth <= 1110)   }"
      class="WrapperNav"
      style="margin: 0; padding: 0; ">
        <div
                [ngClass]="{'menuMobileOpen': statusOption && (this.windowWidth <= 1110), 'menuMobileClose' : !statusOption && (this.windowWidth <= 1110) }"
                class="menuMobile">

            <app-sidebar-eduk #sideBar
                              (clickOption)="setOptionNav($event)"
                              (closeSideBar)="closeSideBar()"
                              [showClose]="(this.windowWidth <= 1110)"
                              [title]="'Opciones'"
                              [optionsMenu]="iconsNav2"></app-sidebar-eduk>
        </div>
    </article>
    <article class="wrapper-couser col" style="margin: 0; padding: 0;">
        <section class="header">
            <mat-icon
              (click)="toggleMenuOptions()"
              style="color: #0ea7f5; font-size: 35px; margin: 7px 10px 0px 5px; cursor: pointer;">menu</mat-icon>
            <span class="title">
                {{this.activityName}} </span>
        </section>
        <section>
          <div>
            <div class="row" style="margin: 0;">
              <article *ngIf="(optionActive?.name !== 'Editar' && optionActive?.name !== 'Cuestionario') && (windowWidth >= 1110) || (optionActive?.name !== 'Editar' && optionActive?.name !== 'Cuestionario') && (windowWidth <= 1110  && !deliverySelected)" class="col-xl-3 col-lg-4 col-md-4 col-sm-12 subNave">
                    <header>
                        <h5>{{ optionActive?.name }}</h5>
                        <div class="pageWrapper">
                            <span>1 - 100</span>
                            <div>
                                <mat-icon
                                        (click)="toggleMenuOptions()"
                                        style="color: #fff;font-size: 25px;cursor: pointer;position: absolute;right: 0;display: inline-block; top:-2px; right: 20px; ">keyboard_arrow_left</mat-icon>
                                <mat-icon
                                        (click)="toggleMenuOptions()"
                                        style="color: #fff;font-size: 25px;cursor: pointer;position: absolute;right: 0;display: inline-block; top:-2px; right: 0px;">keyboard_arrow_right</mat-icon>
                            </div>
                        </div>
                    </header>
                    <nav>
                        <ul>
                            <li
                                    (click)="fileInput.click()"
                                    *ngIf="optionActive?.name === 'Recursos'"
                                    style="
                                    height: auto;
                                    min-height: 55px;
                                    background-color: #185da1;
                                    color: #fff;
                                    font-weight: 100;
                                    font-size: 17px;
                                ">
                                <input #fileInput multiple="multiple" type="file" (change)="prepareFilesList($event)" style="display:none;" />
                                <mat-icon
                                        style="color: #ffffff;font-size: 30px;margin: 1px 0px 0px 10px;cursor: pointer;position: absolute;">attachment</mat-icon>
                                <span style=" margin: 4px 0 0 49px; position: absolute;"> Subir Recurso </span>
                            </li>
                            <li (click)="clickDelivery(item)" *ngFor="let item of
                    optionActive?.name === 'Entregas' ? deliverablesList :
                    optionActive?.name === 'Mensajes' ? activityComments :
                    optionActive?.name === 'Recursos' ? resourcesFiles :
                    optionActive?.name === 'Editar' && [];

                    let i = index">
                                <span *ngIf="optionActive?.name === 'Entregas'" class="dateSP">{{ moment(item.createdAt).utcOffset("-06:00").format("DD/MM/YYYY").toString() }}</span>
                                <span *ngIf="optionActive?.name === 'Mensajes'" class="dateSP">{{ moment(item.created_at, "DD-MM-YYYY HH:mm:ss").utcOffset("-06:00").format("DD/MM/YYYY").toString() }}</span>
                                <span *ngIf="optionActive?.name === 'Recursos'" class="dateSP">{{ moment(item.expires_at).utcOffset("-06:00").format("DD/MM/YYYY").toString() }}</span>


                                <img *ngIf="optionActive?.name === 'Entregas'" src={{item.contact?.avatar?.href}} alt="usuario" />
                                <img *ngIf="optionActive?.name === 'Mensajes'" src='https://edukcare-user-avatars.s3.amazonaws.com/avatar-2.jpeg' alt="usuario" />
                                <img *ngIf="optionActive?.name === 'Recursos'" src={{item?.publisher.icon.href}} alt="usuario" />

                                <span *ngIf="optionActive?.name === 'Entregas'" class="nameStudent">{{item.contact.names }} {{item.contact.lastNames}}
                                    <span class="status">
                            Estatus: <span> {{item.delivery.statusDesc}} </span>
                        </span>
                      </span>

                                <span *ngIf="optionActive?.name === 'Mensajes'" class="nameStudent">
                        {{item.publisher.name }}
                                    <span class="status">
                          Mensaje: <span> {{item.comment}} </span>
                        </span>
                      </span>

                                <span *ngIf="optionActive?.name === 'Recursos'" class="nameStudent" style="overflow-y: hidden">
                        {{item.file_name }}
                                    <span class="status">
                          Peso: <span> {{(item.size / 1048576).toPrecision(1)}} </span>
                        </span>
                      </span>
                                <span *ngIf="optionActive?.name === 'Entregas'" class="score">Punteo: <span> {{item.delivery.score}} </span> </span>

                                <section *ngIf="optionActive?.name === 'Recursos'" class="optionResource">
                            <span class="see" (click)="openFilePreview(item)">
                                Ver
                            </span>
                                    <span class="download" (click)="downloadAttachment(item,false)">
                                Descargar
                            </span>
                                    <span class="delete" (click)="removeAttachment(item)">
                                Eliminar
                            </span>
                                </section>
                            </li>
                        </ul>
                    </nav>
              </article>
              <article class="resources col detailWraper" *ngIf="files?.length > 0">
                  <nav>
                      <ul>
                          <li *ngFor="let d of files; let i = index">
                          <span class="attachment">
                            <mat-icon
                                    (click)="downloadAttachment(d, false)"
                                    style="color: #096fc6;font-size: 30px;margin: 9px 0px 0px -5px;cursor: pointer;">attachment</mat-icon>
                          </span>
                              <div class="name">{{d.name}}</div>
                          </li>
                      </ul>
                  </nav>
                  <button

                          style="float: left;margin: 40px 0 0 0;"
                          type="button"
                          (click)="upload()"
                          class="btn btn-primary active-button"
                          [disabled]="!grantsService.validateByNameAndType('NOTA', 'ACTION')">
                      Cargar Archivo(s)
                  </button>
              </article>
              <article *ngIf="this.deliverySelected" class="col detailWraper" style="margin: 0; padding: 0; background-color:#ebebeb ;">
                <section class="detail">
                    <div class="titleDetail"> Detalle</div>
                    <div *ngIf="windowWidth <= 1110" (click)="backList()">
                        <mat-icon
                                style="color: #ffffff;font-size: 30px;margin: 10px 0px 0px 12px;cursor: pointer;position: absolute;top: 0;">first_page</mat-icon>
                    </div>
                    <div *ngIf="optionActive?.name === 'Entregas'" class="date"> {{moment(deliverySelected.createdAt).utcOffset("-06:00").format("DD/MM/YYYY").toString()}} </div>
                    <div *ngIf="optionActive?.name === 'Mensajes'" class="date"> {{ moment(deliverySelected.created_at, "DD-MM-YYYY HH:mm:ss").utcOffset("-06:00").format("DD/MM/YYYY").toString() }} </div>
                    <div *ngIf="optionActive?.name === 'Recursos'" class="date"> {{ moment(deliverySelected.expires_at).utcOffset("-06:00").format("DD/MM/YYYY").toString() }} </div>

                    <div *ngIf="optionActive?.name === 'Entregas'" class="status"> {{deliverySelected.delivery.statusDesc}} </div>
                    <div *ngIf="optionActive?.name === 'Recursos'" class="status"> {{deliverySelected.file_name}} </div>
                </section>
                <section class="containOption">
                  <section>
                      <img *ngIf="optionActive?.name === 'Entregas'" src={{deliverySelected.contact.avatar.href}} alt="usuario" />
                      <img *ngIf="optionActive?.name === 'Mensajes'" src='https://edukcare-user-avatars.s3.amazonaws.com/avatar-2.jpeg' alt="usuario" />
                      <article class="headerContainerDetail">
                        <ul>
                          <li *ngIf="optionActive?.name === 'Entregas'">{{deliverySelected.contact.names }} {{deliverySelected.contact.lastNames}}</li>
                          <li *ngIf="optionActive?.name === 'Mensajes'">{{deliverySelected.publisher.name }}</li>
                          <li *ngIf="optionActive?.name === 'Entregas'">{{deliverySelected.contact.email }}</li>
                        </ul>
                      </article>
                  </section>
                  <section class="containDetail" *ngIf="optionActive?.name === 'Entregas'">
                    <p class="name">{{deliverySelected.delivery.activity.title }}</p>
                    <p class="description">{{deliverySelected.delivery.activity.description }}</p>
                  </section>

                  <section class="containDetail" *ngIf="optionActive?.name === 'Recursos'">
                        <p class="name">{{deliverySelected.file_name }}</p>
                  </section>

                  <section class="containDetail" *ngIf="optionActive?.name === 'Mensajes'">
                    <p class="name">{{deliverySelected.comment }}</p>
                  </section>

                  <div *ngIf="optionActive?.name === 'Entregas'">
                    <section *ngIf='!deliverySelected?.progress?.answers?.length > 0
                                        &&
                                     deliverySelected?.delivery.status !== "DOING"' class="containForm">
                    <p class="name" style="font-size: 17px; font-weight: 400; padding: 13px 0 0px 12px; margin-bottom: -1px;">Calificar actividad:</p>
                    <div class="row" style="padding: 12px;">
                        <div class="col-12">
                            <div class="score-input-label">Puntuación</div>
                            <div>
                                <input type="number" class="form-control" [(ngModel)]="score"
                                      [disabled]="!grantsService.validateByNameAndType('NOTA', 'ACTION')">
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding: 12px;">
                        <div class="col-12">
                            <div class="score-input-label">Comentario</div>
                            <div>
                                <textarea rows="5" class="form-control" [(ngModel)]="comment"
                                          [disabled]="!grantsService.validateByNameAndType('NOTA', 'ACTION')"></textarea>
                            </div>
                        </div>
                    </div>

                    <button
                      style="float: right;"
                      type="button"
                      (click)="saveQualification(deliverySelected.delivery)"
                      class="btn btn-primary active-button"
                      [disabled]="!grantsService.validateByNameAndType('NOTA', 'ACTION')">
                        Guardar
                    </button>
                  </section>
                  </div>

                    <div class="wrapper-quiz" style="padding: 15px;margin-bottom: 100px;display: inline-block;width: 100%;"
                         *ngIf="deliverySelected?.progress?.answers?.length > 0">

                        <h4>Calificar Cuestionario.</h4>
                        <ngb-accordion
                                [destroyOnHide]='false'
                                [closeOthers]="true"
                                (panelChange)='panelShadow($event, shadow)'
                                [activeIds]="'toggle' + (questionsList.length - 1)">
                            <ng-container
                                    *ngFor="let answer of deliverySelected?.progress?.answers; let i = index">
                                <ngb-panel
                                        cardClass="open"
                                        [title]="answer.question.title"
                                        [id]="'toggle' + i">
                                    <ng-template ngbPanelContent>
                                        <app-notes-form
                                            #notes
                                            [id]="answer?.questionId"
                                            [defaultValue]="answer?.right"
                                            [responseQuiz]="answer?.singleResponse"
                                            [answer]="answer"
                                        ></app-notes-form>
                                    </ng-template>
                                </ngb-panel>
                            </ng-container>

                        </ngb-accordion>

                        <article *ngIf="deliverySelected?.delivery?.comments?.length > 0"
                                 class="commentsWrapper">
                            <h4>Comentarios: </h4>
                            <ul>
                                <li *ngFor="let dd of  deliverySelected?.delivery?.comments">
                                    {{ dd?.comment  }}
                                </li>
                            </ul>
                        </article>


                        <div *ngIf="this.showQuiz
                        && deliverySelected?.delivery?.status !== 'REVIEWED'
                        && deliverySelected?.delivery?.status !== 'DOING'
                      ">
                            <label class="form-control-label"
                                   for="comentarios">Comentarios:</label>
                            <textarea type="text" [(ngModel)]="commentsQuiz"
                                      class="form-control"
                                      (readonly)="deliverySelected.delivery.status !== 'REVIEWED'"
                                      required rows="3">
                      </textarea>
                        </div>

                        <button
                                *ngIf="deliverySelected?.delivery?.status !== 'REVIEWED' && this.showQuiz && deliverySelected?.delivery?.status !== 'DOING'"
                                type="button"
                                class="btn btn-primary active-button float-right mt-5"
                                (click)="saveNotes()">
                            Guardar
                        </button>
                    </div>

                  <section *ngIf="optionActive?.name === 'Entregas'" class="footer">
                    <p style="margin: 7px 0 0px 15px;">
                      Adjuntos:
                    </p>
                    <nav>
                      <ul>
                        <li *ngFor="let d of deliverySelected.delivery.attchaments; let i = index" style="width: 180px">
                            <article class="download-attachment">
                                <ul>
                                    <li (click)="downloadAttachment(d, false)" style="margin: 0; margin-bottom: 5px;">
                                        <mat-icon
                                                style="color: #096fc6;font-size: 20px; float:left; margin: 0;cursor: pointer;">save_alt</mat-icon>
                                        <div>Descargar</div>
                                    </li>
                                    <li style="margin: 0" (click)="openFilePreview(d)">
                                        <mat-icon
                                                style="color: #096fc6;font-size: 20px;float:left; margin: 0;cursor: pointer;">remove_red_eye</mat-icon>
                                        <div> Ver </div>
                                    </li>
                                </ul>
                                <div class="name">{{d.file_name}}</div>
                            </article>
                        </li>
                      </ul>
                    </nav>
                  </section>
                </section>
              </article>

              <article *ngIf="optionActive?.name === 'Editar'">
                  <div class="header edit" style="padding: 30px 40px 20px 40px;">
                      <form #validationForm="ngForm" class="editForm" novalidate>
                          <div class="row">
                              <div class="col-md-12">
                                  <div class="form-group">
                                      <label class="form-control-label" for="type">Tipo Actividad</label>
                                      <select class="form-control" id="type" name="type"
                                              (change)="changeType()" required
                                              #contactType="ngModel"
                                              [ngClass]="{'is-invalid': !contactType.valid && (contactType.dirty || contactType.touched || isSubmit)}"
                                              [(ngModel)]="formInput.type">
                                          <option value="">Ingrese un Tipo Actividad</option>
                                          <option value="TASK">Tarea</option>
                                          <option value="MEET">Reunion</option>
                                          <option value="WATCH_VIDEO">Ver Video</option>
                                          <option value="READ_DOCUMENT">Leer Documento</option>
                                          <option value="QUIZ">Cuestionario</option>
                                      </select>
                                      <label *ngIf="!contactType.valid && (contactType.dirty || contactType.touched || isSubmit)"
                                             id="validation-contactType-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">Debe
                                          seleccionar el tipo de espacio.</label>
                                  </div>
                              </div>
                              <div class="col-md-12">
                                  <div class="form-group">
                                      <label class="form-label">Titulo</label>
                                      <input type="text" class="form-control" id="names" name="names"
                                             placeholder="Ingresar Título"
                                             #names="ngModel"
                                             [(ngModel)]="formInput.title"
                                             [ngClass]="{'is-invalid': !names.valid && (names.dirty || names.touched || isSubmit)}"
                                             required>
                                      <label *ngIf="!names.valid && (names.dirty || names.touched || isSubmit)"
                                             id="validation-names-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">Ingrese
                                          un
                                          t&iacute;tulo valido.</label>
                                  </div>
                              </div>
                              <div class="col-md-12">
                                  <div class="form-group">
                                      <label class="form-label">Descripcion</label>
                                      <textarea
                                              class="form-control"
                                              name="lastNames"
                                              id="lastNames"
                                              #lastNames="ngModel"
                                              [(ngModel)]="formInput.description"
                                              [ngClass]="{'is-invalid': !lastNames.valid && (lastNames.dirty || lastNames.touched || isSubmit)}"
                                              placeholder="Ingresar Descripción"
                                              required
                                              rows="3">
                            </textarea>
                                      <label *ngIf="!lastNames.valid && (lastNames.dirty || lastNames.touched || isSubmit)"
                                             id="validation-apellidos-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">Ingrese
                                          una descripci&oacute;n valida.</label>
                                  </div>
                              </div>
                              <div class="col-md-6" [hidden]="!isMeet">
                                  <div class="form-group">
                                      <label class="form-label">Direccion de reunion</label>
                                      <input type="text" class="form-control" name="urlMeet" id="urlMeet"
                                             #urlMeet="ngModel"
                                             [(ngModel)]="formInput.meet_link"
                                             [ngClass]="{'is-invalid': !urlMeet.valid && (urlMeet.dirty || urlMeet.touched || isSubmit)}"
                                             placeholder="Ingresar apellidos" [required]="isMeet">
                                      <label *ngIf="!urlMeet.valid && (urlMeet.dirty || urlMeet.touched || isSubmit)"
                                             id="validation-urlmeet-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">Ingrese
                                          una descripci&oacute;n valida.</label>
                                  </div>
                              </div>
                              <div class="col-md-6" [hidden]="!isMeet">
                                  <div class="form-group">
                                      <label class="form-control-label" for="type">Plataforma de
                                          reunion</label>
                                      <select class="form-control" id="platform" name="platform"
                                              [required]="isMeet"
                                              #platform="ngModel"
                                              [ngClass]="{'is-invalid': !platform.valid && (platform.dirty || platform.touched || isSubmit)}"
                                              [(ngModel)]="formInput.meet_vendor">
                                          <option value="ZOOM">ZOOM</option>
                                          <option value="GOOGLE">Google Meet</option>
                                          <option value="TEAMS">Teams</option>
                                      </select>
                                      <label *ngIf="!platform.valid && (platform.dirty || platform.touched || isSubmit)"
                                             id="validation-platform-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">Debe
                                          seleccionar el tipo de plataforma.</label>
                                  </div>
                              </div>

                              <div class="col-md-6">
                                  <div class="form-group">
                                      <label class="form-label">Fecha de Entrega</label>
                                      <div class="input-group">
                                          <input class="form-control" placeholder="dd / mm / aaaa"
                                                 [minDate]="startDate ? startDate : minStartDate"
                                                 #end_at="ngModel"
                                                 name="dateEnd" id="dateEnd" [(ngModel)]="endDate"
                                                 ngbDatepicker #d2="ngbDatepicker"
                                                 [ngClass]="{'is-invalid': !dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)}"
                                                 required>
                                          <div class="input-group-append">
                                              <button class="btn btn-outline-secondary"
                                                      (click)="d2.toggle()" type="button">
                                                  <i class="fas fa-calendar f-14"></i>
                                              </button>
                                          </div>
                                      </div>
                                      <label *ngIf="!dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)"
                                             id="validation-dateEnd-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">
                                          Ingrese una fecha de entrega valida.
                                      </label>
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div class="form-group">
                                      <div class="ngx-material-timepicker-example__form-group">
                                          <label class="form-label">Hora de Entrega</label>
                                          <input
                                                  [(ngModel)]="formInput.end_time"
                                                  #dateEnd="ngModel"
                                                  id="endTime"
                                                  name="endTime"
                                                  placeholder="Default time 11:11 pm"
                                                  [required]="isMeet && isTask"
                                                  [format]="24"
                                                  aria-label="default time"
                                                  [ngxTimepicker]="defaultTime"
                                                  [value]="activityTime"
                                                  class="timePicker"
                                          >
                                          <ngx-material-timepicker #defaultTime></ngx-material-timepicker>
                                      </div>

                                      <label *ngIf="!dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)"
                                             id="validation-timeEnd-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">Ingrese
                                          una hora de entrega valida.</label>
                                  </div>
                              </div>
                              <div class="col-md-6" *ngIf="!isMeet">
                                  <div class="form-group">
                                      <label class="form-label">Peso de nota</label>
                                      <input type="number" class="form-control" name="score" id="score"
                                             #score="ngModel"
                                             [(ngModel)]="formInput.score" [min]="0" [max]="100"
                                             [ngClass]="{'is-invalid': !score.valid && (score.dirty || score.touched || isSubmit)}"
                                             placeholder="Ingresar Descripción" required>
                                      <label *ngIf="!score.valid && (score.dirty || score.touched || isSubmit)"
                                             id="validation-apellidos-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">La
                                          nota debe ser entre 0-100</label>
                                  </div>
                              </div>

                              <div class="col-md-6" *ngIf="isQuiz">
                                  <div class="form-group">
                                      <label class="form-label">Nota Minima</label>
                                      <input type="number" class="form-control" name="minScore"
                                             id="minScore" #minScore="ngModel"
                                             [(ngModel)]="formInput.minScore" [min]="0" [max]="100"
                                             [ngClass]="{'is-invalid': !minScore.valid && (minScore.dirty || minScore.touched || isSubmit)}"
                                             placeholder="Ingresar Descripción" required>
                                      <label *ngIf="!minScore.valid && (minScore.dirty || minScore.touched || isSubmit)"
                                             id="validation-apellidos-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">La
                                          nota debe ser entre 0-100</label>
                                  </div>
                              </div>

                              <div class="col-md-6" *ngIf="isQuiz">
                                  <div class="form-group">
                                      <label class="form-label">Oportunidades para responder</label>
                                      <input type="number" class="form-control" name="retries"
                                             id="retries" #retries="ngModel"
                                             [(ngModel)]="formInput.retries" [min]="0" [max]="100"
                                             [ngClass]="{'is-invalid': !retries.valid && (retries.dirty || retries.touched || isSubmit)}"
                                             placeholder="Ingresar Descripción" required>
                                      <label *ngIf="!retries.valid && (retries.dirty || retries.touched || isSubmit)"
                                             id="validation-apellidos-error"
                                             class="error jquery-validation-error small form-text invalid-feedback">La
                                          nota debe ser entre 0-100</label>
                                  </div>
                              </div>

                              <article class="col-md-12 wpQ" *ngIf="isQuiz">
                                  <div class="wrapper-quiz"> Cuestionario:</div>
                                  <section class="row">
                                      <div class="col-md-6 pt-4 pl-4">
                                          Total de preguntas agregadas:
                                          <b> {{this.questionsList.length}} </b>
                                      </div>
                                      <div class="col-md-6">
                                          <button
                                                  type="button"
                                                  class="btn btn-primary active-button float-right mt-3"
                                                  (click)="newQuestion()">
                                              Agregar Pregunta
                                          </button>
                                      </div>
                                  </section>
                                  <section class="mt-3">
                                      <ngb-accordion
                                              [destroyOnHide]='false'
                                              [closeOthers]="true"
                                              (panelChange)='panelShadow($event, shadow)'
                                              [activeIds]="'toggle' + (questionsList.length - 1)">
                                          <ngb-panel class="tabQuestion" cardClass="open"
                                                     *ngFor="let q of questionsList; let i = index"
                                                     [title]="'Pregunta' + ' ' + (i + 1) "
                                                     [id]="'toggle' + i">
                                              <ng-template ngbPanelContent>
                                                                <span class="deleteQuestion"
                                                                      (click)="removeQuestion(i)">Eliminar pregunta!</span>
                                                  <app-question-form
                                                      #questions
                                                      (changeData)="this.fillQuestion()"
                                                      [defaultData]="q"></app-question-form>
                                              </ng-template>
                                          </ngb-panel>
                                      </ngb-accordion>
                                  </section>
                              </article>



                              <div class="col-md-12" *ngIf="isVideo || isDocument">
                                  <article>
                                      <div *ngIf="isVideo" class="form-group">
                                          <label class="form-label">Tipo video*</label>
                                          <select class="form-control" id="typeVideo" name="typeVideo" required
                                                  #typeVideo="ngModel"   [ngClass]="{'is-invalid': !typeVideo.valid && (typeVideo.dirty || typeVideo.touched || isSubmit)}" [(ngModel)]="formInput.videType" >
                                              <option value="LOAD_VIDEO">Cargar un video</option>
                                              <option value="URL">URL</option>
                                          </select>
                                          <label *ngIf="!typeVideo.valid && (typeVideo.dirty || typeVideo.touched || isSubmit)"
                                                 id="validation-contactType-error"
                                                 class="error jquery-validation-error small form-text invalid-feedback">Debe seleccionar un tipo de video.</label>
                                      </div>
                                  </article>
                                  <article>
                                      <section class="wrapper-upload">
                                          <div *ngIf="documentAttached && formInput.videType != 'URL'" class="documentAdd">
                                              <i *ngIf="isVideo" class="feather icon-film"
                                                 style="font-size: 40px"></i>
                                              <i *ngIf="isDocument" class="feather icon-file-text"
                                                 style="font-size: 40px"></i>
                                              <br/>
                                              {{ documentAttached.target.files[0].name }}
                                          </div>
                                          <ng-template [ngIf]="formInput.videType === 'URL'" [ngIfElse]="LOAD_VIDEO" class="form-group">
                                              <div class="form-group">
                                                  <label class="form-label">URL del video*</label>
                                                  <input type="text" class="form-control" name="urlVideo" id="urlVideo" #urlVideo="ngModel"
                                                         [(ngModel)]="formInput.meet_link" (change)="validUrl()"
                                                         [ngClass]="{'is-invalid': !urlVideo.valid && (urlVideo.dirty || urlVideo.touched )}"
                                                         placeholder="Ingresar apellidos" [required]="isMeet">
                                                  <label *ngIf="!urlVideo.valid && (urlVideo.dirty || urlVideo.touched )"
                                                         id="validation-urlmeet-error"
                                                         class="error jquery-validation-error small form-text invalid-feedback">Ingrese una URL valida.</label>
                                              </div>
                                          </ng-template>
                                          <ng-template #LOAD_VIDEO>
                                              <input hidden type="file"
                                                     accept="{{isVideo ? 'video/mp4,video/x-m4v,video/*' : 'application/pdf' }}  "
                                                     #uploader (change)="selectDocument($event)"
                                                     [multiple]="false"/>
                                              <button type="button"
                                                      class="btn btn-sm btn-glow-light btn-light btnAdd btnUpload"
                                                      (click)="uploader.click()">
                                                  <i *ngIf="isVideo" class="feather icon-film"></i>
                                                  <i *ngIf="isDocument" class="feather icon-file-text"></i>

                                                  {{isVideo ? 'Buscar video' : isDocument && 'Buscar documento'}}
                                              </button>
                                          </ng-template>
                                      </section>
                                  </article>
                              </div>
                          </div>
                      </form>
                      <div class="col-12 save-button load-btn-container">
                          <button type="button" class="btn btn-primary active-button"
                                  (click)="updateActivityTask(validationForm)">Guardar
                          </button>
                          <div *ngIf="loading"
                               class="card-body d-flex flex-row justify-content-center mt-5">
                              <div class="spinner-border text-info" role="status">
                                  <span class="sr-only"></span>
                              </div>
                          </div>
                      </div>
                  </div>
              </article>
              <article *ngIf="optionActive?.name === 'Cuestionario'" style="width: 100%">
                  <div>
                      <form #validationFormQuestion="ngForm" class="" novalidate>
                          <div class="row main" style="margin: 0">
                              <div class="col-md-12">
                                  <div class="row" style="margin-top: 15px; margin-bottom: 15px;">
                                      <div class="col-md-3">
                                          <label class="form-label">Nota*</label>
                                          <input
                                                  type="number"
                                                  class="form-control"
                                                  name="score"
                                                  readonly
                                                  id="score"
                                                  #score="ngModel"
                                                  [(ngModel)]="formInputQuestions.score"
                                                  [min]="0"
                                                  [max]="100"
                                                  [disabled]="!questionActiveF"
                                                  [ngClass]="{'is-invalid': !score.valid && (score.dirty || score.touched || isSubmit)}"
                                                  placeholder="nota"
                                                  required>
                                          <label *ngIf="!score.valid && (score.dirty || score.touched || isSubmit)"
                                                 id="validation-apellidos-error"
                                                 class="error jquery-validation-error small form-text invalid-feedback">La
                                              nota debe ser entre 0-100</label>
                                      </div>
                                      <div class="col-md-3">
                                          <label class="form-label">Nota minima*</label>
                                          <input type="number" class="form-control" name="minScore" id="minScore"
                                                 #minScore="ngModel"
                                                 [disabled]="!questionActiveF"
                                                 [(ngModel)]="formInputQuestions.minScore" [min]="0" [max]="100"
                                                 [ngClass]="{'is-invalid': !minScore.valid && (minScore.dirty || minScore.touched || isSubmit)}"
                                                 placeholder="nota minima" required>
                                          <label *ngIf="!minScore.valid && (minScore.dirty || minScore.touched || isSubmit)"
                                                 id="validation-apellidos-error"
                                                 class="error jquery-validation-error small form-text invalid-feedback">La
                                              nota minima debe ser entre 0-100</label>
                                      </div>
                                      <div class="col-md-3" *ngIf="showAttempts">
                                          <label class="form-label">Oportunidades para responder*</label>
                                          <input type="number" class="form-control" name="retries" id="retries"
                                                 #retries="ngModel"
                                                 [disabled]="!questionActiveF"
                                                 [(ngModel)]="formInputQuestions.retries" [min]="0" [max]="100"
                                                 [ngClass]="{'is-invalid': !retries.valid && (retries.dirty || retries.touched || isSubmit)}"
                                                 placeholder="intentos">
                                          <label *ngIf="!retries.valid && (retries.dirty || retries.touched || isSubmit)"
                                                 id="validation-apellidos-error"
                                                 class="error jquery-validation-error small form-text invalid-feedback">el
                                              intento debe estar entre 0-100</label>
                                      </div>
                                      <div class="col-md-3">
                                          <button
                                                  type="button"
                                                  class="btn btn-primary active-button float-right mt-5"
                                                  (click)="newQuestion()"
                                                  style="margin-top: 26px !important;"
                                                  [disabled]="!questionActiveF">
                                              Agregar Pregunta
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-12 mt-3">
                                  <ngb-accordion
                                          [destroyOnHide]='false'
                                          [closeOthers]="true"
                                          (panelChange)='panelShadow($event, shadow)'
                                          [activeIds]="'toggle' + (questionsList.length - 1)">
                                      <ngb-panel
                                              cardClass="open" *ngFor="let q of questionsList; let i = index"
                                                 [title]="'Pregunta' + ' ' + (i + 1) " [id]="'toggle' + i">

                                          <ng-template ngbPanelContent>
                                            <span class="deleteQuestion"
                                                  (click)="removeQuestion(i)">Eliminar pregunta!</span>
                                              <app-question-form
                                                  #questions
                                                  (changeData)="this.fillQuestion()"
                                                  [defaultData]="q"></app-question-form>
                                          </ng-template>
                                      </ngb-panel>
                                  </ngb-accordion>

                                  <button
                                          type="button"
                                          style="background-color: #0bb92d"
                                          class="btn btn-primary active-button float-right mt-5"
                                          (click)="updateActivityTask(validationFormQuestion, true)"
                                          [disabled]="
                        !formInputQuestions.score ||
                        !formInputQuestions.minScore ||
                        !(questionsList.length > 0)
                ">Guardar Cuestionario!
                                  </button>

                              </div>
                          </div>
                      </form>
                  </div>
              </article>
            </div>
          </div>
        </section>
    </article>
  </div>

</div>

<app-screen-loading #screenLoading [message]="message"></app-screen-loading>

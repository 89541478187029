import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges} from '@angular/core';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {TagStructure} from '../../../../../api/models/chipAutoComplete';
import { StorageService } from '../../../../../api/service/storage.service';
import {Service} from '../../../../../api/service/Service';
import { CourseStructure } from 'src/api/models/course';


@Component({
  selector: 'app-asideBar-course',
  templateUrl: './asideBar.component.html',
  styleUrls: ['./asideBar.component.scss']
})
export class AsideBarComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() courseTags: CourseStructure;
  @Input() tagList: TagStructure[];
  @Input() placeHolder: string;
  @Output() filterTags: EventEmitter<boolean> = new EventEmitter();

  listTagsUpdated: TagStructure[] = [];
  constructor( private toastyService: ToastyService, public storageService: StorageService,) { }
  ngOnInit(): void {
    const service = new Service(this.storageService);
    service.getTags()
        .then((value) => {
          this.listTagsUpdated = value;
        }).catch((error) => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: error,
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tagList) {
      this.isNewSpaceChange();
    }
  }

  private isNewSpaceChange(): void {
    console.log('DATA:: ', this.listTagsUpdated);
    this.listTagsUpdated = this.tagList;
  }

  tagEnter(tags): void{
    this.filterTags.emit(tags);
    console.log('TAG:: ', tags);
  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Balance, Deposit, PaymentRequest, Reference, Transfer} from "../../api/client/data-contracts";
// @ts-ignore
import banks from 'src/assets/json/banks.json';
// @ts-ignore
import countries from 'src/assets/json/countries.json';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastOptions, ToastyService} from "ng2-toasty";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import { DatesService } from 'src/api/service/dates.service';
import {Service} from "../../api/service/Service";
import {StorageService} from "../../api/service/storage.service";
import {DatePipe} from "@angular/common";

const permittedFiles = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
  'application/doc',
  'application/docx',
];
@Component({
  selector: 'app-pay-dialog',
  templateUrl: './pay-dialog.component.html',
  styleUrls: ['./pay-dialog.component.scss']
})
export class PayDialogComponent implements OnInit {


  depositFormRequest: PaymentRequest;
  datePay: any;
  transferenceForm: FormGroup;
  activeTab = '';
  loading = false;
  maxDate: NgbDateStruct;
  isSubmit: boolean;
  payFile: any;


  constructor(
      @Inject(MAT_DIALOG_DATA) public balance: Balance,
      private dialogRef: MatDialogRef<PayDialogComponent>,
      private toastyService: ToastyService,
      private fb: FormBuilder,
      private datesService: DatesService,
      private storageService: StorageService,
      public datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.buildFormDeposit();
    const today = new Date();
    this.depositFormRequest = {
      reference:  {
        deposit: {
          number: null,
          date: null,
          type: null,
          bank: null,
        } as Deposit,
        swift: {
          number: null,
          bank: null,
          countryCode: null,
          currencyCode: null,
          date: null,
          type: null,
        } as Deposit
      } as Reference
    } as PaymentRequest;
    this.maxDate = NgbDate.from(
       {
        year: today.getFullYear(),
        month: today.getMonth(),
        day: today.getDate()
      } as NgbDateStruct);
  }
  closeModal(updated: boolean = false) {
    this.dialogRef.close({ updated });
  }
  onTabChange($event) {
    this.activeTab = $event.nextId;
    if ( "tabTransference" ){
      this.depositFormRequest = {
        reference:  {
          swift: {
            number: null,
            bank: null,
            countryCode: null,
            currencyCode: null,
            date: null,
            type: null,
          } as Transfer
        } as Reference
      } as PaymentRequest;
    }else{
      this.depositFormRequest = {
        reference:  {
          deposit: {
            number: null,
            date: null,
            type: null,
            bank: null,
          } as Deposit,
        } as Reference
      } as PaymentRequest;
    }
  }
  getBanks(): any {
      return banks;
  }
  getCountries(): any {
      return countries;
  }

  buildFormDeposit() {

  }

  makePayment(form: any, method: string): void {
    this.loading = true;
    this.toastyService.clearAll();
    const toastOptions: ToastOptions = {
      title: 'Espere',
      msg: 'Actualizando informacion',
      timeout: 5000,
      theme: 'default'
    };
    this.toastyService.wait(toastOptions);
    if(form.valid && this.payFile){
      this.depositFormRequest.amount = this.balance.exchange.amount;
      this.depositFormRequest.method = method;
      let date = this.datePipe.transform(new Date(this.datePay.year, this.datePay.month, this.datePay.day), 'yyyy-MM-dd');
      if(method === 'DEPOSIT'){
        this.depositFormRequest.reference.deposit.date = date;
      }else{
        this.depositFormRequest.reference.swift.date = date;
      }
      const service = new Service(this.storageService);
      service.makePayment(this.balance.customer.id, this.depositFormRequest)
          .then(value => {
            this.toastyService.clearAll();
            service.uploadPayImage(this.balance.customer.id, value.id, this.payFile).then(value1 => {
              var toastOptions: ToastOptions = {
                title: "Exito",
                msg: "Avatar actualizado exitosamente.",
                timeout: 3000,
                theme: "default"
              };
              this.dialogRef.close({  });
            });
          })
          .catch(reason => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Error",
              msg: reason.message,
              timeout: 5000,
              theme: "default"
            };
            this.toastyService.error(toastOptions);
          });
    } else {
      this.loading = false;
      this.toastyService.clearAll();
      if(!this.payFile){
        this.toastyService.error({
          title: 'Error',
          msg: 'Debe cargar una imagen del pago!',
          timeout: 5000,
          theme: 'default'
        });
      }else{
        this.toastyService.error({
          title: 'Error',
          msg: 'Completar todos los campos obligatorios!',
          timeout: 5000,
          theme: 'default'
        });
      }

    }
  }

  loadFile($event) {
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Cargando...",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    if (permittedFiles.includes($event.target.files[0].type)) {
      this.payFile = $event.target.files[0];
      this.toastyService.clearAll();
    } else {
      this.payFile = null;
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: 'Tipo de archivo no permitido.',
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    }
  }
}

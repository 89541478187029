import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastyService} from 'ng2-toasty';
import {ActivityStructure} from '../../../../../../api/models/activity';
import {StorageService} from '../../../../../../api/service/storage.service';
import * as moment from 'moment';
import {GrantsService} from 'src/app/services/grants.service';

@Component({
  selector: 'app-item-activity',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input() activity: ActivityStructure;
  @Input() type: string;
  @Output() clickRemove: EventEmitter<ActivityStructure> = new EventEmitter();
  @Output() clickSelect: EventEmitter<ActivityStructure> = new EventEmitter();
  @Output() clickDownload: EventEmitter<ActivityStructure> = new EventEmitter();
  @Output() clickSee: EventEmitter<ActivityStructure> = new EventEmitter();

  public moment: any = moment;

  constructor( private toastyService: ToastyService, public storageService: StorageService, public grantsService: GrantsService) { }

  ngOnInit(): void {  }

  clickRemoveCourse(event: Event): void {
    event.stopPropagation();
    this.clickRemove.emit(this.activity);
  }

  clickSelectCourse(): void {
    this.clickSelect.emit(this.activity);
  }

  download(event: Event): void{
    event.stopPropagation();
    this.clickDownload.emit(this.activity);
  }

  openFilePreview(event: Event): void {
    event.stopPropagation();
    this.clickSee.emit(this.activity);
  }

}

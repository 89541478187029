import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcesComponent } from './resources.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import {ChipAutoCompleteModule} from '../../../../shared/chipAutoComplete/chipAutoComplete.module';
import {MatMenuModule} from '@angular/material/menu';
import { ArchwizardModule } from "angular-archwizard";

@NgModule({
    declarations: [ResourcesComponent],
    exports: [ResourcesComponent],
    imports: [
        CommonModule,
        NgbModule,
        ChipAutoCompleteModule,
        ArchwizardModule,
        FormsModule,
        MatIconModule,
        MatMenuModule,
    ]
})
export class ResourcesModule { }

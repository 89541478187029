<div class="row" style="margin: 0; padding: 2px 0 0 0;">
    <div class="col-sm">
        <section class="wrapperSearch">
            <mat-icon style="color: #057ab2; font-size: 32px; margin-right: 20px;">search</mat-icon>
            <input type="text" class="form-control" id="search" name="search"
                placeholder="Buscar!"
                #names="ngModel"
                [(ngModel)]="search"
                (keyup)="changeValue()"
            >
        </section>
    </div>
   <!-- <div *ngIf="!isStaff" class="col-sm-3" style="margin-top: -28px;">
        <label class="form-control-label" for="type" style="color: #fff">Sucursal</label>
        <select class="select form-control" id="type" name="type"
                required
                #contactType="ngModel"
                (change)="takeSpace(space)"
                [(ngModel)]="space">
            <option
                selected
                *ngFor="let space of listSpace;"
                value="{{space.id}}"
                >{{space.title}}</option>
            <option
                value="new"
            >Nueva Sucursal(+)</option>
        </select>
    </div> -->
</div>


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DetailActivityComponent} from './detail.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbButtonsModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastyModule} from 'ng2-toasty';
import {SearchModule} from './components/search/search.module';
import {AsideBarModule} from './components/asideBar/asideBar.module';
import {HeaderModule} from './components/header/header.module';
import {ItemViewModule} from './components/itemView/itemView.module';
import {ChipAutoCompleteModule} from '../../shared/chipAutoComplete/chipAutoComplete.module';
import {MatIconModule} from '@angular/material/icon';
import {DetailActivityRoutingModule} from './detail-routing.module';
import {AlertModule, ModalModule} from '../../theme/shared/components';
import {ScreenLoadingModule} from '../../shared/screenLoading/screenLoading.module';
import {HeaderPageModule} from '../../shared/headerPage/headerPage.module';
import {SidebarMenuModule} from '../../shared/sidebarMenu/sidebarMenu.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {QuestionFormModule} from '../../shared/questionForm/questionForm.module';
import {NotesFormModule} from '../../shared/notesForm/notesForm.module';

@NgModule({
    imports: [
        CommonModule,
        ChipAutoCompleteModule,
        HeaderPageModule,
        NgxMaterialTimepickerModule,
        DetailActivityRoutingModule,
        FormsModule,
        ToastyModule,
        SidebarMenuModule,
        SearchModule, ScreenLoadingModule,
        ReactiveFormsModule,
        AsideBarModule,
        HeaderModule,
        ItemViewModule,
        NgbModule,
        NgbButtonsModule,
        MatIconModule,
        AlertModule,
        ModalModule, QuestionFormModule, NotesFormModule
    ],
  declarations: [
    DetailActivityComponent,
  ]
})
export class DetailActivityModule { }

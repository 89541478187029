import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { TagStructure } from '../../../api/models/chipAutoComplete';
@Component({
  selector: 'app-tags-eduk',
  templateUrl: './chipAutoComplete.component.html',
  styleUrls: ['./chipAutoComplete.component.scss']
})

export class ChipAutoCompleteComponent implements OnInit{
  @Input() listTag: TagStructure[];
  @Input() title: string;
  @Input() placeHolder: string;
  @Input() tags: TagStructure[];
  @Input() showAll: boolean;
  @Output() tagsEnter: EventEmitter<TagStructure[]> = new EventEmitter();
  @ViewChild('chip') chip: ElementRef;

  tagsFilter: TagStructure[] = [];
  tagsShow: TagStructure[];
  openTag = false;
  valueInput: string;

  ngOnInit(): void {
    this.tagsShow = this.tags;
  }

  add(event, save?: string): void{
    if (event.keyCode === 13){
      this.findToAdd(event.currentTarget.value);
    }
    if (save){
      this.findToAdd(save);
    }
  }

  findToAdd(value): void{
    const isExist = this.listTag.findIndex(tag => tag.name === value);
    const isExistTag = this.tags.find(tag => tag.name === value);

    if (isExist < 0){
      if (isExistTag){
        this.listTag.push({id: isExistTag.id, name: isExistTag.name});
      }else{
        this.listTag.push({id: null, name: value});
      }
      this.valueInput = '';
    }
    this.close();
    this.tagsEnter.emit(this.listTag);
    this.chip.nativeElement.focus();
  }


  select(t: TagStructure): void {
    // tslint:disable-next-line:no-shadowed-variable
    const isExist = this.listTag.findIndex(tag => tag.name === t.name);
    if (isExist === -1){
      this.listTag.push({id: t.id, name: t.name});
      this.valueInput = '';
      this.tagsEnter.emit(this.listTag);
      this.close();
    }
  }

  open(): void{
    this.openTag = true;
    this.tagsShow = this.tags;
  }

  close(): void{
    this.openTag = false;
    this.chip.nativeElement.focus();
  }

  remove(id: number): void {
    this.listTag.splice(id, 1);
    this.tagsEnter.emit(this.listTag);
    this.close();
  }

  searchTag(): void{
    this.tagsShow = this.tags.filter(t => t.name.includes(this.valueInput));
  }

  clear(): void{
    this.listTag = [];
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingSchoolComponent } from './settings-school.component';
import { SettingsSchoolRoutingModule } from './settings-school-routing.module';
import { SharedModule } from '../theme/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { CustomFormsModule } from "ngx-custom-validators";
import { NgbButtonLabel, NgbButtonsModule, NgbModule, NgbRadioGroup } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule } from "angular-archwizard";
import { ToastyModule } from "ng2-toasty";
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    imports: [
        CommonModule,
        SettingsSchoolRoutingModule,
        SharedModule,
        FormsModule,
        DataTablesModule,
        CustomFormsModule,
        ArchwizardModule,
        ToastyModule,
        NgbModule,
        NgbButtonsModule,
        MatDialogModule,
        MatIconModule,
    ],
  declarations: [
    SettingSchoolComponent,
  ],
  providers: [
    SettingSchoolComponent,
    MatDialog,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    NgbRadioGroup,
    NgbButtonLabel,
  ],
})
export class SettingsSchoolModule { }

import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/api/service/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import countriesArr from "../../assets/data/countries-phone-codes";
import {
  Address,
  Balance,
  Phone
} from "../../api/client/data-contracts";
import { ToastOptions, ToastyService } from 'ng2-toasty';
import {Service} from "../../api/service/Service";
import {WizardNavbarLgComponent} from "../contacts/wizard-navbar-lg/wizard-navbar-lg.component";
import {PayDialogComponent} from "../pay-dialog/pay-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {NavigationEnd, Router} from "@angular/router";

const permittedFiles = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

@Component({
  selector: 'app-settings-school',
  templateUrl: './settings-school.component.html',
  styleUrls: ['./settings-school.component.scss']
})
export class SettingSchoolComponent implements OnInit {
  schoolForm: FormGroup;
  schoolFormPhones: FormGroup;
  schoolFormAddress: FormGroup;
  customerForm: FormGroup;
  customerFormPhones: FormGroup;
  customerFormAddress: FormGroup;
  subscriptionForm: FormGroup;
  countries = countriesArr;
  phones: Array<Phone> = new Array<Phone>();
  addresses: Array<any> = new Array<any>();
  CustomerPhones: Array<Phone> = new Array<Phone>();
  CustomerAddresses: Array<any> = new Array<any>();
  position = 'top-center';
  schoolSetting;
  tabActive = 'school';
  loading = false;
  activeTab = '';
  balance: Balance;
  private fragment: string;


  constructor(
    private storageService: StorageService,
    private fb: FormBuilder,
    private toastyService: ToastyService,
    private dialog: MatDialog,
    private route: Router
  ) {
    this.route.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = this.route.parseUrl(decodeURIComponent(this.route.url));
        if (tree.fragment) {
          this.fragment = tree.fragment;
        }
      }
    });
  }

  ngOnInit(): void {
    this.buildForm();
    this.getSettings();
    if(this.fragment){
      this.tabActive = this.fragment;
    }
  }

  getSettings(): void {
    const service = new Service(this.storageService);
    service.getSettingSchool(this.storageService.getCurrentSession().school.id).then(value => {
      this.setAllInfo(value);
      this.schoolSetting = value;
    });
    if(this.storageService.getCurrentSession().customerResponse){
      service.getCustomerBalanceById(this.storageService.getCurrentSession().customerResponse.id).then(value => {
        this.balance = value;
      });
    }
  }

  setAllInfo(info): void {
    this.schoolForm.get('school_name').patchValue(info.school.name);
    this.schoolForm.get('school_url_logo').patchValue(info.school.avatar.href);
    info.school.phones.forEach(phone => {
      this.schoolFormPhones.get('phone_type').patchValue(phone.type);
      // tslint:disable-next-line:max-line-length
      this.schoolFormPhones.get('phone_number').patchValue((phone.number.substring(phone.number.indexOf(')') + 2, phone.number.length)).replace('-', ''));
      this.schoolFormPhones.get('phone_code').patchValue(phone.number.substring(0, phone.number.indexOf(')') + 1), 'school');
      this.schoolFormPhones.get('phone_type').updateValueAndValidity();
      this.schoolFormPhones.get('phone_number').updateValueAndValidity();
      this.schoolFormPhones.get('phone_code').updateValueAndValidity();

      this.addPhone(
          this.schoolFormPhones.get('phone_type'),
          this.schoolFormPhones.get('phone_number'),
          this.schoolFormPhones.get('phone_code'),
          'school');
    });

    info.school.addresses.forEach(adress => {
      this.schoolFormAddress.get('address_type').patchValue(adress.type);
      this.schoolFormAddress.get('address').patchValue(adress.value);
      this.schoolFormAddress.get('address_type').updateValueAndValidity();
      this.schoolFormAddress.get('address').updateValueAndValidity();

      this.addAddress(
          this.schoolFormAddress.get('address_type'),
          this.schoolFormAddress.get('address'),
          'school');
    });

    this.customerForm.get('customer_name').patchValue(info.customer.names);
    this.customerForm.get('customer_tax_number').patchValue(info.customer.taxNumber);

    info.customer.phones.forEach(phone => {
      this.customerFormPhones.get('customer_type').patchValue(phone.type);
      // tslint:disable-next-line:max-line-length
      this.customerFormPhones.get('customer_number').patchValue((phone.number.substring(phone.number.indexOf(')') + 2, phone.number.length)).replace('-', ''));
      this.customerFormPhones.get('customer_code').patchValue(phone.number.substring(0, phone.number.indexOf(')') + 1), 'customer');
      this.customerFormPhones.get('customer_type').updateValueAndValidity();
      this.customerFormPhones.get('customer_number').updateValueAndValidity();
      this.customerFormPhones.get('customer_code').updateValueAndValidity();

      this.addPhone(
          this.customerFormPhones.get('customer_type'),
          this.customerFormPhones.get('customer_number'),
          this.customerFormPhones.get('customer_code'),
          'customer');
    });

    info.customer.addresses.forEach(adress => {
      this.customerFormAddress .get('customer_address_type').patchValue(adress.type);
      this.customerFormAddress .get('customer_address').patchValue(adress.value);
      this.customerFormAddress .get('customer_address_type').updateValueAndValidity();
      this.customerFormAddress .get('customer_address').updateValueAndValidity();

      this.addAddress(
          this.customerFormAddress .get('customer_address_type'),
          this.customerFormAddress .get('customer_address'),
          'customer');
    });
  }

  setTab(value: string): void{
    this.tabActive = value;
  }

  buildForm(): void {
    this.schoolForm = this.fb.group({
      school_url_logo: [''],
      school_logo: [''],
      school_name: ['', [Validators.required]],
    });

    const numberRegEx = /\-?\d*\.?\d{1,2}/;
    this.schoolFormPhones = this.fb.group({
      phone_type: ['', [Validators.required]],
      phone_number: ['', [Validators.required, Validators.pattern(numberRegEx)]],
      phone_code: ['+(502)', [Validators.required]],
    });

    this.schoolFormAddress = this.fb.group({
      address_type: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });

    this.customerForm = this.fb.group({
      customer_name: ['', [Validators.required]],
      customer_tax_number: ['', [Validators.required]],
    });

    this.customerFormPhones = this.fb.group({
      customer_type: ['', [Validators.required]],
      customer_number: ['', [Validators.required]],
      customer_code: ['+(502)', [Validators.required]],
    });

    this.customerFormAddress = this.fb.group({
      customer_address_type: ['', [Validators.required]],
      customer_address: ['', [Validators.required]],
    });
    this.subscriptionForm = this.fb.group({
    });
  }

  public validateSchool: () => boolean = () => {
    const names = this.schoolForm.get('school_name');
    if (!(names.valid)) {
      return false;
    } else {
      return true;
    }
  }

  verifyValidSchool(): void {
    const names = this.schoolForm.get('school_name');
    names.markAsTouched();
  }

  // tslint:disable-next-line:typedef
  async addPhone(phoneType, phoneNumber, countryPhoneCode, type ) {
    if (phoneType.valid && phoneNumber.valid || (phoneType.value && phoneNumber.value && countryPhoneCode.value && type)) {
      const phoneAsString = phoneNumber.value.toString();
      if (Number(phoneAsString)) {
        // tslint:disable-next-line:variable-name
        const number = `${countryPhoneCode.value} ${phoneAsString.substr(0, 4)}-${phoneAsString.substr(4)}`;

        if (type === 'school') {
          this.phones.push({
            number,
            type: phoneType.value,
          });
        } else {
          this.CustomerPhones.push({
            number,
            type: phoneType.value,
          });
        }

        phoneType.reset();
        phoneNumber.reset();
        countryPhoneCode.reset();
        countryPhoneCode.patchValue('+(502)');
      } else {
        const toastOptions: ToastOptions = {
          title: "Numero invalido.",
          msg: "Ingresar un numero valido!",
          timeout: 3000,
          theme: "default"
        };
        this.toastyService.error(toastOptions);
        phoneType.markAsTouched();
        phoneNumber.markAsTouched();
      }
    } else {
      phoneType.markAsTouched();
      phoneNumber.markAsTouched();
    }
  }

  async addAddress(addressType, address, type) {
    if (addressType.valid && address.valid || (addressType.value && address.value && type)) {
      if (type === 'school') {
        this.addresses.push({
          type: addressType.value,
          value: address.value,
        });
      } else {
        this.CustomerAddresses.push({
          type: addressType.value,
          value: address.value
        });
      }

      addressType.reset();
      address.reset();
    } else {
      addressType.markAsTouched();
      address.markAsTouched();
    }
  }

  async deletePhone(p: Phone, type) {
    if (type === 'school') {
      this.phones.splice(this.phones.findIndex(item => item.number === p.number), 1);
    } else {
      this.CustomerPhones.splice(this.phones.findIndex(item => item.number === p.number), 1);
    }
  }

  async deleteAddress(a: Address, type) {
    if (type === 'school') {
      this.addresses.splice(this.addresses.findIndex(item => item.description === a.description), 1);
    } else {
      this.CustomerAddresses.splice(this.addresses.findIndex(item => item.description === a.description), 1);
    }
  }

  // tslint:disable-next-line:max-line-length
  renderSettingToSave(): { school: { addresses: Array<Address>; name: any; phones: Array<Phone>; type: string }; customer: { addresses: Array<Address>; names: any; countryCode: string; taxNumber: any; phones: Array<Phone> } } {
    const setting = {
        school: {
          name: this.schoolForm.get('school_name').value,
          type: 'PRIVATE',
          phones: this.phones,
          addresses: this.addresses
        },
        customer: {
          names: this.customerForm.get('customer_name').value,
          taxNumber: this.customerForm.get('customer_tax_number').value,
          countryCode: 'GT',
          phones: this.CustomerPhones,
          addresses: this.CustomerAddresses
        }
    };

    return setting;
  }

  changeSchoolAvatar($event): void {
    this.toastyService.clearAll();
    let toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando...",
      timeout: 5000,
      theme: "default"
    };
    const file = $event.target.files[0];

    if (permittedFiles.includes(file.type)) {
      const service = new Service(this.storageService);
      service.addLogo(file)
          .then(async () => {
            this.toastyService.clearAll();
            // tslint:disable-next-line:no-shadowed-variable
            const toastOptions: ToastOptions = {
              title: "Exito",
              msg: "Logo actualizado exitosamente.",
              timeout: 3000,
              theme: "default"
            };
            this.toastyService.info(toastOptions);
            this.phones = [];
            this.addresses = [];
            this.CustomerPhones = [];
            this.CustomerAddresses = [];
            this.getSettings();
            //this.profilePicture = this.selectedContact.avatar;
          })
          .catch(reason => {
            this.toastyService.clearAll();
            const toastOptions: ToastOptions = {
              title: "Error",
              msg: reason.message,
              timeout: 5000,
              theme: "default"
            };
            this.toastyService.error(toastOptions);
          });
    } else {
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: "Error",
        msg: 'Tipo de archivo no permitido.',
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    }
  }

  async updateSchool() {
    this.loading = true;
    this.toastyService.clearAll();
    const toastOptions: ToastOptions = {
      title: 'Espere',
      msg: 'Actualizando informacion',
      timeout: 5000,
      theme: 'default'
    };

    this.toastyService.wait(toastOptions);

    this.schoolForm.get('school_logo').clearValidators();
    this.schoolForm.get('school_name').clearValidators();
    this.schoolForm.get('school_logo').updateValueAndValidity();
    this.schoolForm.get('school_name').updateValueAndValidity();

    this.customerForm.get('customer_name').clearValidators();
    this.customerForm.get('customer_tax_number').clearValidators();
    this.customerForm.get('customer_name').updateValueAndValidity();
    this.customerForm.get('customer_tax_number').updateValueAndValidity();
    const service = new Service(this.storageService);

    if (this.schoolForm.get('school_logo').value) {
      // tslint:disable-next-line:max-line-length
      const rr = this.schoolForm.get('school_logo').value;
    }

    if (this.schoolForm.valid && this.customerForm.valid) {
      service.updateSettingSchool(this.storageService.getCurrentSession().school.id, this.renderSettingToSave())
          .then(() => {
            this.toastyService.clearAll();
            // tslint:disable-next-line:no-shadowed-variable
            const toastOptions: ToastOptions = {
              title: 'Actualizado Configuracion',
              msg: 'Configuracion actualizada',
              timeout: 5000,
              theme: 'default'
            };
            this.toastyService.success(toastOptions);
            this.loading = false;
          })
          .catch(reason => {
            this.toastyService.clearAll();
            // tslint:disable-next-line:no-shadowed-variable
            const toastOptions: ToastOptions = {
              title: 'Error',
              msg: reason.message,
              timeout: 5000,
              theme: 'default'
            };
            this.toastyService.error(toastOptions);
            this.loading = false;
          });
    }
  }


  onTabChange($event) {
    this.activeTab = $event.nextId;
  }


  makePayment(): any {
    this.dialog.open(PayDialogComponent, {
      data: this.balance,
      height: '75%',
      width: '65%',
      autoFocus: false,
    }).afterClosed().subscribe(() => {
      this.getSettings();
    });
  }
}

import {
    Alerts,
    Customer,
    CycleResponse,
    Grant,
    SchoolInfo,
    StudentInfo,
    User,
    UserInfo
} from "../client/data-contracts";

export class Session {
    public token: string;
    public user: UserInfo;
    public school: SchoolInfo;
    public students: Array<StudentInfo>;
    public cycle: CycleResponse;
    public grants: Grant[];
    public alerts: Alerts[];
    public grantsObject: Object;
    public customerResponse: Customer;
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { StorageService } from "src/api/service/storage.service";
import { GrantsService } from "src/app/services/grants.service";
import { Service } from "../../../api/service/Service";
import { ToastOptions, ToastyService } from "ng2-toasty";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ActivityStructure,
  FilterActivity,
  OptionMenuActivity,
} from "../../../api/models/activity";
import {
  ActivityShortResponse,
  MediaAsset,
  ResourceResponse,
} from "src/api/client/data-contracts";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { UiModalComponent } from "../../theme/shared/components/modal/ui-modal/ui-modal.component";
import { HeaderComponent } from "./components/header/header.component";
import { FILES_NOT_PREVEW } from "src/api/utils/Constants";
import { MediaPreviewModalComponent } from "src/app/theme/shared/components/media-preview-modal/media-preview-modal.component";
import { ScreenLoadingComponent } from "../../shared/screenLoading/screenLoading.component";
import { DeleteScoreModalComponent } from "../../content/form-content/delete-score-modal/delete-score-modal.component";
import { ScoresManagerComponent } from "../../content/form-content/scores-manager/scores-manager.component";
import { StudentScoresModalComponent } from "../../theme/shared/components/student-scores-modal/student-scores-modal.component";
import { saveAs } from "file-saver";
import { HeaderStructure } from "src/api/models/headerPage";
import { MenuStructure, OptionsMenuStructure } from "src/api/models/sidebar";
import { SidebarMenuComponent } from "../../shared/sidebarMenu/sidebarMenu.component";

@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"],
})
export class CourseActivityComponent implements OnInit {
  @ViewChild("modalCreateResource") modalCreateResource: UiModalComponent;
  @ViewChild("modalCreateActivitie") modalCreateActivitie: UiModalComponent;
  @ViewChild("modalDeleteResource") modalDeleteResource: UiModalComponent;
  @ViewChild("modalDeleteActivity") modalDeleteActivity: UiModalComponent;
  @ViewChild("modalDelete") modalDelete: UiModalComponent;
  @ViewChild("screenLoading") screenLoading: ScreenLoadingComponent;
  @ViewChild("sideBar") sideBar: SidebarMenuComponent;

  @ViewChild("header") header: HeaderComponent;
  period = "";
  selectedStudent = {
    names: "",
    last_names: "",
    birth_date: "",
    gender: "",
    id: "",
  };
  courseId: string;
  message = "Cargando Actividades";
  courseName: string;
  optionActive: MenuStructure;
  filterActive: FilterActivity;
  activities = new Array<ActivityShortResponse>();
  resources = new Array<ResourceResponse>();
  students = [];
  statusOption = true;
  windowWidth: number;
  scores = [];
  breadcrumbs: HeaderStructure[] = [];
  resourceToDelete = "";
  existingUnitsIds: number[] = [];
  activitiesByName = [];
  attachment: MediaAsset;
  loading = false;

  private localStorageService;

  iconsNav: OptionMenuActivity[] = [
    { icon: "assignment", active: true, option: "Actividades" },
    { icon: "attach_file", active: false, option: "Recursos" },
    { icon: "how_to_reg", active: false, option: "Calificaciones" },
    { icon: "school", active: false, option: "Alumnos" },
  ];

  iconsNav2: OptionsMenuStructure[] = [
    {
      title: "Acciones",
      description:
        "navega y selecciona acciones entre la lista que se muestra en la parte de abajo, ahi se muestran las diferentes opciones que se pueden ejecutar dentro de un curso.",
      menu: [
        { id: "assignment", icon: "assignment", name: "Actividades" },
        { id: "attach_file", icon: "attach_file", name: "Recursos" },
        { id: "how_to_reg", icon: "how_to_reg", name: "Calificaciones" },
        { id: "school", icon: "school", name: "Alumnos" },
      ],
    },
  ];

  filterNave: FilterActivity[] = [
    {
      option: "NOW",
      active: false,
      text: "Hoy",
      period: { period: null, from: null, to: null },
    },
    {
      option: "WEEK",
      active: false,
      text: "Semana",
      period: { period: null, from: null, to: null },
    },
    {
      option: "MONTH",
      active: false,
      text: "Mes",
      period: { period: null, from: null, to: null },
    },
    {
      option: "NAME",
      active: false,
      text: "Nombre",
      period: { period: null, from: null, to: null },
    },
    {
      option: "RANGE",
      active: false,
      text: "Rango fechas",
      period: { period: null, from: null, to: null },
    },
  ];

  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    public grantsService: GrantsService,
    private toastyService: ToastyService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.localStorageService = localStorage;
    const course = {
      id: this.route.snapshot.params.id,
      name: this.route.snapshot.params.course,
    };

    this.localStorageService.setItem("edukcare-course", JSON.stringify(course));

    this.courseId = this.route.snapshot.params.id;
    this.courseName = this.route.snapshot.params.course;
    this.breadcrumbs = [
      { id: null, name: "Cursos", url: "menu/course" },
      { id: null, name: "Actividades", url: null },
    ];

    this.windowWidth = window.innerWidth;
  }

  ngAfterViewInit() {
    this.updateDataActivities("");
    this.updateDataResources();
    this.updateDataStudents();
    this.updateDataScores();
    if (this.windowWidth <= 1110) {
      this.toggleMenuOptions();
    }
  }

  closeSideBar(): void {
    this.statusOption = !this.statusOption;
  }

  setOptionNav(item): void {
    this.optionActive = item.menu.find((icon) => icon.active);

    switch (this.optionActive.name) {
      case "Actividades":
        this.filterNave = [
          {
            option: "NOW",
            active: false,
            text: "Hoy",
            period: { period: null, from: null, to: null },
          },
          {
            option: "WEEK",
            active: false,
            text: "Semana",
            period: { period: null, from: null, to: null },
          },
          {
            option: "MONTH",
            active: false,
            text: "Mes",
            period: { period: null, from: null, to: null },
          },
          {
            option: "NAME",
            active: false,
            text: "Nombre",
            period: { period: null, from: null, to: null },
          },
          {
            option: "RANGE",
            active: false,
            text: "Rango fechas",
            period: { period: null, from: null, to: null },
          },
        ];
        break;
      case "Recursos":
      case "Alumnos":
        this.filterNave = [
          {
            option: "NAME",
            active: false,
            text: "Nombre",
            period: { period: null, from: null, to: null },
          },
        ];
        break;
      case "Calificaciones":
        this.filterNave = [];
        break;
    }

    this.filterActive = null;
    if (this.header) {
      this.header.optionActive = null;
    }
  }

  closeModalCreateResource() {
    this.modalCreateResource.hide();
  }

  closeModalFormActivities() {
    this.modalCreateActivitie.hide();
  }

  addZeroToDate(date: number) {
    let value = "";
    if (date < 10) {
      value = `0${date}`;
    } else {
      value = `${date}`;
    }
    return value;
  }

  toggleMenuOptions(): void {
    this.sideBar.closeSide();
    this.statusOption = !this.sideBar.close;
  }

  setFilterNav(op: FilterActivity): void {
    this.filterNave.forEach((fil) => {
      fil.active = fil.option === op.option;
    });

    this.filterActive = this.filterNave.find((fil) => fil.active);

    switch (this.filterActive.option) {
      case "NOW":
      case "WEEK":
      case "MONTH":
        this.filterActive.period.period = this.filterActive.option;
        break;
      case "NAME":
        this.activitiesByName =
          this.optionActive.name === "Actividades"
            ? this.activities.slice()
            : this.optionActive.name === "Recursos"
            ? this.resources.slice()
            : this.optionActive.name === "Calificaciones"
            ? this.scores.slice()
            : this.optionActive.name === "Alumnos"
            ? this.students.slice()
            : [];
        break;
    }
    if (this.filterActive.option !== "NAME") {
      this.updateDataActivities(this.filterActive.period);
    }
  }

  searchByName(name: string) {
    if (this.optionActive.name === "Actividades") {
      this.activities = this.activitiesByName.filter((act) =>
        act.title.toLowerCase().includes(name.toLowerCase())
      );
    } else if (this.optionActive.name === "Recursos") {
      this.resources = this.activitiesByName.filter((act) =>
        act.name.toLowerCase().includes(name.toLowerCase())
      );
    } else if (this.optionActive.name === "Calificaciones") {
      this.scores = this.activitiesByName.filter((act) =>
        act.title.toLowerCase().includes(name.toLowerCase())
      );
    } else if (this.optionActive.name === "Alumnos") {
      this.students = this.activitiesByName.filter((act) =>
        act.names.toLowerCase().includes(name.toLowerCase())
      );
    }
  }

  clickContextMenu(opt: string): void {
    if (opt === "newResource") {
      this.modalCreateResource.show();
    } else if (opt === "updateScores") {
      this.generateScore();
    } else if (opt === "listStudents") {
      this.exportExcel();
    }
  }

  async exportExcel() {
    const service = new Service(this.storageService);
    await service
      .getStudentsExcel(this.courseId)
      .then((data: any) => {
        const fileName = "Estudiantes.xlsx";
        const fileBlob = this.dataURItoBlob(data);
        saveAs(fileBlob, fileName);
      })
      .catch((reason) => {
        console.log(reason);
      });
  }

  generateScore() {
    this.dialog
      .open(ScoresManagerComponent, {
        data: {
          spaceId: this.courseId,
          students: this.students,
          existingUnits: this.existingUnitsIds,
        },
        height: "85%",
        width: "75%",
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.updateDataScores();
      });
  }

  clearFilter(): void {
    this.updateDataActivities("");
    this.updateDataResources();
    this.updateDataStudents();
    this.updateDataScores();
  }

  searchByDates(ranges: { to: NgbDateStruct; from: NgbDateStruct }) {
    if (ranges.from && ranges.to) {
      this.filterActive.period.from = [
        this.addZeroToDate(ranges.from.day),
        this.addZeroToDate(ranges.from.month),
        ranges.from.year,
      ].join("-");
      this.filterActive.period.to = [
        this.addZeroToDate(ranges.to.day),
        this.addZeroToDate(ranges.to.month),
        ranges.to.year,
      ].join("-");

      this.updateDataActivities(this.filterActive.period);
    } else {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Campos Requerido",
        msg: "Los campos 'desde' y 'hasta' son requeridos ",
        timeout: 3000,
        theme: "default",
      };
      this.toastyService.error(toastOptions);
    }
  }

  public updateDataActivities(params) {
    this.screenLoading.on();
    const service = new Service(this.storageService);
    service.getAllActivitiesFromSpace(this.courseId, params).then((acts) => {
      acts.sort((a, b) => {
        const aDate = new Date(a.start_at),
          bDate = new Date(b.start_at);
        if (aDate < bDate) {
          return 1;
        }
        if (aDate > bDate) {
          return -1;
        }
        return 0;
      });
      this.screenLoading.off();
      this.activities = acts;
    });
  }

  public updateDataResources() {
    const service = new Service(this.storageService);
    service.getAllResourcesFromSpace(this.courseId).then((value) => {
      this.resources = value;
    });
  }

  updateDataStudents() {
    const service = new Service(this.storageService);
    service
      .getStudentsBySpace(this.courseId)
      .then((value) => (this.students = value));
  }

  updateDataScores() {
    const service = new Service(this.storageService);
    service.getScoresBySpace(this.courseId).then((value) => {
      this.scores = value;
      this.scores.forEach((score) =>
        this.existingUnitsIds.push(score.unit.unit)
      );
    });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/png" });
    return blob;
  }

  download(item: ActivityStructure): void {
    if (this.optionActive.name === "Recursos") {
      const filename = item.link.file_name
        ? item.link.file_name
        : "resource_file_" + Math.random();
      const a = document.createElement("a");
      a.download = filename;
      a.href = item.link.href;
      a.textContent = filename;
      document.body.appendChild(a);
      a.click();

      setTimeout(() => document.body.removeChild(a), 100);
    } else if (this.optionActive.name === "Calificaciones") {
      const service = new Service(this.storageService);
      service
        .downloadScoreInfo(this.courseId, item.id)
        .then((data: any) => {
          const fileName = `Notas para la Unidad ${item.unit.unit}.xlsx`;
          const fileBlob = this.dataURItoBlob(data);
          saveAs(fileBlob, fileName);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  }

  deleteAc(item: ActivityStructure): void {
    if (this.optionActive.name === "Recursos") {
      this.modalDeleteResource.show();
      this.resourceToDelete = item.id;
    } else if (this.optionActive.name === "Actividades") {
      this.modalDeleteActivity.show();
      this.resourceToDelete = item.id;
    } else if (this.optionActive.name === "Calificaciones") {
      this.dialog
        .open(DeleteScoreModalComponent, {
          data: {
            spaceId: this.courseId,
            scoreId: item.id,
          },
          height: "65%",
          width: "65%",
          autoFocus: false,
        })
        .afterClosed()
        .subscribe((deleted) => {
          deleted && this.updateDataScores();
        });
    } else if (this.optionActive.name === "Alumnos") {
      this.selectedStudent = item;
      this.modalDelete.show();
    }
  }

  async deleteStudent() {
    const service = new Service(this.storageService);
    await service
      .unassignContactsStudent(this.courseId, [this.selectedStudent.id])
      .then((value) => {
        this.toastyService.clearAll();
        const toastOptions: ToastOptions = {
          title: "Exito",
          msg:
            this.storageService.getContactTypes()["STUDENT"]["name"] +
            " eliminado exitosamente.",
          timeout: 3000,
          theme: "default",
        };
        this.toastyService.info(toastOptions);
        this.updateDataStudents();
        this.modalDelete.hide();
      })
      .catch((reason) => {
        this.toastyService.clearAll();
        const toastOptions: ToastOptions = {
          title: "Error",
          msg: reason.message,
          timeout: 5000,
          theme: "default",
        };
        this.toastyService.error(toastOptions);
      });
    this.selectedStudent = {
      names: "",
      last_names: "",
      birth_date: "",
      gender: "",
      id: "",
    };
    // @ts-ignore
    this.modalDelete.hide();
  }

  deleteActivity() {
    const service = new Service(this.storageService);

    service.removeActivityFromSpace(this.resourceToDelete).then((value) => {
      // @ts-ignore
      this.modalDeleteActivity.hide();
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Actividad eliminada exitosamente.",
        timeout: 3000,
        theme: "default",
      };
      this.toastyService.error(toastOptions);
      this.updateDataActivities("");
    });
  }

  deleteResource() {
    const service = new Service(this.storageService);

    service
      .removeResourceFromActivity(this.courseId, this.resourceToDelete)
      .then((value) => {
        if (!value) {
          // @ts-ignore
          this.modalDeleteResource.hide();
          this.toastyService.clearAll();
          const toastOptions: ToastOptions = {
            title: "Exito",
            msg: "Recurso eliminada exitosamente.",
            timeout: 3000,
            theme: "default",
          };
          this.toastyService.info(toastOptions);
          this.updateDataResources();
        }
      });
  }

  openFilePreview(item: ActivityStructure) {
    let resource: MediaAsset = item.link;
    let id = item.id;
    if (this.optionActive.name === "Recursos") {
      if (FILES_NOT_PREVEW[resource.type]) {
        this.attachment = resource;
        // @ts-ignore
        this.modalConfirmationDownload.show();
      } else {
        this.dialog
          .open(MediaPreviewModalComponent, {
            data: resource,
            height: "85%",
            width: "75%",
            autoFocus: false,
          })
          .afterClosed()
          .subscribe(() => {
            // TODO If action needed after closed
          });
      }
    } else if (this.optionActive.name === "Calificaciones") {
      this.dialog.open(ScoresManagerComponent, {
        data: {
          spaceId: this.courseId,
          score: item,
        },
        height: "85%",
        width: "75%",
        autoFocus: false,
      });
    } else if (this.optionActive.name === "Alumnos") {
      let selectedStudent = item;
      this.dialog.open(StudentScoresModalComponent, {
        data: {
          selectedStudent: selectedStudent,
          space: { id: this.courseId },
          selectUnit: false,
          showSpace: false,
        },
        height: "75%",
        width: "55%",
        autoFocus: false,
      });
    } else if (this.optionActive.name === "Actividades") {
      this.router.navigate([
        `menu/course/activity/detail/${item.id}/${item.title}`,
      ]);
    }
  }
}

<div class="d-flex flex-column h-100">
    <div class="app-modal-header mb-2 d-flex flex-row justify-content-between align-items-center">
        <div>
            <table>
                <tr>
                    <h5 class="modal-title">
                        {{title}}
                    </h5>
                </tr>
                <tr>
                    <label class="modal-title">
                        {{subTitle}}
                    </label>
                </tr>
                <tr>
                    <label class="modal-title">
                        {{scoreHandling}}
                    </label>
                </tr>
            </table>
        </div>
        <div>
            <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="app-modal-body">
        <div *ngIf="!loading" class="d-flex flex-column mt-3">
            <ng-container *ngIf="mode==='create'">
                <form [formGroup]="form">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label" for="unit">Unidad</label>
                            <select class="form-control" name="unit" formControlName="unit" required
                                [ngClass]="{'is-invalid': form.get('unit').touched && form.get('unit').hasError('required')}">
                                <option *ngFor="let unit of cycleUnits" [value]="unit.id">
                                    Unidad {{ unit.unit }}
                                </option>
                            </select>
                            <label *ngIf="form.get('unit').touched && form.get('unit').hasError('required')"
                                id="validation-phone-type-error"
                                class="error jquery-validation-error small form-text invalid-feedback">
                                Seleccione una unidad
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label" for="score_type">¿Cómo va a subir sus notas?</label>
                            <select class="form-control" name="score_type" formControlName="score_type" required
                                [ngClass]="{'is-invalid': form.get('score_type').touched && form.get('score_type').hasError('required')}">
                                <!-- <option value="UPLOAD">Subir archivo csv (Excel)</option> -->
                                <option value="MANUAL">Manual</option>
                            </select>
                            <label *ngIf="form.get('score_type').touched && form.get('score_type').hasError('required')"
                                id="validation-phone-type-error"
                                class="error jquery-validation-error small form-text invalid-feedback">
                                Seleccione un método
                            </label>
                        </div>
                    </div>
                    <div *ngIf="form.get('unit').value && form.get('score_type').value === 'UPLOAD'"
                        class="col-md-12 mt-3">
                        <div class="d-flex flex-column align-items-center">
                            <i *ngIf="scoresFileSelected" class="far fa-check-circle fa-4x"
                                style="color: forestgreen;"></i>
                            <p *ngIf="scoresFileSelected" class="mt-2" style="color: forestgreen; font-weight: 500;">
                                Archivo válido
                            </p>
                            <i *ngIf="!scoresFileSelected" class="far fa-times-circle fa-4x"
                                style="color: darkred;"></i>
                            <p *ngIf="!scoresFileSelected" class="mt-2" style="color: darkred; font-weight: 500;">
                                Sin archivo seleccionado
                            </p>
                        </div>
                        <div class="d-flex flex-column align-items-center mt-3">
                            <input hidden type="file" #uploader (change)="validateScoresFiles($event)"
                                [multiple]="true">
                            <button type="button" class="btn btn-sm btn-glow-light btn-light" (click)="uploader.click()"
                                style="max-height: 40px;">
                                <i class="feather icon-paperclip"></i>
                                Seleccionar archivo(s)
                            </button>
                        </div>
                    </div>
                    <div *ngIf="form.get('unit').value && form.get('score_type').value === 'MANUAL'"
                        formArrayName="students_scores" class="col-md-12 pl-0 pr-0">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-center col-1"># Carnet</th>
                                        <th class="text-center col-1">CUI</th>
                                        <th class="text-center col-3">Nombre</th>
                                        <th class="text-center col-2">Nota</th>
                                        <th class="text-center col-5">Comentario</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let student of form.get('students_scores')['controls']; let i = index;">
                                        <ng-container [formGroupName]="i">
                                            <th class="text-center align-middle" scope="row">
                                                {{ getScoreOption(i).value['school_id'] || 'N/A' }}
                                            </th>
                                            <td class="text-center align-middle">
                                                {{ getScoreOption(i).value['personal_id'] || 'N/A' }}
                                            </td>
                                            <td class="text-center align-middle">
                                                {{ getScoreOption(i).value['names'] || 'N/A'}}
                                            </td>
                                            <td class="text-center align-middle">
                                                <div class="input-group d-flex align-self-center score-input">
                                                    <input type="number" class="form-control" style="min-width: 150px" formControlName="score"
                                                        required placeholder="0-100"
                                                        [ngClass]="{'is-invalid': (getScoreOption(i).get('score').touched && getScoreOption(i).get('score').hasError('required')) || (getScoreOption(i).get('score').touched && !getScoreOption(i).get('score').valid)}">
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <textarea class="form-control" style="min-width: 150px" name="comment" rows="2"
                                                    formControlName="comment" placeholder="Comentarios"></textarea>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="mode==='update'">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center col-1"># Carnet</th>
                                <th class="text-center col-1">CUI</th>
                                <th class="text-center col-3">Nombre</th>
                                <th class="text-center col-2">Nota</th>
                                <th class="text-center col-5">Comentario</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let score of selectedScore?.scores">
                                <th class="text-center align-middle" scope="row">
                                    {{ score?.contact?.school_internal_id || 'N/A' }}
                                </th>
                                <td class="text-center align-middle">
                                    {{ score?.contact?.personal_id || 'N/A' }}
                                </td>
                                <td class="text-center align-middle">
                                    {{ score?.contact?.names || 'N/A' }} {{ score?.contact?.last_name || 'N/A' }}
                                </td>
                                <td class="text-center align-middle">
                                    {{ ( score?.score % 1 === 0 ? score?.score :  score?.score.toFixed(2)) }}
                                </td>
                                <td class="text-center align-middle">
                                    {{ score?.comment || 'N/A' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="app-modal-footer mt-auto">
        <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
            <div class="spinner-border text-info" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
        <div *ngIf="!loading" class="d-flex flex-row justify-content-end">
            <button type="button" class="btn btn-sm btn-glow-secondary btn-secondary" (click)="closeModal()">
                Cancelar
            </button>
            <button *ngIf="mode==='create'" type="button" class="btn btn-sm btn-glow-success btn-success"
                [disabled]="(form.get('score_type').value === 'UPLOAD' && !scoresFileSelected) || (form.get('score_type').value === 'MANUAL' && !form.valid)"
                (click)="saveScore(form.get('score_type').value === 'UPLOAD' ? true : false)">
                Guardar
            </button>
        </div>
    </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GrantsService} from 'src/app/services/grants.service';
import {Router} from '@angular/router';
import {StorageService} from '../../../../../api/service/storage.service';
import {ActivityStructure} from 'src/api/models/activity';


@Component({
  selector: 'app-itemView-activity',
  templateUrl: './itemView.component.html',
  styleUrls: ['./itemView.component.scss']
})
export class ItemViewComponent implements OnInit {
  @Input() data: ActivityStructure[];
  @Input() type: string;
  @Input() space: string;
  @Input() isNewSpace: boolean;
  @Output() delete: EventEmitter<ActivityStructure> = new EventEmitter();
  @Output() newActivity: EventEmitter<boolean> = new EventEmitter();
  @Output() download: EventEmitter<ActivityStructure> = new EventEmitter();
  @Output() see: EventEmitter<ActivityStructure> = new EventEmitter();
  @Output() open: EventEmitter<any> = new EventEmitter();

    loading = false;

    constructor(
    public grantsService: GrantsService,
    public storageService: StorageService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit(): void { }

  openNewActivity(): void{
    this.newActivity.emit(true);
  }

  downloadDocument(activity: ActivityStructure): void{
    this.download.emit(activity);
  }

  seeDocument(activity: ActivityStructure): void{
    this.see.emit(activity);
  }

  openAc(activity: ActivityStructure): void{
    this.open.emit(activity);
  }

  deleteAc(activity: ActivityStructure): void{
    this.delete.emit(activity);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideBarComponent } from './asideBar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { ChipAutoCompleteModule } from '../../../../shared/chipAutoComplete/chipAutoComplete.module';
@NgModule({
    declarations: [AsideBarComponent],
    exports: [
        AsideBarComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        ChipAutoCompleteModule,
        FormsModule,
        MatIconModule,
    ]
})
export class AsideBarModule { }

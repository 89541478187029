import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { HeaderStructure } from '../../../api/models/headerPage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-eduk',
  templateUrl: './headerPage.component.html',
  styleUrls: ['./headerPage.component.scss']
})

export class HeaderPageComponent implements OnInit{
  @Input() title: string;
  @Input() breadcrumbs: HeaderStructure[];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateUrl(item: HeaderStructure): void{
    if(item.url) {
      this.router.navigate([item.url]);
    }
  }

}

<section class="sectionChip">
    <label> {{title}} </label>
    <article
        #chip
        tabindex="0"
        contenteditable="false"
        (mouseleave)="close()"
        class="wrapperChipAutoComplete">
        <ul>
            <li *ngFor="let tag of listTag; let i = index">
                {{tag.name}}
                <i style="cursor: pointer" class="feather icon-x-circle ml-2" (click)="remove(i)"></i>
            </li>
        </ul>
        <input
                type="text"
                autocomplete="off"
                class="form-control"
                id="inputTag"
                name="inputTag"
                [placeholder]="placeHolder"
                (keyup)="searchTag()"
                #course="ngModel"
                (focus)="open()"
                (keyup)="add($event)"
                [(ngModel)]="valueInput"
                [ngClass]="{'is-invalid': !course.valid && (course.dirty || course.touched )}">

        <article *ngIf="valueInput" class="listTag">
            <div style="cursor: pointer" (click)="add('', valueInput)"> {{ valueInput }} <span style="font-size: 10px; color: #6b6b6b;">(Agregar Etiqueta)</span> </div>
        </article>

        <article *ngIf="openTag" [ngClass]="showAll ? 'full' : ''" class="listTag">
            <label>Etiqueta</label>
            <label class="descriptionTagCreated">Para seleccionar una etiqueta haz clic sobre ella.</label>
            <ul>
                <li *ngFor="let item of tagsShow; let i = index" (click)="select(item)">
                    <span> {{item.name}} </span>
                </li>
            </ul>
        </article>
    </article>

</section>


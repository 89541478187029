import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastyService } from "ng2-toasty";
import { MatDialog } from "@angular/material/dialog";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { FilterActivity } from "../../../../../api/models/activity";
import { GrantsService } from "src/app/services/grants.service";

@Component({
  selector: "app-header-activity",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() placeHolder: string;
  @Input() filterNave: FilterActivity[];
  @Output() filters: EventEmitter<FilterActivity> = new EventEmitter();
  @Output() name: EventEmitter<string> = new EventEmitter();
  @Output() rangeDates: EventEmitter<{
    to: NgbDateStruct;
    from: NgbDateStruct;
  }> = new EventEmitter();
  @Output() clearFilter: EventEmitter<boolean> = new EventEmitter();
  @Output() optionContextMenu: EventEmitter<string> = new EventEmitter();

  nameActivity: string;
  dateToActivity: NgbDateStruct;
  dateFromActivity: NgbDateStruct;
  optionActive: FilterActivity;

  constructor(
    private toastyService: ToastyService,
    private dialog: MatDialog,
    public grantsService: GrantsService
  ) {}

  ngOnInit(): void {}

  openCreateResource(opt: string): void {
    this.optionContextMenu.emit(opt);
  }

  filterActivity(filter: FilterActivity): void {
    this.filterNave.forEach((fil) => {
      fil.active = fil.option === filter.option;
    });

    this.optionActive = this.filterNave.find((f) => f.active);

    this.filters.emit(filter);
  }

  range(): void {
    const rd = { to: this.dateToActivity, from: this.dateFromActivity };
    this.rangeDates.emit(rd);
  }

  clear() {
    this.filterNave.forEach((fil) => {
      fil.active = false;
    });

    this.optionActive = this.filterNave.find((f) => f.active);
    this.clearFilter.emit(true);
  }

  searchByName() {
    this.name.emit(this.nameActivity);
  }
}
